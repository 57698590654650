import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checklistTemplateAttributes: [],
  checklistGeneralDetailsCreationDetails: {},
  useTemplateInformation: { useTemplate: false, templateId: null },
  templateInfo: {},
}

const ChecklistAndReportsSlice = createSlice({
  name: "ChecklistTemplate",
  initialState,
  reducers: {
    setSelectedAttributesToolkitState(state, action) {
      state.checklistTemplateAttributes = action.payload;
    },
    setChecklistGeneralDetailsCreationDetails(state, action) {
      state.checklistGeneralDetailsCreationDetails = action.payload;
    },
    setUseTemplateInformation(state, action) {
      state.useTemplateInformation = action.payload;
    },
    setTemplateInfoToolkitState(state, action) {
      state.templateInfo = action.payload;
    },
  },
});

export const ChecklistAndReportsAction = ChecklistAndReportsSlice.actions;

export default ChecklistAndReportsSlice.reducer;