import { memo, useEffect, useState } from "react";
import Styles from "./TemplateImagesNotesModal.module.css";
import { useDispatch } from "react-redux";
import Modal from "../../../../../components/CommonComponents/Modal/Modal";
import CloseIcon from "../../../../../assets/Clonos Common Indicators/Icon_Components/CloseIcon";
import { ClonosButton } from "../../../../../components/CommonComponents/Button/Button";
import Template_Creation_Upload_Button_Icon from "../../../../../assets/UIUX/icons/Common_Component/File_Uploader/file_uploader_upload_button.svg";
import Template_Creation_Remove_File_Icon from "../../../../../assets/UIUX/icons/Common_Component/File_Uploader/file_uploader_delete.svg";
import Template_Creation_Pencil_Icon from "../../../../../assets/UIUX/icons/Checklist/pencil.svg";
import {
	handleCheckFileIsPresentOrNot,
	handleLinearProgress,
	handleValidateFileSize,
	handleValidateFilesType,
	validateFilesLimit,
} from "../../../../../utils/ChecklistAndReportsMethods/ChecklistMethods";
import { Tooltip } from "@material-ui/core";
import { ProgressBarWithLabel } from "../../../../../components/CommonComponents/FileUploader/FileUploader";
import ClonosImageEditor from "../../../../../components/CommonComponents/ClonosImageEditor/ClonosImageEditor";
import { ImagePreviewModal } from "../../../../../components/CommonComponents/ImagePreviewModal/ImagePreviewModal";
import { useSelector } from "react-redux";
import { globalHandleUploadFiles } from "../../../../../utils/clonosCommon";

/**
 * TemplateImagesNotesModal component allows users to upload images or add notes.
 * The component supports file validation, file removal, and note saving functionality.
 *
 * @param {object} props - Component props.
 * @param {string} props.label - The type of input (either "Image" or "Notes").
 * @param {string} props.uniqueKey - Unique identifier for the data.
 * @param {boolean} props.showNotesEditor - Flag to show/hide the notes editor.
 * @param {Function} props.handleGetValues - Function to handle saving the values.
 * @param {boolean} props.showFileUploader - Flag to show/hide the file uploader.
 * @param {Function} props.setShowFileUploader - Function to set the visibility of the file uploader.
 * @param {Function} props.setShowNotesEditor - Function to set the visibility of the notes editor.
 */
const TemplateImagesNotesModal = ({
	label,
	uniqueKey,
	showNotesEditor,
	handleGetValues,
	showFileUploader,
	setShowFileUploader,
	setShowNotesEditor,
}) => {
	const { templateInfo } = useSelector(
		(store) => store.checklistTemplateData);

	console.log('templateInfo:', templateInfo)
	const [uploadProgress, setUploadProgress] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);
	console.log('uploadedFiles:', uploadedFiles)
	const [noteDetails, setNoteDetails] = useState(templateInfo?.noteDetails || "");
	const [isImageEditorModalOpen, setIsImageEditorModalOpen] = useState(false);
	const [isImagePreviewerModalOpen, setIsImagePreviewerModalOpen] = useState(false);
	const [needToEditAndViewFile, setNeedToEditAndViewFile] = useState({});
	const [uploadedFilesOnServer, serUploadedFilesOnServer] = useState([]);
	console.log('uploadedFilesOnServer:', uploadedFilesOnServer)
	console.log('needToEditAndViewFile:', needToEditAndViewFile)
	const dispatch = useDispatch();

	/**
	 * Handles the saving of uploaded images.
	 */
	const handleSaveImages = () => {
		handleGetValues && handleGetValues({ uniqueKey, updatedValue: { uploadedFiles, noteDetails } });
		setShowFileUploader(false);
	};

	const localHandleUploadFiles = async (files) => {
		console.log('filessssssss:', files)
		const uploadPromises = files?.map(file => (globalHandleUploadFiles({ payload: { file } })));
		console.log('uploadPromises:', uploadPromises)
		try {
			const results = await Promise.all(uploadPromises);
			console.log('results:', results)
			serUploadedFilesOnServer([...uploadedFilesOnServer, ...results?.map(item => (item?.data?.result))])
		} catch (error) {
			console.log('error:', error)
		}
	}

	/**
	 * Handles file upload, including validation and updating the state.
	 *
	 * @param {Event} e - The change event triggered by file input.
	 */
	const handleUploadFiles = (e) => {
		let files = Array.from(e.target.files);
		console.log('files:', files)
		const backupFiles = [...files];
		if (
			validateFilesLimit({ limit: 8, files, dispatch }) &&
			handleValidateFileSize({ files, fileSizeInMB: 8, dispatch }) &&
			handleValidateFilesType({ files, acceptableFileTypes: [".jpg", ".png", ".jpeg"], dispatch })
		) {
			const filesResponse = handleCheckFileIsPresentOrNot({
				needToUploadedFiles: files,
				uploadedFiles,
				dispatch,
			});

			console.log('filesResponse:', filesResponse)

			localHandleUploadFiles(filesResponse?.newFiles)

			console.log('files:', files)
			handleLinearProgress({ files: backupFiles, setUploadProgress });
			files = (filesResponse?.totalFiles || []).map((file) => {
				if (!file?.url) {
					return {
						file: file,
						url: file?.url ? file?.url : URL.createObjectURL(file),
						name: file?.name,
						size: file?.size
					}
				} else {
					return file;
				}
			})
			setUploadedFiles(files);
		}
	};

	console.log("uploadedFiles", uploadedFiles)

	/**
	 * Handles the removal of a selected file.
	 *
	 * @param {Object} fileToDelete - The file to be removed.
	 */
	const handleDeselectFile = (fileToDelete) => {
		const updatedFiles = uploadedFiles?.filter((file) => (file?.name !== fileToDelete?.name));
		setUploadedFiles(updatedFiles);
	};

	/**
	 * Handles the saving of notes.
	 */
	const handleSaveNotes = () => {
		handleGetValues && handleGetValues({ uniqueKey, updatedValue: { uploadedFiles, noteDetails } });
		setShowNotesEditor(false);
	};

	/**
	 * Determines which save handler to call based on the label.
	 */
	const handleSave = () => {
		if (label === "Image") handleSaveImages();
		if (label === "Notes") handleSaveNotes();
	};


	/**
	 * Edit the image in Clonos Image Editor
	 * @param {Object} file - A file type object that contain the file details
	 */
	const handleEditImage = (file) => {
		console.log('file:', file)
		setIsImageEditorModalOpen(true);
		setNeedToEditAndViewFile(file);
	}

	/**
	 * Handles updating the list of uploaded files with the newly edited image data.
	 * If the file already exists, it updates the file details; otherwise, it adds the new file to the list.
	 *
	 * @param {Object} data - The data object containing the updated file details.
	 * @param {Object} data.updatedValue - The new file data to be added or updated.
	 */
	const handleGetEditedImageData = (data) => {
		try {
			if (!data || !data.updatedValue) {
				throw new Error("Invalid data object or updated value.");
			}

			let isCompletedNewImage = true;
			const manipulatedData = (uploadedFiles || []).map(item => {
				if (item?.name === data?.updatedValue?.name) {
					const editedFileBlobURL = URL.createObjectURL(data.updatedValue);
					const editedFileName = `${item?.name?.split(".")[0]}_Edited.${item?.name?.split(".")[1]}`;
					if (isCompletedNewImage) {
						isCompletedNewImage = false;
					}
					return {
						file: item.file,
						editedFile: data?.updatedValue,
						url: item.url,
						name: data?.updatedValue?.name,
						size: data?.updatedValue?.size,
						urls: [{ name: data?.updatedValue?.name, url: item.url }, { name: editedFileName, url: editedFileBlobURL }]
					};
				} else {
					return item;
				}
			});

			if (isCompletedNewImage) {
				setUploadedFiles([...manipulatedData, {
					file: null,
					editedFile: data?.updatedValue,
					url: URL.createObjectURL(data.updatedValue),
					name: data?.updatedValue?.name,
					size: data?.updatedValue?.size
				}]);
			} else {
				setUploadedFiles(manipulatedData);
			}
		} catch (error) {
			console.error("Error handling edited image data:", error.message);
		}
	};


	useEffect(() => {
		setNoteDetails(templateInfo?.noteDetails)
	}, [templateInfo])


	return (
		<>
			<Modal
				stopOverlayCloser={true}
				isOpen={showFileUploader || showNotesEditor}
				closeModalMethod={
					label === "Image"
						? setShowFileUploader
						: label === "Notes"
							? setShowNotesEditor
							: setShowFileUploader
				}
			>
				<div className={Styles.modal_inner_container}>
					<header className={Styles.modal_header_container} aria-label="Modal Header">
						<h4>Add {label}</h4>
						<CloseIcon
							tooltipTitle={"Close"}
							onClick={() => {
								setShowFileUploader(false);
								setShowNotesEditor(false);
							}}
							aria-label="Close Modal"
							role="button"
						/>
					</header>

					{label === "Image" ? (
						<div className={Styles.ct_image_uploader_container} aria-live="polite">
							{uploadedFiles?.length > 0 ? (
								<>
									<div className={Styles.ct_view_image_container}>
										<div className={Styles.ct_image_list_container} role="list">
											{uploadedFiles?.map((file, index) => (
												<div key={index + 1} className={Styles.ct_image_item} role="listitem">
													<div className={Styles.ct_img_item_left}>
														<img
															width={"30px"}
															height={"30px"}
															src={file?.url}
															alt={file?.name}
															onClick={() => {
																setIsImagePreviewerModalOpen(true);
																setNeedToEditAndViewFile(file);
															}}
														/>
														<div className={Styles.ct_filename_container}>
															<Tooltip title={file?.name}>
																<p>{file?.name}</p>
															</Tooltip>
															{uploadProgress[file?.name] && uploadProgress[file?.name] !== 100 ? (
																<ProgressBarWithLabel progress={uploadProgress[file?.name]} />
															) : null}
														</div>
													</div>
													<div className={Styles.ct_img_item_right}>
														<div>
															<img onClick={() => handleEditImage(file, index)} src={Template_Creation_Pencil_Icon} alt="Edit" />
														</div>
														<div onClick={() => handleDeselectFile(file)}>
															<img src={Template_Creation_Remove_File_Icon} alt="Remove" />
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
									<div className={Styles.ct_view_img_upload_btn_box}>
										<label htmlFor="ct_view_image_uploader" name="ct_view_image_uploader">
											<input
												type="file"
												style={{ display: "none" }}
												id="ct_view_image_uploader"
												accept=".jpg,.png,.jpeg"
												multiple
												name="ct_view_image_uploader"
												onChange={handleUploadFiles}
												aria-label="Upload Images"
											/>
											<div className={Styles.ct_upload_btn} role="button">
												<span>
													<img src={Template_Creation_Upload_Button_Icon} alt="Upload" />
												</span>
												<span>upload</span>
											</div>
										</label>
									</div>
								</>
							) : (
								<div className={Styles.ct_image_uploader_inner_container}>
									<div className={Styles.ct_drag_area_container}>
										<label htmlFor="ct_image_uploader" name="ct_image_uploader">
											<div className={Styles.ct_drag_area_content}>
												<div>
													<img alt="" />
												</div>
												<div>
													<h4 className={Styles.drag_area_text}>
														Drag & Drop Image to upload or
													</h4>
												</div>
												<input
													type="file"
													style={{ display: "none" }}
													id="ct_image_uploader"
													accept=".jpg,.png,.jpeg"
													multiple
													name="ct_image_uploader"
													onChange={handleUploadFiles}
													aria-label="Upload Images"
												/>
												<div>
													<div className={Styles.ct_upload_btn} role="button">
														<span>
															<img src={Template_Creation_Upload_Button_Icon} alt="Upload" />
														</span>
														<span>upload</span>
													</div>
												</div>
												<div className={Styles.drag_area_text}>
													Supported formats .png, .jpg, or .jpeg
												</div>
											</div>
										</label>
									</div>
								</div>
							)}
						</div>
					) : (
						<div className={Styles.note_editor_content_container}>
							<div className={Styles.textarea_container}>
								<textarea
									onChange={(e) => setNoteDetails(e.target.value)}
									rows="20"
									cols="50"
									maxLength="5000"
									placeholder="Type here"
									defaultValue={noteDetails || ""}
									aria-label="Notes Editor"
								></textarea>
							</div>
						</div>
					)}
					<footer className={Styles.file_uploader_footer_container}>
						<ClonosButton
							onClick={() => {
								setShowFileUploader(false);
								setShowNotesEditor(false);
							}}
							style={{ color: "#06337E" }}
							isHollow={true}
							p={"0.5rem 2rem"}
							aria-label="Cancel"
						>
							Cancel
						</ClonosButton>
						<ClonosButton
							onClick={handleSave}
							p={"0.5rem 2rem"}
							variant="filled"
							aria-label="Save"
						>
							Save
						</ClonosButton>
					</footer>
				</div>
			</Modal>
			<Modal isOpen={isImageEditorModalOpen} closeModalMethod={setIsImageEditorModalOpen}>
				<div aria-label="Image Editor Modal Container"
					className={Styles.image_editor_container}
				>
					<ClonosImageEditor
						file={needToEditAndViewFile?.editedFile ? needToEditAndViewFile?.editedFile : needToEditAndViewFile?.file}
						handleGetEditedData={handleGetEditedImageData}
						handleEditorCloser={() => {
							setIsImageEditorModalOpen(false);
						}} />
				</div>
			</Modal>
			<ImagePreviewModal
				images={needToEditAndViewFile?.urls ? needToEditAndViewFile?.urls : [needToEditAndViewFile]}
				open={isImagePreviewerModalOpen}
				closeModalMethod={setIsImagePreviewerModalOpen}

			/>
		</>
	);
};

export default memo(TemplateImagesNotesModal);