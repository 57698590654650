import { Skeleton } from '@mui/material'
import React from 'react'

/**
 * Generates a skeleton loading component with customizable properties.
 * @param {Object} props - The properties object.
 * @param {String} [props.variant='text'] - Specifies the variant of the skeleton loading. Possible values are 'circular', 'rectangular', 'rounded', or 'text' (default).
 * @param {String} [props.height='16px'] - Specifies the height of the skeleton loading element.
 * @param {String} [props.width='100%'] - Specifies the width of the skeleton loading element.
 * @param {Number} [props.length=1] - Specifies the number of skeleton loading elements to render.
 * @param {String} [props.animation='wave'] - Specifies the type of animation for the loading effect. Possible values are 'pulse' or 'wave' (default).
 * @param {String} [props.bgColor='#e5e3e3'] - Specifies the background color of the skeleton loading element.
 * @returns {JSX.Element} The skeleton loading component.
 */
const ClonosSkeletonLoading = ({ variant = 'text', height = '16px', width = '100%', length = 1, animation = "wave", bgColor = '#e5e3e3' }) => {
    return (
        <>
            {
                (new Array(length)?.fill(0) || []).map((item, index) => {
                    return <Skeleton
                        key={index}
                        animation={animation}
                        variant={variant}
                        width={width}
                        height={height}
                        sx={{ bgcolor: bgColor }}
                    />
                })
            }
        </>
    )
}

export default ClonosSkeletonLoading