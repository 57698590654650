import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react'
import ImageEditorReference from "tui-image-editor"
import "./ClonosImageEditor.css"
import { useState } from 'react';
import { ClonosButton } from '../Button/Button';
import defaultImage from "../../../assets/UIUX/images/import.png"
import ClonosSkeletonLoading from '../ClonosSkeletonLoading/ClonosSkeletonLoading';
import CloseIcon from '../../../assets/Clonos Common Indicators/Icon_Components/CloseIcon';



/**
 * ImageEditor component allows users to edit images using the custom-image-editor.
 * Users can upload an image, perform various editing operations, and save/download the edited image.
 * @param {string} url - The URL of the image to be loaded initially.
 * @param {Object} file - File object representing the image to be edited.
 * @param {Function} handleGetEditedData - Function to handle getting the edited image data.
 * @param {string} uniqueKey - Unique key to identify the component instance.
 * @param {string} handleEditorCloser - handler function for close the editor.
 * @returns {JSX.Element} ImageEditor component.
 */
const ClonosImageEditor = React.memo((
    {
        url,
        file,
        uniqueKey,
        handleEditorCloser,
        handleGetEditedData,
    }
) => {
    const ImageEditorContainerRef = useRef(null);
    const [lcIntervals, setLcIntervals] = useState({});
    const [fileDetails, setFileDetails] = useState(null);
    const optionsMenus = { draw: false, text: false, crop: false };
    const containerInstanceRef = useRef(null);
    let detailedColorState = false;
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false)


    const customTheme = {
        'common.bi.image': '/path-to-your-custom-icons.svg',
        'common.bisize.width': '20px',
        'common.bisize.height': '20px',
        'common.backgroundImage': 'none',
        'menu.normalIcon.color': '#333',
        'menu.activeIcon.color': '#00F',
        'menu.disabledIcon.color': '#999',
    };

    // Handle toggle detailed color
    const handleCheck = () => {
        const featureElement = document.querySelector(`.tui-colorpicker-slider-container`);
        let interval = null
        if (detailedColorState) {
            interval = setTimeout(() => {
                featureElement.style.display = "none";
            }, 10)
            detailedColorState = false
        } else {
            interval = setTimeout(() => {
                featureElement.style.display = "flex"
            }, 10)
            detailedColorState = true
        }
        setLcIntervals((prev) => {
            let prevIntervals = { ...prev, [`interval${Object.keys(prev).length}`]: interval }
            return prevIntervals
        })
    }


    // Update layout range styles
    const handleUpdateLayoutRange = ({ className }) => {
        let element = document.querySelector(`.${className}`);
        element.type = 'number'
        element.style.backgroundColor = "white"
        element.style.width = "50px"
        element.style.borderRadius = "0.2rem"
        element.style.padding = "0.5rem"
        element.style.color = "black"
        element.style.height = 'fit-content'
    }


    function base64ToFile(base64Data, filename) {
        // Split the base64 string to get the content type and the actual data
        const [, contentType, base64Payload] = base64Data.match(/^data:(.+?);base64,(.+)/);

        // Convert the base64 payload to a blob
        const byteCharacters = atob(base64Payload);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });

        // Create a file object from the blob
        const file = new File([blob], filename, { type: contentType });
        return file;
    }

    // For example, you can save the edited image:
    const handleSave = () => {
        const fileTypeObject = base64ToFile(containerInstanceRef.current.toDataURL(), fileDetails.name)
        handleGetEditedData && handleGetEditedData({ updatedValue: fileTypeObject, uniqueKey });
        setSaveLoading(false);
        handleEditorCloser && handleEditorCloser();
    };




    // Handle event setting 
    const handleEventSatted = ({ className, optionsMenus }) => {
        if (optionsMenus[className] == true) {
            const featureElement = document.querySelector(`#clonos-editor-menu-${className}`);
            featureElement.style.display = "none"
            optionsMenus[className] = false
        } else {
            const featureElement = document.querySelector(`#clonos-editor-menu-${className}`);
            featureElement.style.display = "block"
            optionsMenus[className] = true
            Object.keys(optionsMenus).forEach((item) => {
                if (item !== className) {
                    const featureElement = document.querySelector(`#clonos-editor-menu-${item}`);
                    featureElement.style.display = "none"
                }
            })
        }
    }


    // Adding the event on the load of the page.
    const handleSetEvents = ({ menuOptions, optionsMenus, isSingle, type, className, singleMethod }) => {
        if (isSingle) {
            const featureElement = document.querySelector(`${type}${className}`);
            featureElement.addEventListener(('click'), function () {
                singleMethod()
            })
        } else {
            menuOptions.length > 0 && menuOptions?.map((className) => {
                const featureElement = document.querySelector(`.tie-btn-${className}`);
                featureElement.addEventListener(('click'), function () {
                    handleEventSatted({ className, optionsMenus })
                })
            })
        }

    }

    // Check URL for image file
    const handleCheckUrl = (e) => {
        const lcFile = e?.target.files["0"]
        setFileDetails({ name: lcFile?.name, size: lcFile?.size, type: lcFile?.type, lastModified: lcFile?.lastModified, lastModifiedDate: lcFile?.lastModifiedDate });
        containerInstanceRef.current.loadImageFromFile(lcFile, e.target.files["0"].name)
    }

    // Download edited image
    const handleDownload = () => {
        const linkElement = document.createElement('a');
        linkElement.href = containerInstanceRef.current.toDataURL();
        linkElement.setAttribute("download", fileDetails?.name);
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
    }

    // Drag and drop feature.
    const handleDragAndDrop = (e) => {
        e.preventDefault();
        const lcFile = e.dataTransfer.files[0];
        setFileDetails({ name: lcFile?.name, size: lcFile?.size, type: lcFile?.type, lastModified: lcFile?.lastModified, lastModifiedDate: lcFile?.lastModifiedDate });
        containerInstanceRef.current.loadImageFromFile(lcFile, lcFile?.name);
    };


    const handleUploadFiles = (e) => {
        const lcFile = e.target.files[0];
        setFileDetails({ name: lcFile?.name, size: lcFile?.size, type: lcFile?.type, lastModified: lcFile?.lastModified, lastModifiedDate: lcFile?.lastModifiedDate });
        containerInstanceRef.current.loadImageFromFile(lcFile, lcFile?.name);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (ImageEditorContainerRef.current) {
            const imageEditor = new ImageEditorReference(ImageEditorContainerRef.current, {
                includeUI: {
                    theme: customTheme,
                    loadImage: {
                        path: url ? url : defaultImage, // Load an initial image
                        name: 'Clonos Image Editor',
                    },
                    menu: Object.keys(optionsMenus),
                },
            });
            // You can add event listeners and further customization here
            containerInstanceRef.current = imageEditor;  // Assign the instance to the ref
        }

        let interval = setTimeout(() => {
            Object.keys(optionsMenus).includes("text") && handleUpdateLayoutRange({ className: "tie-text-range-value" })  // Updating the layout dynamicaly
            handleSetEvents({ menuOptions: Object.keys(optionsMenus), optionsMenus }) // Setting the event on the library default elements.
            Object.keys(optionsMenus).includes("draw") && handleUpdateLayoutRange({ className: "tie-draw-range-value" })
            handleSetEvents({ isSingle: true, singleMethod: handleCheck, type: ".", className: "tui-colorpicker-palette-toggle-slider" })
            Object.keys(optionsMenus).forEach(item => {  // Setting some Id on the default elements which are given by tui and react-skatch and react-crop.
                const featureElement = document.querySelector(`.tui-image-editor-menu-${item}`);
                featureElement.style.display = "none"
                featureElement.setAttribute("id", `clonos-editor-menu-${item}`)
            });
        }, 100)



        return () => {
            clearInterval(interval);
            clearInterval(lcIntervals["functionInteval"]);
            Object.keys(lcIntervals)?.forEach((element, index) => {  // Here we are clearing local intervals
                clearInterval(lcIntervals[`${element + index}`]);
            })
        }
    }, []);

    useEffect(() => {
        let localInterval = null
        if (file) {
            localInterval = setTimeout(() => {
                const lcFile = file?.target?.files["0"] ? file?.target?.files["0"] : file
                console.log('lcFile:', lcFile)
                setFileDetails({ name: lcFile?.name, size: lcFile?.size, type: lcFile?.type, lastModified: lcFile?.lastModified, lastModifiedDate: lcFile?.lastModifiedDate });
                containerInstanceRef.current.loadImageFromFile(lcFile, lcFile?.name)
                setLoading(true);
            }, 1000)
        }

        return () => {
            clearInterval(localInterval)
        }

    }, [file]);


    return (
        <div className="clonos-image-editor-main-container">
            <div aria-label='Export Import Container' className='export_import_container'>
                <div aria-label='Import file' className='upload_container'>
                    <label name='upload' className='upload'>
                        Upload
                        <input name='upload' type='file' placeholder='Import Image' onChange={handleCheckUrl} />
                    </label>
                </div>
                <div className='action_button_container' aria-label='Action button container'>

                    <ClonosButton loading={saveLoading} onClick={() => {
                        setSaveLoading(true);
                        const functionInteval = setTimeout(() => {
                            handleSave();
                        }, 100);
                        setLcIntervals({ ...lcIntervals, functionInteval });
                    }} isDisabled={fileDetails ? false : true}>Save</ClonosButton>

                    <ClonosButton onClick={handleDownload} isDisabled={fileDetails ? false : true}>Download</ClonosButton>

                    {handleEditorCloser && <CloseIcon
                        tooltipTitle={"Close"}
                        onClick={() => handleEditorCloser()}
                        aria-label="Close Modal"
                        role="button"
                    />}

                </div>
            </div>
            {!fileDetails && <label className='drag_and_drop_container' name='dragAndDrop' onDrop={handleDragAndDrop} onDragOver={handleDragOver}>
                <input type='file' onChange={(e) => handleUploadFiles(e)} />
            </label>}
            <div id="image_editor_container" style={{ visibility: !loading ? "hidden" : "visible" }} ref={ImageEditorContainerRef}></div>
            {
                !loading && <div
                    className="skeleton_loading_container"
                >
                    <ClonosSkeletonLoading
                        variant='rounded'
                        height="45px"
                        width='70%'
                    />
                    <ClonosSkeletonLoading
                        variant='rounded'
                        height="60px"
                        width='50%'
                    />
                    <ClonosSkeletonLoading
                        variant='rounded'
                        height='500px'
                        width='100%'
                    />
                </div>
            }
        </div>
    )
})

export default ClonosImageEditor


// {
//     dataSets: [
//         {
//             "id": "e0a00b9c-5e72-4ab5-8ac3-a46cb989cbb8",
//             "attributeName": "Multiple Choice",
//             "fieldName": "sdfs",
//             "fieldValue": [
//                 {
//                     "optionValue": "sdfsd",
//                     "isActive": false
//                 },
//                 {
//                     "optionValue": "sdfsdf",
//                     "isActive": false
//                 }
//             ],
//             "type": "multipleChoice",
//             "index": number
//         },
//         {
//             "id": "87690460-1890-48c2-bfbf-c8eaec009e12",
//             "attributeName": "Number",
//             "fieldName": "dsfsdf",
//             "fieldValue": [
//                 "2323"
//             ],
//             "type": "number"
//         }
//     ]
// }