import { Grow, MenuItem, MenuList, Paper, Popper, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import React, { useState } from "react";
import { getUser, handleLoggedInUser, removeUserSession } from "../../utils/clonosCommon";
import { commonActions } from "../../Store/Reducers/CommonReducer";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutAccount } from "../../Api/User/UserApi";
import { useSelector } from "react-redux";
import { ClonosButton } from "../CommonComponents/Button/Button";
import Styles from "../../ModuleStyles/ClonosLayout/AppBar.module.css"
import BackIcon from "../../assets/Clonos Common Indicators/Icon_Components/BackIcon";
import { CLONOS_GLOBAL_COLOUR_FADE_WHITE, CLONOS_GLOBAL_COLOUR_WHITE, CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT, CLONOS_GLOBAL_MATE_BLACK } from "../../Constants/Constant"
import GreaterThan from "../../assets/svg/GreaterThan"
import Burger from "../../assets/svg/Burger"
import User from "../../assets/svg/User"
import useToggler from "../../CustomHooks/TogglerHook"
import PowerOff from "../../assets/svg/PowerOff"
import { Tooltip } from "@mui/material"
import NotificationsDrawer from "../../Clonos_Modules/clonos-notifications/notifications-drawer/NotificationsDrawer"
import ToastNotification from "../../Clonos_Modules/clonos-notifications/components/toast-notification/ToastNotification";
import { parentRoutesStaticData } from "../../Constants/StaticData";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: CLONOS_GLOBAL_COLOUR_FADE_WHITE,
        border: "none"

    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    toolbarTwo: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0rem"
    }
}));

const AppBar1 = ({ open, setOpen }) => {
    // Global States
    const { globalNavigationBarPathsToolkitState } = useSelector(
        (store) => store.globalEntitiesStateManagement
    );
    const { notifications } = useSelector(store => store.notificationsData);
    console.log('notifications:', notifications)

    // Local States
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const refreshApp = useSelector((state) => state.commonData.refreshApp);
    const [toggler, setToggler] = useToggler();
    const loggedInUserDetails = handleLoggedInUser();

    const handleRefresh = () => {
        dispatch(commonActions.setRefresh(!refreshApp));
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    console.log('globalNavigationBarPathsToolkitState:', globalNavigationBarPathsToolkitState)

    const handleGoToRequestedPath = (props) => {
        // if ((globalNavigationBarPathsToolkitState || []).length > 1 && globalNavigationBarPathsToolkitState[globalNavigationBarPathsToolkitState?.length - 2]?.route === props?.route) {
        //     navigate(-1);
        // } else {
        // }
        navigate(props?.route, { state: props?.state })
    }

    return <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
        })}
    >
        <Toolbar className={classes.toolbarTwo}>
            {!open && <div className={Styles.burger_container} style={{ width: "61px", display: "flex", alignItems: 'center', justifyContent: "center" }}>
                <Burger strokeWidth={3} onClick={handleDrawerOpen} />
            </div>}
            <div className={Styles.global_nav_parent_container}>
                <div className={Styles.global_nav_container}>
                    {
                        globalNavigationBarPathsToolkitState?.length && ((!parentRoutesStaticData.has(globalNavigationBarPathsToolkitState[0]?.route) && globalNavigationBarPathsToolkitState?.length === 1) || globalNavigationBarPathsToolkitState?.length > 1) && < BackIcon
                            strokeColor={CLONOS_GLOBAL_MATE_BLACK}
                            tooltipTitle="Back"
                            onClick={() => handleGoToRequestedPath(globalNavigationBarPathsToolkitState[globalNavigationBarPathsToolkitState?.length - 2])}
                        // onClick={() => navigate(-1)} 
                        />
                    }
                    {
                        (globalNavigationBarPathsToolkitState || []).map((element, index) => {
                            return <div className={Styles.global_nav_route_container} key={index} onClick={() => handleGoToRequestedPath(element)}>
                                {
                                    index == 0 ?
                                        <span className={index === globalNavigationBarPathsToolkitState?.length - 1 ? Styles.global_nav_route_hover_active : Styles.global_nav_route}>{element?.activeComponentName}</span> :
                                        <div className={index === globalNavigationBarPathsToolkitState?.length - 1 ? Styles.global_nav_route_hover_active : Styles.global_nav_route}>
                                            <GreaterThan size="15" strokeColor={CLONOS_GLOBAL_MATE_BLACK} />
                                            <span>{element?.activeComponentName}</span>
                                        </div>
                                }
                            </div>
                        })
                    }
                </div>
                <div className={Styles.utility_icons_container}>
                    <NotificationsDrawer />
                    <ToastNotification />
                    <div className={Styles.user_container}>
                        <User onClick={setToggler} style={{ backgroundColor: CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT, padding: "0.3rem", zIndex: "1" }} />
                        {
                            toggler && <>
                                <div className={Styles.popper_grow_container}>
                                    <div className={`${Styles.popper_body} ${toggler ? Styles.popper_body_open : Styles.popper_body_close}`} onClick={e => e.stopPropagation()}>
                                        <div className={Styles.arrow}></div>
                                        <div className={Styles.details}>
                                            <Tooltip title={loggedInUserDetails?.name ? loggedInUserDetails?.name : "Not Mentioned"}>
                                                <span>{loggedInUserDetails?.name}</span>
                                            </Tooltip>
                                            <Tooltip title={loggedInUserDetails?.userType ? loggedInUserDetails?.userType?.name : "Not Mentioned"}>
                                                <span >{loggedInUserDetails?.userType?.name}</span>
                                            </Tooltip>
                                        </div>
                                        <div className={Styles.options_container}>
                                            <div className={Styles.options} onClick={() => {
                                                navigate(`/profile/${loggedInUserDetails?.name}`, { state: { userId: loggedInUserDetails?.id } })
                                            }}>
                                                <span>View Profile</span>
                                            </div>
                                            <div className={Styles.options} onClick={() => {
                                                removeUserSession();
                                                navigate("/");
                                            }}>
                                                <PowerOff color={CLONOS_GLOBAL_MATE_BLACK} />
                                                <span>Logout</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={setToggler} className={Styles.overlay}></div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Toolbar>
    </AppBar >
}

export default AppBar1;