import { Card } from '@mui/material'
import React from 'react'

const NotFound = () => {
    return (
        <div style={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "0.50rem"
        }}>
            <h1>404 </h1>
            <h4 style={{ color: "gray" }}>Page Not Found</h4>
        </div>
    )
}

export default NotFound