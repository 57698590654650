import { prodConfigs } from './prod.config.js';
import { localConfigs } from './local.config.js';


let configs = {};
if (process.env.REACT_APP_BUILDTYPE === "PRODUCTION") {
    configs = { url: process.env.REACT_APP_PRODUCTION_CONFIG }
}
else {
    configs = { url: process.env.REACT_APP_LOCAL_CONFIG }
}

export default configs
