import { useDispatch } from "react-redux";
import TEMPLATE_CREATION_ADDIMAGE from "../../../../../assets/UIUX/icons/Checklist/add-image.svg";
import TEMPLATE_CREATION_VIEWIMAGE from "../../../../../assets/UIUX/icons/Checklist/view-image.svg";
import TEMPLATE_CREATION_ADDNOTE from "../../../../../assets/UIUX/icons/Checklist/add-note.svg";
import TEMPLATE_CREATION_VIEWNOTE from "../../../../../assets/UIUX/icons/Checklist/view-note.svg";
import TEMPLATE_CREATION_REMOVEROW from "../../../../../assets/UIUX/icons/Checklist/circle-minus.svg";
import Styles from "./TemplateRowActions.module.css";
import { useEffect, useState } from "react";
import TemplateImagesNotesModal from "./TemplateImagesNotesModal";
import { useSelector } from "react-redux";
import { ChecklistAndReportsAction } from "../../../../../Store/Reducers/ClonosChecklistAndReportsReducer";


export const TemplateRowActions = ({
  attribute,
  attributes,
  isImage = true,
  isNote = true,
  isDeselect = true,
  uniqueKey,
  handleGetValues,
  handleDeleteKeyFromPrimaryObject,
  handleRemoveAttribute
}) => {
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [showNotesEditor, setShowNotesEditor] = useState(false);
  const [lcValues, setLcValues] = useState({});
  const { templateInfo, checklistTemplateAttributes } = useSelector((store) => store.checklistTemplateData);
  const dispatch = useDispatch();
  const [actions, setActions] = useState([]);
  console.log('templateInfo:', templateInfo)
  console.log('lcValuessss:', lcValues)
  console.log('attributesssssssss:', attributes, "attribute", attribute)

  const lcHandleRemoveAttribute = () => {
    handleRemoveAttribute(attribute);
  };

  const handleUpdateActions = (props) => {
    setActions(
      [
        {
          id: 1,
          action: props?.uploadedFiles?.length > 0 ? "View Image" : "Add Image",
          icon: props?.uploadedFiles?.length > 0
            ? TEMPLATE_CREATION_VIEWIMAGE
            : TEMPLATE_CREATION_ADDIMAGE,
          style: { color: props?.uploadedFiles?.length > 0 ? "#0A5FFF" : "#000" },
          onclickMethod: () => setShowFileUploader(true),
          isNeeded: isImage
        },
        {
          id: 2,
          action: props?.noteDetails?.length > 0 ? "View Note" : "Add Note",
          icon: props?.noteDetails?.length > 0
            ? TEMPLATE_CREATION_VIEWNOTE
            : TEMPLATE_CREATION_ADDNOTE,
          style: { color: props?.noteDetails?.length > 0 ? "#0A5FFF" : "#000" },
          onclickMethod: () => setShowNotesEditor(true),
          isNeeded: isNote
        },
        {
          id: 3,
          icon: TEMPLATE_CREATION_REMOVEROW,
          onclickMethod: lcHandleRemoveAttribute,
          isNeeded: isDeselect
        },
      ]
    )
  }



  // const localHandleUploadFiles = async (files) => {
  //   const uploadPromises = files?.map(file => (handleUploadFiles({ payload: { file } })));
  //   try {
  //     const results = await Promise.all(uploadPromises);
  //     console.log('results:', results)
  //   } catch (error) {
  //     console.log('error:', error)
  //   }
  // }


  const lcHandleGetValues = (props) => {
    console.log('props:', props)
    setLcValues({ ...lcValues, [props.uniqueKey]: props?.updatedValue })
    if (props?.updatedValue?.uploadedFiles) {
      // localHandleUploadFiles()
    }
    handleUpdateActions(props?.updatedValue);
    // localHandleUploadFiles(props?.updatedValue?.)
    handleGetValues && handleGetValues({ "componentName": uniqueKey, uniqueKey: props.uniqueKey, updatedValue: props?.updatedValue })
  };


  useEffect(() => {
    setLcValues({ ...lcValues, ["templateImagesNotes"]: { noteDetails: templateInfo?.noteDetails, images: templateInfo?.images } })
  }, [templateInfo])

  useEffect(() => {
    handleUpdateActions()
  }, []);


  return (
    <>
      <div className={Styles.template_action_container}>
        {actions?.map((action) => {
          return (
            action.isNeeded && <div
              key={action?.id}
              className={Styles.template_action}
              onClick={action?.onclickMethod}
            >
              <div className={Styles.action_icon_container}>
                <img src={action?.icon} />
              </div>
              {action?.action && (
                <p style={action?.style} className={Styles.action_title}>{action?.action}</p>
              )}
            </div>
          );
        })}
      </div>
      <TemplateImagesNotesModal
        label={showFileUploader ? "Image" : showNotesEditor ? "Notes" : ""}
        uniqueKey="templateImagesNotes"
        showFileUploader={showFileUploader}
        showNotesEditor={showNotesEditor}
        setShowFileUploader={setShowFileUploader}
        setShowNotesEditor={setShowNotesEditor}
        handleGetValues={lcHandleGetValues}
      />
    </>
  );
};
