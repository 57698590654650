import React from 'react'

const BellStaticIcon = ({ height, size, width, fill }) => {
    return (
        <svg width={size ? size : width ? width : "39"} height={size ? size : height ? height : "46"}
            viewBox="0 0 39 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="objects">
                <g id="Group">
                    <path id="Vector" d="M19.5036 45.4999C27.5569 45.4999 34.0853 44.7216 34.0853 43.7617C34.0853 42.8017 27.5569 42.0234 19.5036 42.0234C11.4503 42.0234 4.92188 42.8017 4.92188 43.7617C4.92188 44.7216 11.4503 45.4999 19.5036 45.4999Z" fill={fill ? fill : "#DBDBDB"} />
                    <g id="Group_2">
                        <path id="Vector_2" d="M19.5002 39.9965C21.9122 39.9965 23.8676 38.0411 23.8676 35.6291C23.8676 33.2171 21.9122 31.2617 19.5002 31.2617C17.0882 31.2617 15.1328 33.2171 15.1328 35.6291C15.1328 38.0411 17.0882 39.9965 19.5002 39.9965Z" fill={fill ? fill : "#E5E5E5"} />
                        <path id="Vector_3" d="M23.8709 35.6283C23.8709 38.0407 21.916 39.9957 19.5035 39.9957C17.5287 39.9957 15.8606 38.6854 15.3203 36.8863C16.113 37.6923 17.2154 38.1918 18.4354 38.1918C20.8479 38.1918 22.8028 36.2368 22.8028 33.8244C22.8028 33.3867 22.7382 32.9651 22.6186 32.5664C23.3924 33.3544 23.8709 34.4359 23.8709 35.6283Z" fill={fill ? fill : "#D1D1D1"} />
                        <path id="Vector_4" d="M38.4447 31.8834V33.4547C38.4447 34.2883 37.7687 34.9643 36.9351 34.9643H2.07205C1.23847 34.9643 0.5625 34.2883 0.5625 33.4547V31.8834C0.5625 31.2767 0.926174 30.7279 1.4853 30.4924C2.50023 30.0633 3.33766 29.3521 3.92151 28.472C4.50546 27.5919 4.83485 26.5428 4.83485 25.4414V17.8631C4.83485 10.5696 10.1896 4.40211 17.1612 3.25517C17.1404 3.1308 17.13 3.00362 17.13 2.87354C17.13 1.56234 18.1924 0.5 19.5035 0.5C20.8146 0.5 21.877 1.56244 21.877 2.87354C21.877 3.00168 21.8666 3.12703 21.8466 3.24948C24.9588 3.74883 27.7435 5.22719 29.8759 7.35959C32.5306 10.0141 34.1721 13.6818 34.1721 17.7321V25.4415C34.1721 27.6451 35.4918 29.6352 37.5217 30.4925C37.8017 30.6102 38.0316 30.8059 38.1929 31.0488C38.3535 31.2909 38.4447 31.5805 38.4447 31.8834Z" fill={fill ? fill : "#F2BE2A"} />
                        <path id="Vector_5" d="M38.4434 31.8834V33.4547C38.4434 34.2883 37.7674 34.9643 36.9339 34.9643H2.875L16.2259 33.3075C24.0397 32.3382 29.6982 25.357 28.9967 17.5148C28.4716 11.6454 25.8228 7.21156 20.1432 3.44329C20.8345 1.5074 19.4122 0.880767 18.5872 0.68232C18.8682 0.56557 19.1777 0.5 19.5024 0.5C20.8136 0.5 21.876 1.56244 21.876 2.87354C21.876 3.00168 21.8655 3.12703 21.8455 3.24948C24.9577 3.74883 27.7425 5.22719 29.8749 7.35959C32.5295 10.0141 34.1711 13.6818 34.1711 17.7321V25.4415C34.1711 27.6451 35.4908 29.6352 37.5206 30.4925C37.8007 30.6102 38.0305 30.8059 38.1919 31.0488C38.3523 31.2909 38.4434 31.5805 38.4434 31.8834Z" fill={fill ? fill : "#E2AD27"} />
                        <path id="Vector_6" d="M16.1957 5.38486C14.172 5.87243 8.46084 7.99055 6.73285 16.5521C6.69384 16.7453 6.8412 16.9264 7.03839 16.9264H7.13689C7.25161 16.9264 7.35707 16.8637 7.41124 16.7625C8.00706 15.6494 11.6413 9.20769 16.5992 7.92363C16.8912 7.84801 17.0867 7.57386 17.0643 7.27305L16.9661 5.94688C16.9377 5.56187 16.5712 5.29438 16.1957 5.38486Z" fill={fill ? fill : "#FFD671"} />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default BellStaticIcon