import { Suspense, useEffect, useState } from "react";
import UserInteractiveIcon from "../../../../resources/icons/interactive/UserInteractiveIcon";
import Styles from "./toast-notification-item.module.css";
import ClonosSkeletonLoading from "../../../../components/CommonComponents/ClonosSkeletonLoading/ClonosSkeletonLoading";
import CloseInteractiveIcon from "../../../../resources/icons/interactive/CloseInteractiveIcon";
import { getDayMonthYear, handleClientDateInConfiguredTimeZone, modifyString } from "../../../../utils/clonosCommon";
import { CLONOS_GLOBAL_MODULE_ASSETS, CLONOS_GLOBAL_MODULE_AUDIT_AND_INSPECTION, CLONOS_GLOBAL_MODULE_CHECKLISTS, CLONOS_GLOBAL_MODULE_LOGS, CLONOS_GLOBAL_MODULE_PLANT_3D, CLONOS_GLOBAL_MODULE_REPORTS, CLONOS_GLOBAL_MODULE_SPARES, CLONOS_GLOBAL_MODULE_WORK_ORDERS } from "../../../../Constants/Constant";
import { handleViewModuleEntity } from "../../managers";
import { useNavigate } from "react-router-dom";
import { activityENumsStaticData, moduleMapStaticData } from "../../../../Constants/StaticData";


const NotificationItem = ({ notification }) => {
    console.log('notification:', notification)
    const [active, setActive] = useState(false);
    const [progressActive, setProgressActive] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        let timer1, timer2, timer3;

        if (active) {
            setProgressActive(true);
            timer1 = setTimeout(() => {
                timer3 = setTimeout(() => {
                    document.getElementById(notification.key).remove();
                }, 1000)
                setActive(false);
            }, 5000);
            timer2 = setTimeout(() => {
                setProgressActive(false);
            }, 5300);
        }


        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
        };


    }, [active]);

    useState(() => {
        const timer = setTimeout(() => {
            setActive(true);
        }, 100);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const closeNotification = () => {
        setActive(false);
        setTimeout(() => {
            document.getElementById(notification.key).remove();
        }, 1000)
        setTimeout(() => {
            setProgressActive(false);
        }, 300);
    };


    return (
        <div id={notification?.key} key={notification?.key} className={Styles.toast_contianer}>
            <div className={`${Styles.toast} ${active ? Styles.active : ''}`}>
                <div className={Styles.toastContent}>
                    <Suspense fallback={<ClonosSkeletonLoading variant='circular' />}>
                        <UserInteractiveIcon />
                    </Suspense>
                    <div className={Styles.details}>
                        <header className={Styles.header} >
                            <div className={Styles.action_container}>
                                <h3 className={Styles.action}>{`${moduleMapStaticData[notification?.moduleName]} ${activityENumsStaticData[notification?.activity]}`}</h3>
                                <span>{(() => {
                                    const time = handleClientDateInConfiguredTimeZone({ dateString: notification?.createdAt, parseType: "object" })
                                    return `${time?.hour}:${time?.minute}`
                                })()}</span>
                            </div>
                            <CloseInteractiveIcon variant="hollow" onClick={closeNotification} />
                        </header>
                        <p>{`"${notification?.moduleDetails?.name}" ${notification?.message}`}</p>
                        <span
                            className={Styles.view}
                            onClick={() => handleViewModuleEntity(
                                {
                                    moduleName: notification?.moduleName,
                                    entityId: notification?.moduleDetails?.id,
                                    entityName: notification?.moduleDetails?.name,
                                    navigate,
                                    handleCloseDrawer: closeNotification,
                                    notificationId: notification?.id ? notification?.id : null,
                                    activity: notification?.activity ? notification?.activity : null
                                }
                            )}>View</span>
                    </div>
                </div>
                <div className={`${Styles.progress} ${progressActive ? Styles.active : ''}`}></div>
            </div>
        </div>
    )
}


export default NotificationItem;