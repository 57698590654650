// src/socket.js
import { io } from 'socket.io-client';
import { getToken } from '../utils/clonosCommon';
import configs from '../config';

const SOCKET_URL = configs?.url;
// const SOCKET_URL = "http://192.168.68.109:3333";

console.log('SOCKET_URL:', SOCKET_URL)
const socket = io(SOCKET_URL, {
    transports: ['websocket'],
    auth: {
        token: getToken()
    },
    token: getToken()
});


// socket.on('notification', (data) => {
//     console.log('notificationData:', data);
//     alert("HI")
// });

export default socket;
