import { Tooltip } from "@mui/material";
import useToggler from "../../../CustomHooks/TogglerHook";
import { CLONOS_GLOBAL_COLOR_ICON_BLACK, CLONOS_GLOBAL_COLOUR_BLUE_HOVER } from "../../../Constants/Constant";

const RetryInteractiveIcon = ({ height, width, size, color, onClick, tooltipTitle = 'Retry', strokeWidth, strokeColor, style }) => {
  let [isHover, setIsHover] = useToggler();
  return (
    <Tooltip title={tooltipTitle}>
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => onClick()}
        style={{ padding: "0px", margin: "0px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content", height: "fit-content", cursor: "pointer", ...style }}
      >

        <svg xmlns="http://www.w3.org/2000/svg" width={size ? size : width ? width : "20"} height={size ? size : height ? height : "20"} viewBox="0 0 20 20" fill="none">
          <path d="M3.375 9.16741C3.58574 7.55969 4.37518 6.08389 5.59557 5.01626C6.81595 3.94863 8.38359 3.36237 10.0051 3.36722C11.6265 3.37207 13.1906 3.96769 14.4046 5.0426C15.6186 6.11751 16.3992 7.59801 16.6003 9.20696C16.8014 10.8159 16.4093 12.443 15.4972 13.7837C14.5852 15.1243 13.2158 16.0866 11.6455 16.4905C10.0751 16.8943 8.41136 16.7119 6.96573 15.9776C5.5201 15.2432 4.39166 14.0071 3.79167 12.5007M3.375 16.6674V12.5007H7.54167" stroke={color ? color : "#D24B5A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

      </div>
    </Tooltip>
  )
}

export default RetryInteractiveIcon
