import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allTeams:{},
}

const teamsSlice = createSlice({
    name:"teamsData",
    initialState,
    reducers:{
        getAllTeams(state,action){
            state.allTeams = action.payload
        }
    }
})

export const teamsActions = teamsSlice.actions;
export default teamsSlice.reducer 