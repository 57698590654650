const ActiveRight = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<path d="M7.16406 12L11.1641 8L7.16406 4V12Z" stroke={color ? color : "white"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const Right = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M4 6.66797L8 10.668L12 6.66797H4Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4 6.66797L8 10.668L12 6.66797H4Z" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export {
    ActiveRight,
    Right
}
