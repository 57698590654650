import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { commonActions } from "../Store/Reducers/CommonReducer";
import { globalEntitiesActions } from "../Store/Reducers/ClonosGlobalEntities";
import CryptoJS from "crypto-js";
import { userActions } from "../Store/Reducers/ClonosUserReducer";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { doGetApiCall, doPostApiCall } from "./ClonosApi";
import ClonosSkeletonLoading from "../components/CommonComponents/ClonosSkeletonLoading/ClonosSkeletonLoading";

import {
  CLONOS_GLOBAL_ACTION_CREATE,
  CLONOS_GLOBAL_ACTION_DECOMMISSION,
  CLONOS_GLOBAL_ACTION_DELETE,
  CLONOS_GLOBAL_ACTION_DUE_DATE_EXTENSION,
  CLONOS_GLOBAL_ACTION_READ,
  CLONOS_GLOBAL_ACTION_RESTORE,
  CLONOS_GLOBAL_ACTION_UPDATE,
  CLONOS_GLOBAL_MODULE_ASSETS,
  CLONOS_GLOBAL_MODULE_CHECKLISTS,
  CLONOS_GLOBAL_MODULE_DASHBOARD,
  CLONOS_GLOBAL_MODULE_DOCUMENTS,
  CLONOS_GLOBAL_MODULE_LOGS,
  CLONOS_GLOBAL_MODULE_MAINTENANCE_PLANS,
  CLONOS_GLOBAL_MODULE_PLANT_3D,
  CLONOS_GLOBAL_MODULE_TASK_LIBRARY,
  CLONOS_GLOBAL_MODULE_TEAMS,
  CLONOS_GLOBAL_MODULE_USERS,
  CLONOS_GLOBAL_MODULE_WORK_ORDERS,
  CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_CHECKBOXES,
  CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE,
  CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DROPDOWN,
  CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE,
  CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_NUMBER,
  CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_TEXT,
  CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS,
  CLONOS_GLOBAL_SERVER_RESPONSE_CODE_NOTFOUND_ERROR,
  CLONOS_GLOBAL_ACTION_FILL_ENTRY,
  CLONOS_GLOBAL_ACTION_FILL_UPDATE_ENTRY,
  CLONOS_GLOBAL_MODULE_REPORTS,
  CLONOS_GLOBAL_TIME_ZONE_ISO,
  CLONOS_GLOBAL_TIME_ZONE_UTC,
  CLONOS_GLOBAL_TIME_ZONE_IST,
  CLONOS_GLOBAL_CLIENT_TIME_ZONE,
  CLONOS_GLOBAL_SERVER_TIME_ZONE
} from "../Constants/Constant";

import configs from "../config";
import AppUrl from "./AppUrl";
import { monthStringStaticData, sidebarModuleListPriorityListStaticData } from "../Constants/StaticData";
// import { postAuditLog } from "../Api/User/UserApi";

// import { loginUser } from "../Api/User/UserApi";

// return the user data from the session storage
export function getUser() {
  const userStr = localStorage.getItem("loginUser") || null;
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => Cookies.get("token") || null;

export const getRefreshToken = () => {
  return Cookies.get("refreshToken") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  Cookies.remove("token");
  // localStorage.removeItem('loginUser');
  Cookies.remove("refreshToken");
  Cookies.remove("tokenExpires");

  localStorage.removeItem("loginUser");
  if (localStorage.getItem("unity")) {
    localStorage.removeItem("unity"); // If this function is getting use by direct login jump.
  } else if (localStorage.getItem("AR")) {
    localStorage.removeItem("AR"); // If this function is getting use by direct login jump.
  } else if (localStorage.getItem("VR")) {
    localStorage.removeItem("VR"); // If this function is getting use by direct login jump.
  }
};

// set the token and user from the session storage
export const setUserSession = ({ token, refreshToken, userDetails }) => {
  const tokenDecoded = jwt_decode(token);
  const tokenExpiry = new Date(tokenDecoded.exp * 1000);
  const { permission, id, businessUnit, isSuperAdmin } = tokenDecoded;
  // const refreshTokenDecoded = jwt_decode(refreshToken);
  // const refreshTokenExpiry = new Date(refreshTokenDecoded.exp * 1000);

  const loginUser = {
    role: userDetails?.designation,
    userId: id,
    permissions: handleTakeControlOnPermission(permission),
    role_id: userDetails?.designation?.id,
    businessUnit: userDetails?.businessUnit,
    ...userDetails,
  };
  console.log('loginUser:', loginUser)
  Cookies.set("token", token, { expires: tokenExpiry });
  localStorage.setItem("loginUser", JSON.stringify(loginUser));
};

// function decodeToken(token) {
//   try {
//       const decoded = jwt.decode(token, { complete: true });
//       return decoded;
//   } catch (error) {
//       console.error('Error decoding token:', error.message);
//       return null;
//   }
// }

export const fileToBinaryData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", (event) => {
      const binaryData = event.target.result;
      resolve(binaryData);
    });

    reader.addEventListener("error", (error) => {
      reject(error);
    });

    reader.readAsArrayBuffer(file);
  });
};

export function binaryDataToFile(binaryData, fileName, fileType) {
  const blob = new Blob([binaryData], { type: fileType });

  // Create a new file upload object with the Blob data
  const file = new File([blob], fileName, { type: fileType });

  return file;
}

export function getFormatedDate(date) {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const formattedDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1
    }/${dateObject.getFullYear()}`;
  return `${day}/${month}/${year}`;
}

export function getFormatAMPM(d) {
  const date = new Date(d);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export const getDayMonthYear = (date) => {
  console.log('dateeeeeeeeee:', date)
  if (date === null) {
    return { day: 31, month: 12, year: 9999 };
  }
  const dateObject = new Date(date);
  console.log('dateObject:', dateObject)
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  let hours = dateObject?.getHours();
  hours = String(hours).length == 1 ? `0${hours}` : hours;
  let minutes = dateObject?.getMinutes();
  minutes = String(minutes).length == 1 ? `0${minutes}` : minutes;
  const seconds = dateObject?.getSeconds();
  console.log('secondssss:', seconds)
  const time = `${hours}:${minutes}:${seconds?.length === 1 ? `0${seconds}` : seconds}`;
  console.log('timeeee:', time)
  const monthString = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return {
    day,
    month,
    year,
    monthString: monthString[month - 1],
    time,
    hours,
    minutes,
    seconds,
  };
};
/**
 *
 * @returns An object with user details like (email,id,name,permissions array, role, role_id, userId)
 */
export const handleLoggedInUser = () => {
  return JSON.parse(localStorage.getItem("loginUser"));
};

export const handleLoginExpiry = (err, dispatch) => {
  if (
    err?.response?.data?.status === 401 &&
    JSON.parse(localStorage.getItem("loginUser")) !== null
  ) {
    dispatch(commonActions.handleExpiryAlert(true));
    removeUserSession();
    localStorage.removeItem("loginUser");

    if (localStorage.getItem("unity")) {
      localStorage.removeItem("unity"); // If this function is getting use by direct login jump.
    } else if (localStorage.getItem("AR")) {
      localStorage.removeItem("AR"); // If this function is getting use by direct login jump.
    } else if (localStorage.getItem("VR")) {
      localStorage.removeItem("VR"); // If this function is getting use by direct login jump.
    }
  }
};

// Function to restrict the latitude to values between -90 and 90

export const limitDecimalDigits = (num) => {
  console.log(num, "nummun")
  if (num?.indexOf(".") !== -1 && num?.split(".")[1]?.length > 4) {
    return num?.split(".")[0] + "." + num?.split(".")[1]?.slice(0, 4);
  }
  return num;
};

export const limitLatitude = (num) => {
  if (num < -90) {
    return "-90";
  }
  if (num > 90) {
    return "90";
  }
  return num;
};

export const limitLongitude = (num) => {
  if (num < -180) {
    return -180;
  }
  if (num >= 180) {
    return 180;
  }
  return num;
};

export const handleGetWidthDynamicallyBasedOnWindowWidth = ({
  widthInPercentage,
}) => {
  // Get the width of the screen
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  // Calculate 95% of the screen width
  const widthInPixels = (widthInPercentage / 100) * screenWidth;
  return widthInPixels;
};

const handleCollectDocumentsForMultipleDelete = ({
  itemId,
  needToDelete,
  setNeedToDelete,
  setIsEdit,
  setChecked,
  checked,
}) => {
  if (!needToDelete?.includes(itemId)) {
    setNeedToDelete((prev) => {
      let updateValue = [...prev, itemId];
      if (updateValue.length >= 2) setIsEdit(true);
      return updateValue;
    });
  } else {
    let updateValue = needToDelete?.filter((item) => item != itemId);
    if (updateValue.length < 2) {
      setIsEdit(false);
      setChecked(false);
      if (checked) updateValue = [];
    }
    setNeedToDelete(updateValue);
  }
};

export function handleGetRemainingWidthAndHeightOfBody({ width, height }) {
  const bodyWidth = document.body.clientWidth;
  const bodyHeight = window.innerHeight;

  const widthPercent = (width / bodyWidth) * 100;
  const heightPercent = (height / bodyHeight) * 100;

  const widthViewport = (width / bodyWidth) * 100;
  const heightViewport = (height / bodyHeight) * 100;

  const response = {
    pixelUnit: {
      currentPart: {
        height: height + "px",
        width: width + "px",
      },
      remainingPart: {
        height: bodyHeight - height + "px",
        width: bodyWidth - width + "px",
      },
    },
    percentUnit: {
      currentPart: {
        height: `${heightPercent}%`,
        width: `${widthPercent}%`,
      },
      remainingPart: {
        height: `${100 - heightPercent}%`,
        width: `${100 - widthPercent}%`,
      },
    },
    viewPortUnit: {
      currentPart: {
        height: `${heightViewport}vh`,
        width: `${widthViewport}vh`,
      },
      remainingPart: {
        height: `${100 - heightViewport}vh`,
        width: `${100 - widthViewport}vh`,
      },
    },
    remUnit: {
      currentPart: {
        height: height / 16 + "rem",
        width: width / 16 + "rem",
      },
      remainingPart: {
        height: (bodyHeight - height) / 16 + "rem",
        width: (bodyWidth - width) / 16 + "rem",
      },
    },
  };
  return response;
}

export const updateLayout = ({ dispatch }) => {
  let unity = localStorage.getItem("unity");
  let getLayoutDimensions = document.querySelector(".main_toolbar");
  let response = handleGetRemainingWidthAndHeightOfBody({
    width: unity ? 0 : getLayoutDimensions?.clientWidth,
    height: unity ? 0 : getLayoutDimensions?.clientHeight,
  });
  // let response = handleGetRemainingWidthAndHeightOfBody({ width: getLayoutDimensions?.clientWidth, height: getLayoutDimensions?.clientHeight })
  dispatch(globalEntitiesActions.setMainLayoutChildrenPosition(response));
};

export const handleUpdateLayoutDelaySetter = ({ dispatch }) => {
  let interval = setTimeout(() => {
    updateLayout({ dispatch });
  }, 1000);
  return interval;
};

// TO HANDLE DROPDOWN VALUES FOR SINGLE AND MULTIPLE SELECT
// item : item to be added or removed
// setFormData : method to set or remove selected value
//  type : key name for the selected value
// multiSelect : if requirement is multiple select then it should be true.
export const handleSelectvalue = ({
  item,
  setFormData,
  type,
  multiSelect,
  FormData,
  updateLocalData,
  updateChangedData,
  changedData,
}) => {
  setFormData((prev) => {
    const temp = prev[type] || [];
    const isPresent = temp.length
      ? temp?.some((field) => field?.id === item?.id)
      : false;

    if (multiSelect) {
      if (isPresent) {
        const updatedTemp = temp?.filter((field) => field?.id !== item?.id);
        return { ...prev, [type]: updatedTemp };
      } else {
        return { ...prev, [type]: [...temp, item] };
      }
    } else {
      if (updateLocalData) {
        updateLocalData((prev) => ({ ...prev, [type]: item }));
      }
      if (updateLocalData && updateChangedData) {
        let temp = {
          ...prev,
          [type]: item,
        };
        let changeIn = {
          ...changedData,
          [type]: item,
        };
        updateChangedData(changeIn);
        updateLocalData(temp);
      }
      return { ...prev, [type]: item };
    }
  });
};

export const handleDeselectValue = ({
  item: item,
  setFormData,
  type,
  multiSelect,
  formData,
}) => {
  if (!multiSelect) {
    delete formData[type];
    setFormData({ ...formData });
  }
};
export const debounce = (func, delay) => {
  let timeoutId;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
};
const performSearch = (props) => {
  const { e, type, setMethod } = props;
  const { value } = e.target;
  setMethod(value);
};
export const handleSearchedData = debounce(performSearch, 500);

export const getYYYYMMDD_Format = (isoDateString) => {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
  const day = date.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const handleDecryptData = async ({ encryptedData, key }) => {
  try {
    let keys = CryptoJS.enc.Utf8.parse(key);
    let base64 = CryptoJS.enc.Base64.parse(encryptedData.toString());

    let src = CryptoJS.enc.Base64.stringify(base64);
    let decrypt = CryptoJS.AES.decrypt(src, keys, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    //  var bytes = CryptoJS.AES.decrypt(user.password.toString(), "clonoskeyunity3D", { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedData = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (err) { }
};

/**
 * This function is getting use to replicate the req.query functionality in the frontend
 * @param {*} url This will contain the url once the request happen from the UNITY SIDE
 * @returns {} Output will look like this {unity:1/0,token:string}
 */
export function handleSegregateURL() {
  let url = window.location.href;
  let output = {};
  let splitedByAnd = url?.split("?")[1]?.split("&"); // First we splitting the url based on "?" then again we are spliting the based on "&"
  splitedByAnd?.map((ele) => {
    // Here we are creating the output object  with time complexity of (n^2)
    let elementArray = ele?.split("=");
    output[elementArray[0]] = elementArray[1];
  });

  let targetRoute = ""; // This will contain that specific path in which Unity want's to jump.
  let flag = false; // This will take or our length of the string that from where to where we need the string.
  let count = 0;
  for (let i = 1; i < url.length; i++) {
    // Iteration on the string
    if (url[i - 1] == "/") {
      count++;
      if (count == 3) flag = true;
    }

    if (url[i] == "?") {
      flag = false;
    }
    if (flag) targetRoute += url[i];
  }
  output["targetRoute"] = targetRoute;
  return output;
}

/**
 * Login session setter function for Unity team.
 * @param {*} token This will contain the token
 */
export function handleSessionForUnity({ token, refreshToken, dispatch }) {
  const tokenDecoded = jwt_decode(token);
  let permissions = tokenDecoded?.permissions?.split(", ");
  const tokenExpiry = new Date(tokenDecoded.exp * 1000);

  Cookies.set("token", token, { expires: tokenExpiry });
  Cookies.set("tokenExpires", JSON.stringify(tokenExpiry));

  const refreshTokenDecoded = jwt_decode(refreshToken);
  const refreshTokenExpiry = new Date(refreshTokenDecoded.exp * 1000);

  const loginUser = {
    id: tokenDecoded?.id,
    email: tokenDecoded?.email,
    role: tokenDecoded?.role,
    userId: tokenDecoded?.userId,
    permissions,
    role_id: tokenDecoded?.role_id,
    email: tokenDecoded?.email,
  };

  Cookies.set("token", token, { expires: tokenExpiry });
  Cookies.set("refreshToken", refreshToken, { expires: refreshTokenExpiry });
  Cookies.set("tokenExpires", JSON.stringify(tokenExpiry));
  localStorage.setItem("loginUser", JSON.stringify(loginUser));
  // if (loginUser.length) dispatch(commonActions.handleExpiryAlert(false));
}

/**
 * This function will decrypt the token and resolved the promise also and set the session of the user. (Login Jump)
 * @param {*} param0 it takes one object
 * @returns null
 */

export const handleMakePromiseFullfilled = async ({ dispatch }) => {
  let URL = handleSegregateURL();
  if (URL?.unity) {
    localStorage.setItem("unity", 1); // If this function is getting use by direct login jump.
  } else if (URL?.AR) {
    localStorage.setItem("AR", 1); // If this function is getting use by direct login jump.
  } else if (URL?.VR) {
    localStorage.setItem("VR", 1); // If this function is getting use by direct login jump.
  }
  try {
    // let decryptedToken = await handleDecryptData({
    //   encryptedData: URL["token"],
    //   key: "clonoskeyunity3D",
    // }); // Here we are decrypting our token, which are encrypted from the Unity side.
    // let decryptedRefreshToken = await handleDecryptData({
    //   encryptedData: URL["refreshToken"],
    //   key: "clonoskeyunity3D",
    // });
    // if (decryptedRefreshToken.length && decryptedToken.length) {
    //   dispatch(
    //     userActions.setTokenMethod({
    //       decryptedToken,
    //       decryptedRefreshToken,
    //       unity: true,
    //     })
    //   ); // Here we are saving the information of the user in the store.
    //   // dispatch(userActions.setTokenMethod({ decryptedToken: URL.token, decryptedRefreshToken: URL.refreshToken, unity: true, })); // Here we are saving the information of the user in the store.
    //   // handleSessionForUnity({
    //   //   token: decryptedToken,
    //   //   refreshToken: decryptedRefreshToken,
    //   //   dispatch,
    //   // }); // Here we are saving all nessessary information for run our app.
    //   alert("dep")
    // }
    handleSessionForUnity({
      token: URL.token,
      refreshToken: URL.refreshToken,
      dispatch,
    }); // Here we are saving all nessessary information for run our app.
  } catch (err) { }
};

/**
 * "This function stops app loading during login jumps from Unity."
 * @param {Function} setterFunction - Manages component rendering for "Unauthorised" and "Login Jump."
 * @param {timeout} timeout - This will store the interval ID
 * @returns
 */
export const loginJumpLoadingStopper = ({ setterFunction, timeout }) => {
  let timer = null;
  timer = setTimeout(() => {
    setterFunction(true);
  }, timeout || 5000);
  return timer;
};

// export const dummyEncryptionToken = ({ text }) => {
//   // const parsedkey = CryptoJS.enc.Utf8.parse(CLONOS_ENCRYPT_PASSWORD_SECRET_KEY);
//   // const encrypted = CryptoJS.AES.encrypt(token, parsedkey, {
//   //   mode: 'aes-256-ecb',
//   //   padding: 32,
//   // });
//   // const encryptedValue = encrypted.toString();
//   // return encryptedValue;
//   const key = CLONOS_ENCRYPT_PASSWORD_SECRET_KEY; // Replace with a secure method to obtain a key
//     const encrypted = CryptoJS.AES.encrypt(text, key, {
//       mode: CryptoJS.mode.ECB,
//     }

//     ).toString();
//     return encrypted
// };
// export const dummyEncryptionToken = ({ text }) => {
//   const key = "WEB@CLONOS" //process.env.REACT_APP_CLONOS_ENCRYPT_PASSWORD_SECRET_KEY;
//   const paddedKey = key.padEnd(32, "0"); // Ensure key length is 32 bytes (256 bits)
//   const iv = CryptoJS.lib.WordArray.random(32);
//   const encrypted = CryptoJS.AES.encrypt(text, paddedKey, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//   }).toString();
//   return encrypted;
// };

export const dummyEncryptionToken = (textToEncrypt) => {
  const secretKey = process.env.REACT_APP_CLONOS_ENCRYPT_PASSWORD_SECRET_KEY; // Replace with a secure method to obtain a key
  const paddedKey = CryptoJS.enc.Utf8.parse(secretKey.padEnd(32, "0")); // Ensure key length is 32 bytes (256 bits)
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random 16-byte IV

  // Encrypt the text using AES-256-CBC
  const encrypted = CryptoJS.AES.encrypt(textToEncrypt, paddedKey, { iv });

  // Concatenate IV and ciphertext
  const encryptedText = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);

  return encryptedText;
};

export const decryptToken = (encryptedText, secretKey) => {
  const paddedKey = secretKey.padEnd(32, "0"); // Ensure key length is 32 bytes (256 bits)
  const iv = CryptoJS.lib.WordArray.random(32);
  const decryptedText = CryptoJS.AES.decrypt(encryptedText, paddedKey, {
    iv: CryptoJS.enc.Hex.parse(iv),
    mode: CryptoJS.mode.CBC
  }).toString(CryptoJS.enc.Utf8);
  return decryptedText;
};

export function getCurrentDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  return `${mm}/${dd}/${yyyy}`;
}

/**
 * Show an error or warning message using a snackbar notification.
 *
 *   @param {Object} options - An object containing the following properties:
 *   @property {function} dispatch - A function to dispatch actions (e.g., Redux dispatch).
 *   @property {string} type - The type of the message (e.g., 'error' or 'warning').
 *   @property {string} message - The message to display in the snackbar.
 *   @property {number} showTime - The duration (in milliseconds) to display the snackbar (optional, default is 500 milliseconds).
 */
export const handleShowErrorAndWarning = ({
  dispatch,
  type,
  message,
  showTime,
}) => {

  let lcType = null;
  let lcMessage = null;
  if (typeof message !== "object") {
    lcMessage = message;
    lcType = type;
  } else {
    lcMessage = "Please provide the valid error message";
    lcType = "warning"
  };

  // Dispatch an action to show a snackbar notification.
  dispatch(
    commonActions.handleSnackbar({
      show: true, // Show the snackbar.
      message: lcMessage, // Set the message.
      type: lcType, // Set the message type (e.g., 'error' or 'warning').
      closeIn: showTime || 1000, // Set the duration to close the snackbar (default: 500 milliseconds).
    })
  );
};

/**
 * Get the height and width of an HTML element based on its ID.
 *
 * @param {Object} options - An object containing the following property:
 * @property {string} idName - The ID of the HTML element to query.
 *
 * @returns {Object} - Returns an object with the `height` and `width` properties representing the element's client height and width, respectively.
 *                   - Returns null if the element with the specified ID is not found.
 */
export const handleGetHeightOrWidthBasedOnId = ({ idName }) => {
  let element = document.getElementById(idName);
  return { height: element?.clientHeight, width: element?.clientWidth };
};

/**
 * Handle redirection to a target route after a specified timeout.
 *
 * @param {Object} options - An object containing the following properties:
 *   @property {string} targetRoute - The route to navigate to after the timeout.
 *   @property {number} timeout - The timeout duration in milliseconds before redirection.
 *   @property {function} navigate - This is the navigate function which is passing from the parent (react-router-dom)
 * @returns {number} - Returns the ID of the timeout interval, which can be used to cancel the timeout if needed.
 */
export const handleRedirectAfterEvent = ({
  targetRoute,
  timeout,
  navigate,
}) => {
  let interval = null;

  // Set a timeout to navigate to the targetRoute after the specified timeout duration.
  interval = setTimeout(() => {
    navigate(targetRoute);
    clearInterval(interval);
  }, timeout);

  // Return the ID of the timeout interval for potential cancellation.
  return interval;
};

export const handleMakeCamelCase = ({ string }) => {
  console.log('string:', string)
  let splittedString = string?.split(" ");
  splittedString = splittedString?.map((ele, index) => {
    if (index === 0) return ele?.toLowerCase();
    else
      return ele?.charAt(0)?.toUpperCase() + ele?.split("")?.slice(1)?.join("");
  });
  console.log('splittedString:', splittedString)
  return splittedString?.join("");
};

/**
 * Compare two date strings to check if the first date is greater than the second date.
 *
 * @param {string} dateString1 - The first date string (e.g., "2023-10-27").
 * @param {string} dateString2 - The second date string (e.g., "2023-10-26").
 * @returns {boolean} - `true` if the first date is greater than the second date, `false` otherwise.
 */
export function isDate1GreaterThanDate2(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  return date1 > date2;
}

/**
 *
 * @param {Object} => An object which contain "data" and "type"
 * @property {Array} data => "data" will be Array that will contain and object and all object will contain the keys and values , All keys will denote the column name and values will be as the value of the keys
 * @property {String} type => type will be the type of format in which you want to export details exp. ("xlsx","pdf","csv","excel").
 * @property {String} fileName => Name of that file which you want to download.
 */
export const exportInformation = ({
  data,
  type,
  fileName = "data",
  pageType,
}) => {
  if (type === "xlsx") {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "data");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  } else if (type === "pdf") {
    handleGeneratePDF({ childRefs: data, layout: pageType, fileName });
  } else if (type === "csv") {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (type === "excel") {
  }
};

/**
 * Generate a PDF document from an array of child components using jsPDF and html2canvas.
 * @param {Object} options - The options for generating the PDF.
 * @param {Array} options.childRefs - An array of refs to child components to include in the PDF.
 * @param {string} options.layout - The layout of the PDF ("a4" for portrait, "l" for landscape).
 * @param {string} options.fileName - The desired name for the generated PDF file.
 * @param {string} options.pageType - This will be type of page exp: a4 / horizontal
 */
export const handleGeneratePDF = async ({
  childRefs,
  layout,
  fileName,
  pageType,
}) => {
  // Create a new instance of jsPDF
  let dimensions = layout === pageType ? [210, 297] : [297, 210];
  let lcLayout = layout === pageType ? "p" : "l";
  const pdf = new jsPDF(lcLayout, "mm", dimensions, true);

  // Get the width and height of the PDF page
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  // Loop through each child component
  for (let index = 0; index < childRefs.length; index++) {
    const childRef = childRefs[index];

    // Retrieve the input element from the childRef
    const input = childRef;

    // Convert the input element to a canvas using html2canvas library with higher scale
    const canvas = await html2canvas(input, { useCORS: true, scale: 2 });

    // Get the data URL of the canvas as an image
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    // Calculate the scaling ratio for the image to fit in the PDF page
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

    // Set the initial position of the image in the PDF
    const imgX = 0;
    const imgY = 0;

    // Add a new page for each child component, except the first one
    if (index !== 0) {
      pdf.addPage();
    }

    // Add the image to the PDF with potentially higher DPI
    const dpi = 300; // Experiment with this value
    pdf.addImage(
      imgData,
      "PNG",
      imgX,
      imgY,
      imgWidth * ratio,
      imgHeight * ratio
    );

    // Save the PDF if it's the last child component
    if (index === childRefs.length - 1) {
      pdf.save(`${fileName}.pdf`);
    }
  }
};

/**
 * Generate a unique ID of the specified length using random alphanumeric characters.
 * @param {number} length - The length of the desired unique ID.
 * @returns {string} - The generated unique ID.
 */
export const generateUniqueId = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uniqueId = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueId += characters.charAt(randomIndex);
  }

  return uniqueId;
};

export const toCamelCase = (inputString) => {
  // Split the string into an array of words
  const words = inputString.split(" ");

  // Capitalize the first letter of each word (except the first one)
  const camelCaseWords = words.map((word, index) =>
    index === 0
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the words back into a single string
  const camelCaseString = camelCaseWords.join("");

  return camelCaseString;
};

export const handleGetENVVariables = () => {
  return process.env;
};

// Decripting the id for direct login
export const handleDecryptURLKeys = async ({ URLkeyName, stringValue }) => {
  const URL = handleSegregateURL();
  const descriptedKey = await handleDecryptData({
    encryptedData: URL[URLkeyName] ? URL[URLkeyName] : stringValue,
    key: handleGetENVVariables().REACT_APP_SECRET_KEY_FOR_UNITY,
  });
  return descriptedKey;
};

export const handleEncryptionURLKeys = ({ needToEncrypt }) => {
  let encrypted = dummyEncryptionToken(needToEncrypt);
  return encrypted;
};

export const handleCreateURLForDirectLogingJump = ({ pathParam }) => {
  let refreshToken = Cookies.get("refreshToken");
  let token = Cookies.get("token");
  localStorage.setItem(
    "lcCred",
    `localhost:3000/work-order-view?unity=1&token=${token}&refreshToken=${refreshToken}${pathParam}`
  );
};

export const handleAllowDirectAccessPage = async ({ keyName }) => {
  try {
    const URL = handleSegregateURL();
    const decryptdId = await handleDecryptURLKeys({
      stringValue: URL[keyName],
    });
    return decryptdId;
  } catch (err) { }
};

export const globalHandleSearch = ({ typedString, withCompareString }) => {
  let needToSearch = typedString.toUpperCase();
  let element = withCompareString.toUpperCase();
  let count = 0;
  for (let i = 0; i < needToSearch.length; i++) {
    if (element[i] == needToSearch[i]) count++;
  }
  if (count == needToSearch.length) return true;
  return false;
};

export const getFormattedTime = ({ timestampString, timeZone }) => {
  const date = new Date(timestampString);

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone,
  };

  const formattedTime = date.toLocaleString("en-US", options);

  // Extract hours, minutes, seconds, and zone (AM/PM)
  const [timePart, zone] = formattedTime.split(" ");
  const [hours, minutes, seconds] = timePart.split(":").map(Number);

  return { hours, minutes, seconds, zone };
};

export const restrictSpecialCharacters = ({ value, specialCharacters }) => {
  let charArr = value.split("");
  const newValue = charArr
    .filter((char) => {
      return !specialCharacters.includes(char);
    })
    .join("");
  return newValue;
};

/**
 * This function will return the current date and time.
 * @returns 'yyyy-mm-ddT00:00'
 */
export const globalHandleGetCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// export const globalHandleCatchError = ({ dispatch, error, status, errorMessage, isSnackbar, snackbarMessage, snackbarType, snackbarTimeout, isPostAuditLog, postAuditLogActionTitle }) => {
//   handleLoginExpiry(error, dispatch)
//   if (status !== 401) {
//     isSnackbar && handleShowErrorAndWarning({ dispatch, type: snackbarType, message: snackbarMessage, showTime: snackbarTimeout })
//     if (isPostAuditLog) {
//       const lcError = error.response.data.error ? error.response.data.error : errorMessage;
//       postAuditLog({ action: postAuditLogActionTitle, message: lcError });
//     }
//   }
// }






/**
 * Checks whether the current user has permission for a specific module and action.
 * 
 * @param {object} Object
 * @property {object} moduleName - The name of the module for which permission is being checked.
 * @property {string} action - The action for which permission is being checked. Exp (create,update,delete,read...)
 * @property {boolean} isSideBarPermissionsNeeded - Flag indicating whether sidebar permissions are needed.
 * @returns {boolean} - True if the user has permission, otherwise false.
 */

export function checkUserHasPermission({ moduleName, action, isSideBarPermissionsNeeded }) {
  console.log('moduleName:', moduleName)
  let user = null;
  if (!(user = getUser())) return false;
  const { permissions } = user;
  console.log('permissions:', permissions)
  if (moduleName === "maintenance") return true
  if ((moduleName === "maintenance") && (permissions?.workOrders || permissions?.checkLists || permissions?.logs || permissions?.taskLibrary || permissions?.maintenancePlan)) return true
  // Check if the permission type (module) exists for the user
  if (permissions?.hasOwnProperty(moduleName)) {
    // Check if the user has the required permission (action);
    if (isSideBarPermissionsNeeded) return true;
    if (
      permissions[moduleName]?.hasOwnProperty(action) &&
      permissions[moduleName][action]
    ) {
      return true; // User has the required permission
    } else {
      return false
    }
  }
  // return true; // User does not have the required permission
  return false; // User does not have the required permission
};

export const hasPermission = (route) => {
  let user;
  if (!(user = getUser())) return false;
  const { permissions, role_id } = user;
  let permission = null;

  switch (route) {
    case "/dashboard":
      permission = true;
      break;
    case "/users":
      permission = permissions?.user?.read;
      break;
    case "/profile":
      permission = permissions?.user?.read;
      break;
    case "/create-user":
      permission = permissions?.user?.create;
      break;
    case "/edit-user":
      permission = permissions?.user?.update;
      break;
    case "/delete-user":
      permission = permissions?.user?.delete;
      break;
    case "/teams":
      permission = permissions?.team?.read;
      break;
    case "/team":
      permission = permissions?.team?.read;
      break;
    case "/create-team":
      permission = permissions?.team?.create;
      break;
    case "/edit-team":
      permission = permissions?.team?.update;
      break;
    case "/delete-team":
      permission = permissions?.team?.delete;
      break;
  }
  // const isPermission = permission ? permissions.includes(permission) : true;
  // const isRole = roleId.length > 0 ? roleId.includes(role_id) : true;
  // return isPermission && isRole;
  return permission ? permission : false;
};

export const commonPageState = {
  page: 1,
  limit: 15,
};

export const setSerialNumber = (data, page, limit) =>
  data?.map((a, key) => ({
    ...a,
    index: (page - 1) * limit + key + 1,
  }));

export const arrayToObject = (arr, key, value) => {
  const obj = {};
  for (let i = 0; i < arr.length; ++i) obj[arr[i][key]] = arr[i][value];
  return obj;
};

/**
 * onScrollend function will be call while scrolling the any div.
 * @param {*} callback => Pass the that function that you want call whenever you are at the end of the scroller div.
 * @param {*} idName => Pass the Id name that should be present in the DOM
 * @param {*} args => Any arguments if you have.
 * @returns undefined
 */
export function globalOnScrollEnd(callback, idName, args) {
  // Get the target div by its ID (replace 'yourDivId' with the actual ID of your div)
  const targetDiv = document.getElementById(idName);
  if (!targetDiv) return;
  // Attach a scroll event listener to the div
  targetDiv.addEventListener("scroll", function () {
    // Calculate the scroll position within the div
    const scrollPosition = targetDiv.scrollTop + targetDiv.clientHeight;
    const totalHeight = targetDiv.scrollHeight;
    // Check if the user has reached the bottom
    if (scrollPosition === totalHeight) {
      // Call the provided callback function
      callback(args);
    }
  });
}


/**
 * Manipulates the given data for previewing checklist attributes by converting attribute names,
 * formatting field values, and organizing them according to specific attribute types.
 * 
 * @param {object} data - The data to be manipulated for previewing checklist attributes.
 * @returns {Array} - An array containing manipulated data for previewing checklist attributes.
 */
export const globalManipulateTemplatePreviewData = ({ data }) => {
  const attributeNames = {
    [CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_TEXT]: "Text",
    [CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE]: "Date",
    [CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_NUMBER]: "Number",
    [CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DROPDOWN]: "Dropdown",
    [CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_CHECKBOXES]: "Checkboxes",
    [CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE]: "Multiple Choice",
  };
  const manipulatedDataForPreviewChecklistAttributes = data && data?.map((element) => {
    if (
      element?.type == "text" ||
      element?.type == "date" ||
      element?.type == "number"
    ) {
      return {
        id: element?._id,
        attributeName: attributeNames[element?.type],
        type: element?.type,
        fieldName: element?.fieldName,
        fieldValue: element?.fieldValue,
        index: element?.index ? element?.index : null,
        asset: element?.asset ? element?.asset : null,
      };
    } else {
      return {
        id: element?._id,
        attributeName: attributeNames[element?.type],
        type: element?.type,
        fieldName: element?.fieldName,
        index: element?.index ? element?.index : null,
        fieldValue: element?.fieldValue?.map((item) => {
          return {
            optionId: item?._id || item?.optionId,
            optionValue: item?.optionValue,
            isActive: item?.isActive,
            "_id": item?._id
          };
        }),
        asset: element?.asset ? element?.asset : null,
      };
    }
  });
  return manipulatedDataForPreviewChecklistAttributes;
};

export const showErrorMessage = ({ err, dispatch, message }) => {
  if (
    err?.response?.data?.status == 400 ||
    err?.response?.data?.status == 500
  ) {
    handleShowErrorAndWarning({
      dispatch,
      type: "error",
      message: "Internal Server Error!",
      showTime: "10000",
    });
  }
  if (err?.response?.data?.status != 401) {
    handleShowErrorAndWarning({
      dispatch,
      type: "error",
      message: message || "Failed to Fetch Details!",
      showTime: "10000",
    });
  }
};


function scrollToErrorSection(sectionId) {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

/**
 *
 * @param {object} props
 * @param {object} props.primaryObject - 'primaryObject' will the controller of all the field component, that will be help to activate error and throw message.
 * @returns {boolean} - If all the required fields had been filled then it will return 'true' othervise 'false'
 */
export const globalHandleIsAllRequiredFieldAvailable = ({ primaryObject }) => {
  // Check if the function is called for creating a new work order (not editing).
  let isValidated = false;
  for (let key in primaryObject) {
    let element = primaryObject[key];
    console.log('element:', element)
    if (element?.value == false) {
      element?.errorActivatorMethod && element?.errorActivatorMethod(true);
      scrollToErrorSection(key);
      isValidated = true;
    }
  }

  return !isValidated;
};

/**
 * 'globalHandleLoadMoreData' function that fetches data from the server based on pagination and ensures that subsequent calls are made only after the previous call has been completed,.
 * @param {object} props - this object will contain all the possible states
 * @returns {response} - server response
 */
export const globalHandleLoadMoreData = async (props) => {
  const { url, loadingFlag, setLoadingFlag, method, optionalParams } = props;

  // If already loadingFlag, do nothing
  if (loadingFlag) return;

  try {
    const data = await doGetApiCall(url, optionalParams);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoadingFlag(false);
  }
};

export const globalhandlerOutsideRequest = () => {
  const URL = handleSegregateURL();
  if (
    localStorage.getItem("unity") ||
    localStorage.getItem("AR") ||
    localStorage.getItem("VR") ||
    URL?.unity ||
    URL?.AR ||
    URL?.VR
  ) {
    return true;
  } else {
    return false;
  }
};

export const handleMoreData = (props) => {
  const { state, stateMethod, limit, loadingFlag, setLoadingFlag, url } = props;
  if (state?.currentPage == state?.totalPageCount) return;
  const optionalParams = { page: state?.currentPage + 1, limit: limit || 15 };
  state?.currentPage != state?.totalPageCount && globalHandleLoadMoreData({ url, loadingFlag, setLoadingFlag, method: "get", optionalParams }).then((res) => {
    stateMethod((prev) => {
      let currentResponse = res?.data?.result;
      let updateState = { ...(currentResponse || {}), ["data"]: [...(prev?.data || []), ...(currentResponse?.data || [])] }
      let newSet = new Set();
      updateState.data = (updateState?.data || []).filter(element => {
        if (!newSet.has(element?.id)) {
          newSet.add(element?.id);
          return element
        }
      });
      return updateState
    })
  })
};

export const globalHandlerDataURLtoFile = () => { }



/**
 * 
 * @param {*} module - An object that contain the permissions that are coming from the backend. Exp: {create:true,update:true,delete:true}
 * @param {*} action - it's a string Exp: create,update,delete
 * @returns 
 */
const hasCRUDPermission = (module, action) => {
  return !!module[action];
};


/**
 * This function manipulates the backend permissions and provides frontend control over the permission names and their status for scalability.
 * @param {Object} permissions - An object containing the available permissions for the logged-in user.
 * @returns {Object} An object that contains the updated keys based on frontend requirements.
 */
function handleTakeControlOnPermission(permissions) {

  let localPermissions = {}; // Initialize localPermissions as an empty object
  let defaultPermissions = [CLONOS_GLOBAL_ACTION_CREATE, CLONOS_GLOBAL_ACTION_READ, CLONOS_GLOBAL_ACTION_UPDATE, CLONOS_GLOBAL_ACTION_DELETE]; // Default permissions contain basic CRUD
  const modulePermissionsMapping = {
    [CLONOS_GLOBAL_MODULE_ASSETS]: [...defaultPermissions, CLONOS_GLOBAL_ACTION_DECOMMISSION, CLONOS_GLOBAL_ACTION_RESTORE],
    [CLONOS_GLOBAL_MODULE_WORK_ORDERS]: [...defaultPermissions, CLONOS_GLOBAL_ACTION_DUE_DATE_EXTENSION],
    [CLONOS_GLOBAL_MODULE_USERS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_TEAMS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_DOCUMENTS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_PLANT_3D]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_MAINTENANCE_PLANS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_TASK_LIBRARY]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_DASHBOARD]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_CHECKLISTS]: [...defaultPermissions, CLONOS_GLOBAL_ACTION_FILL_ENTRY, CLONOS_GLOBAL_ACTION_FILL_UPDATE_ENTRY],
    [CLONOS_GLOBAL_MODULE_LOGS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_REPORTS]: [...defaultPermissions],
  };

  // Define a mapping of frontend permissions names for each module and 
  // the order of the actions (create,read,update,delete....) should be same, 
  // but you can change the name of action based on your requiment. 
  // Exp: moduleFrontendPermissionsMapping[assets]:["create", "read", "update"]=== moduleFrontendPermissionsMapping[assets]:["creation", "view", "updations"].
  const moduleFrontendPermissionsMapping = {
    [CLONOS_GLOBAL_MODULE_ASSETS]: [...defaultPermissions, CLONOS_GLOBAL_ACTION_DECOMMISSION, CLONOS_GLOBAL_ACTION_RESTORE],
    [CLONOS_GLOBAL_MODULE_WORK_ORDERS]: [...defaultPermissions, CLONOS_GLOBAL_ACTION_DUE_DATE_EXTENSION],
    [CLONOS_GLOBAL_MODULE_USERS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_TEAMS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_DOCUMENTS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_PLANT_3D]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_MAINTENANCE_PLANS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_TASK_LIBRARY]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_DASHBOARD]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_CHECKLISTS]: [...defaultPermissions, CLONOS_GLOBAL_ACTION_FILL_ENTRY, CLONOS_GLOBAL_ACTION_FILL_UPDATE_ENTRY],
    [CLONOS_GLOBAL_MODULE_LOGS]: [...defaultPermissions],
    [CLONOS_GLOBAL_MODULE_REPORTS]: [...defaultPermissions],
  };

  for (let key in permissions) {
    localPermissions[key] = {}; // Initialize localPermissions for each module
    const element = permissions[key]; // Fix typo in variable name
    switch (key) {
      case CLONOS_GLOBAL_MODULE_ASSETS: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key]; // Replacing the local permissions with backend permissions.
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      case CLONOS_GLOBAL_MODULE_WORK_ORDERS: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      case CLONOS_GLOBAL_MODULE_USERS: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;


      case CLONOS_GLOBAL_MODULE_TEAMS: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;


      case CLONOS_GLOBAL_MODULE_DOCUMENTS: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      case CLONOS_GLOBAL_MODULE_PLANT_3D: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      case CLONOS_GLOBAL_MODULE_MAINTENANCE_PLANS: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      case CLONOS_GLOBAL_MODULE_TASK_LIBRARY: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      case CLONOS_GLOBAL_MODULE_DASHBOARD: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      case CLONOS_GLOBAL_MODULE_LOGS: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      case CLONOS_GLOBAL_MODULE_CHECKLISTS: {
        const frontendPermissionsNames = moduleFrontendPermissionsMapping[key];
        lcHandleGenerateLocalPermissions(key, frontendPermissionsNames, element);
      }
        break;

      default: {
        console.log(`Unsupported module: ${key}`);
      }
    }
  }

  function lcHandleGenerateLocalPermissions(moduleName, frontendPermissionsNames, element) {
    modulePermissionsMapping[moduleName]?.forEach((item, index) => {
      localPermissions[moduleName][frontendPermissionsNames[index]] = hasCRUDPermission(element, item);
    });
  }
  return localPermissions; // Return localPermissions
}


export function camelToPascal(camelCaseStr) {
  // Split the string into an array of words
  const words = camelCaseStr.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word
  const pascalCasedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words back together
  const pascalCaseStr = pascalCasedWords.join(' ');

  return pascalCaseStr;
}




/**
 * Function to get the current week of the month based on the current date.
 * @returns {string} The current week of the month ("first", "second", "third", "fourth", "fifth").
 */
export function getCurrentWeekOfMonth() {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Get the first day of the month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const firstDayOfWeek = firstDayOfMonth.getDay();

  // Calculate the week number based on the current day and the first day of the week
  const weekNumber = Math.ceil((currentDay + firstDayOfWeek) / 7);

  // Return the week label
  switch (weekNumber) {
    case 1:
      return "first";
    case 2:
      return "second";
    case 3:
      return "third";
    case 4:
      return "fourth";
    case 5:
      return "fifth";
    default:
      return "unknown";
  }
}

/**
 * Function to calculate the difference in years, months, and days between two dates.
 * @param {string} startDateString - The start date string (in ISO 8601 format).
 * @param {string} endDateString - The end date string (in ISO 8601 format).
 * @returns {Object} An object containing the difference in years, months, and days.
 */
export function calculateDateDifference(startDateString, endDateString) {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = endDate - startDate;

  // Convert milliseconds to years, months, and days
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25;
  const years = Math.floor(differenceInMilliseconds / millisecondsInYear);
  const remainingMilliseconds = differenceInMilliseconds % millisecondsInYear;
  const millisecondsInMonth = millisecondsInYear / 12;
  const months = Math.floor(remainingMilliseconds / millisecondsInMonth);
  const remainingDays = Math.floor((remainingMilliseconds % millisecondsInMonth) / (1000 * 60 * 60 * 24));

  return {
    years,
    months,
    days: remainingDays
  };
}



/**
 * Function to find the provided key is present or not in the list.
 * @param {string} list - An array that contain strings (["item","item"]).
 * @param {string} isPresent - It will be string that we need to find in the list.
 * @returns {Object} It will be one boolean value.
 */
export function hasItem(list, isPresent) {
  if (Array.isArray(list) && typeof isPresent === "string") {
    if ((list || []).indexOf(isPresent) > -1) {
      return true
    } else {
      return false
    }
  } else {
    console.error(new Error("Please provie the valid format 'list : Array ([])','isPresent : String ('')'"))
  }
}


/**
 * Function to modify a string based on the specified format type.
 * @param {string} inputString - The input string to be modified.
 * @param {string} formatType - The type of modification. Supported values are:
 *  - 'uppercase': Converts the entire string to uppercase.
 *  - 'lowercase': Converts the entire string to lowercase.
 *  - 'capitalize': Capitalizes the first letter of the string and lowercases the rest.
 *  - 'camelcase': Converts the string to camelCase, where the first word is lowercase, and each subsequent word starts with an uppercase letter.
 *  - 'snakecase': Converts the string to snake_case, replacing spaces with underscores and converting to lowercase.
 *  - 'kebabcase': Converts the string to kebab-case, replacing spaces with hyphens and converting to lowercase.
 *  - 'spacecase': Inserts a space between lowercase and uppercase letters in the string (e.g., "helloWorld" becomes "hello World").
 * @returns {string} The modified string, or "Unsupported format type" if the format type is not recognized.
 */
export function modifyString(inputString, formatType) {
  switch (formatType) {
    case 'uppercase':
      return inputString.toUpperCase();
    case 'lowercase':
      return inputString.toLowerCase();
    case 'capitalize':
      return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
    case 'camelcase':
      return inputString.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    case 'snakecase':
      return inputString.replace(/\s+/g, '_').toLowerCase();
    case 'kebabcase':
      return inputString.replace(/\s+/g, '-').toLowerCase();
    case 'spacecase':
      return inputString.replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\b\w/g, char => char.toUpperCase());
    case 'pascalcase':
      return inputString
        .replace(/\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .replace(/\s+/g, '');    
    default:
      return "Unsupported format type";
  }
}

// Example usage:
const result = modifyString("helloWorld", "spacecase");
console.log(result); // Output: "Hello World"



export const generateSkeletonColumns = (fields) => {
  const skeletonColumns = fields.map(field => ({
    ...field,
    field: field?.headerName || "Not Mentioned",
    headerName: field?.headerName || "Not Mentioned",
    flex: 1,
    hideable: false,
    disableColumnFilter: false,
    width: field?.width || "auto",
    renderCell: () => {
      return <ClonosSkeletonLoading width="100%" height="100%" animation="wave" />
    }
  }));

  return skeletonColumns;
};


export const getCurrentDateInFormat = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because January is 0
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}


export const convertISTToCustomFormat = (istDateString) => {
  // Parse the IST date string
  const istDate = new Date(istDateString);

  // Format the date to ISO 8601 without seconds and milliseconds
  const year = istDate.getFullYear();
  const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  const day = String(istDate.getDate()).padStart(2, '0');
  const hours = String(istDate.getHours()).padStart(2, '0');
  const minutes = String(istDate.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}



// Remove the duplicates from an array

export const handleRemoveDuplicatesFromAnArray = ({ data }) => {
  console.log('dataaaaa:', data)
  const recordMap = new Map();
  if (data?.length < 2) return;
  const id = data?.[0]?.id || data?.[0]?._id;
  return data?.filter((item) => {
    if (!recordMap.has(item[id])) {
      recordMap.set(id);
      return item
    }
  })
};


export const globalHandleUploadFiles = async ({ payload, isMultipleUpload = false }) => {
  console.log('payload:', payload)
  const formData = new FormData();
  formData.append(isMultipleUpload ? "files" : "file", payload?.file);
  return await doPostApiCall(
    {
      url: AppUrl.uploadFiles,
      contentType: "multipart/form-data",
      payload: formData
    });
};


export const isPastDateTime = (dateTimeString) => {
  // Create a Date object from the input string
  const inputDateTime = new Date(dateTimeString);

  // Get the current date and time
  const currentDateTime = new Date();

  // Compare the input date and time with the current date and time
  return inputDateTime < currentDateTime;
}


export const convertISTtoUTC = (timeStr) => {
  console.log('timeStr:', timeStr)
  // IST offset
  const istOffsetMinutes = 330; // IST is UTC+5:30, which is 330 minutes

  // Create a Date object from the time string in local time
  const localDate = new Date(timeStr + 'Z'); // Append 'Z' to indicate UTC, which will be adjusted later

  // Manually adjust for IST offset
  const utcDate = new Date(localDate.getTime() - (istOffsetMinutes * 60 * 1000));

  return utcDate.toISOString(); // Convert to UTC string
}

// const convertUTCToIST = (utcDateString) => {
//   const utcDate = new Date(utcDateString);

//   // IST is UTC+5:30, so add 5 hours and 30 minutes to the UTC time
//   const istOffset = 5 * 60 + 30; // 5 hours 30 minutes in minutes
//   const utcOffset = utcDate.getTimezoneOffset(); // Get the local time zone offset

//   // Create a new Date object for IST
//   const istDate = new Date(utcDate.getTime() + (istOffset - utcOffset) * 60000);

//   return istDate.toISOString();
// }

const convertUTCToIST = (utcString) => {
  // Create a Date object from the UTC string
  const utcDate = new Date(utcString);

  // Convert to IST by adding 5 hours and 30 minutes
  const istOffset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000; // 5 hours 30 minutes in milliseconds
  const istDate = new Date(utcDate.getTime() + istOffset);

  // Format the date to ISO 8601 without time zone information
  return istDate.toISOString().slice(0, 19)
}

/**
 * Adds hours and minutes to a given ISO time string.
 * @param {string} timeString - The original time string in ISO format (YYYY-MM-DDTHH:MM).
 * @param {number} hoursToAdd - The number of hours to add.
 * @param {number} minutesToAdd - The number of minutes to add.
 * @returns {string} - The new time string after adding the specified hours and minutes.
 */
export const addTime = (timeString, hoursToAdd, minutesToAdd) => {
  let date = new Date(timeString);
  date.setHours(date.getHours() + hoursToAdd, date.getMinutes() + minutesToAdd);
  return date.toISOString().slice(0, 16); // Returns the time in "YYYY-MM-DDTHH:MM" format
}



/**
 * Converts an ISO time string to an object with detailed time components.
 * @param {string} isoString - The ISO time string (e.g., "2024-08-19T23:45:00.000Z").
 * @returns {object} - An object containing year, month, day, hour, minute, second, and time.
 */
export const parseISOTimeString = (isoString) => {
  console.log('isoString:', isoString)
  if (!isoString) return false
  const date = new Date(isoString);
  console.log('daterrrrr:', date)


  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  console.log('monthtttt:', month)
  const day = String(date.getDate()).padStart(2, '0');
  console.log('day:', day)
  const hour = String(date.getHours()).padStart(2, '0');
  const minute = String(date.getMinutes()).padStart(2, '0');
  const second = String(date.getSeconds()).padStart(2, '0');
  const time = `${hour}:${minute}:${second}`;
  const clientView = `${day}/${month}/${year}`
  const monthString = monthStringStaticData[date.getMonth()];

  return {
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: second,
    time: time,
    clientView,
    monthString
  };
}


const formatDateWithCurrentTime = (dateString) => {
  // Define regex patterns for date and datetime
  const dateOnlyPattern = /^\d{4}-\d{2}-\d{2}$/;
  const dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(?:\.\d{1,3})?)?(?:Z|[+-]\d{2}:\d{2})?$/;

  // Get the current time
  const now = new Date();
  const currentHours = String(now.getHours()).padStart(2, '0');
  const currentMinutes = String(now.getMinutes()).padStart(2, '0');

  // Check if the input is a valid ISO 8601 datetime or date only
  if (dateTimePattern.test(dateString)) {
    // If it's a datetime, return it as-is
    return dateString;
  } else if (dateOnlyPattern.test(dateString)) {
    // If it's a date only, append the current time
    const dateTimeString = `${dateString}T${currentHours}:${currentMinutes}`; // Combine date with current time
    return dateTimeString; // Return the formatted date-time string
  } else {
    throw new Error('Invalid date string');
  }
}


export const dateModifierAndParser = (props = {}) => {
  const { dateString, dateStringType = CLONOS_GLOBAL_TIME_ZONE_ISO, returnType, parseType = "string" } = props;
  console.log('dateString:', dateString)

  let lcDataString = dateString;

  if (dateString && dateStringType === CLONOS_GLOBAL_TIME_ZONE_ISO) {
    lcDataString = formatDateWithCurrentTime(dateString)
  };

  if (dateStringType !== CLONOS_GLOBAL_TIME_ZONE_ISO && dateStringType !== returnType) {
    switch (returnType) {
      case CLONOS_GLOBAL_TIME_ZONE_UTC: {
        lcDataString = convertISTtoUTC(lcDataString);
      }
        break;
      case CLONOS_GLOBAL_TIME_ZONE_IST: {
        lcDataString = convertUTCToIST(lcDataString);
      }
        break;
    }
  }


  if (parseType === "object") {
    lcDataString = parseISOTimeString(lcDataString)
  };
  console.log('lcDataString:', lcDataString)
  return lcDataString;
}



/**
 * Returns the appropriate route for a given node by checking its child nodes for accessibility.
 * 
 * The function checks if any children of the specified `nodeName` are accessible based on the `CLONOS_GLOBAL_ACTION_READ` action.
 * It returns the route of the first accessible child node, or the route of the `nodeName` itself if no accessible children are found.
 * 
 * @param {object} props - An object containing the necessary parameters.
 * @param {string} props.nodeName - The name of the node for which the route is being determined.
 * 
 * @returns {string} - The route for the first accessible child node, or the `nodeName` route if no accessible children are found.
 *                     Returns an empty string if `nodeName` is not provided.
 */
export const handleSidebarModuleListPriorityList = (props) => {
  const { nodeName } = props;
  if (!nodeName) return;
  const nodeChildren = sidebarModuleListPriorityListStaticData[nodeName];
  console.log('nodeChildren:', nodeChildren)
  for (let i = 0; i < nodeChildren?.length; i++) {
    const element = nodeChildren[i];
    if (checkUserHasPermission({ moduleName: element?.moduleName, action: CLONOS_GLOBAL_ACTION_READ })) {
      return element?.route;
    }
  }
  return `/${nodeName}`;
}


/**
 * Compares the source data with the new data to find unique entries based on a specified unique key.
 * 
 * @param {Object} params - The function parameters.
 * @param {Array} params.sourceData - The array of source data to compare against.
 * @param {Array} params.newData - The array of new data to compare with the source.
 * @param {String} params.uniquekey - The unique key in the objects to determine uniqueness.
 * @returns {Array} - An array of unique entries from the new data that do not exist in the source data.
 * 
 * @example
 * const source = [{ id: 1, name: 'A' }, { id: 2, name: 'B' }];
 * const newData = [{ id: 2, name: 'B' }, { id: 3, name: 'C' }];
 * const unique = getUniqueEntitiesByKey({ sourceData: source, newData: newData, uniquekey: 'id' });
 * console.log(unique); // Output: [{ id: 3, name: 'C' }]
 */

export const getMergedAndUniqueDataByKey = ({ sourceData = [], newData = [], uniquekey = "" }) => {
  const uniqueEntitiesObject = sourceData.reduce((acc, entity) => {
    if (!acc[uniquekey]) {
      acc[uniquekey] = entity;
    }
    return acc;
  }, {});

  const newEntities = newData?.filter(entity => !uniqueEntitiesObject?.[entity]?.[uniquekey]);
  return [...sourceData, ...newEntities]
}


/**
 * Converts a given date string from the server's time zone to the client's configured time zone.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.dateString - The date string to be converted, provided in the server's time zone.
 * @param {string} [params.parseType="string"] - The format type for the output; could be either "string" for a date string or "object" for a JavaScript Date object.
 * @returns {string|Date} The converted date in the client's configured time zone. If `parseType` is "string", returns a formatted date string; if "object", returns a Date object.
 * 
 * @description This function takes a date string provided in the server's time zone and converts it to the client's configured time zone. It uses `dateModifierAndParser` with the appropriate conversion parameters. 
 * The `parseType` parameter determines the format of the returned value. If the `dateString` is missing or empty, the function returns undefined.
 */
export const handleClientDateInConfiguredTimeZone = ({ dateString, parseType }) => {
  if (!dateString || dateString === "") return;
  const props = {
    dateString,
    dateStringType: CLONOS_GLOBAL_SERVER_TIME_ZONE,
    returnType: CLONOS_GLOBAL_CLIENT_TIME_ZONE,
    parseType: parseType ? parseType : "string"
  };

  return dateModifierAndParser(props);
};



/**
 * Converts a given date string from IST (Indian Standard Time) to UTC (Coordinated Universal Time) for server processing.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.dateString - The date string in IST to be converted to UTC.
 * @returns {string} The converted date string in UTC format, adjusted for server processing.
 * 
 * @description This function formats a given date string by ensuring it includes the current time, then converts it from IST to UTC for server-side handling.
 * If the `dateString` is empty, the function returns undefined. It uses `formatDateWithCurrentTime` to add the current time to the date string and `convertISTtoUTC` 
 * to perform the conversion from IST to UTC.
 */
export const handleServerDateInConfiguredTimeZone = ({ dateString }) => {
  if (dateString === "") return
  const lcDateString = formatDateWithCurrentTime(dateString)
  return convertISTtoUTC(lcDateString);
};




export const determineDateFormat = (dateString) => {
  // Regular expression to check for full ISO 8601 date-time format with UTC offset
  const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d{1,3})?)?(Z|([+-]\d{2}:\d{2}))?$/;

  // Regular expression to check for basic ISO 8601 date format (YYYY-MM-DD)
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

  // Check if it matches the full ISO 8601 date-time format with a possible UTC offset
  if (isoDateTimeRegex.test(dateString)) {
    return dateString.endsWith("Z") ? "UTC" : "ISO";
  }

  // Check if it matches the basic ISO 8601 date format (without time)
  if (isoDateRegex.test(dateString)) {
    return "ISO";
  }

  // Return unknown if neither format is detected
  return "Unknown";
}



export const timeDifference = (isoString1, isoString2) => {
  const date1 = new Date(isoString1);
  const date2 = new Date(isoString2);
  const differenceMilliseconds = date2 - date1;
  const differenceHours = differenceMilliseconds / (1000 * 60 * 60);
  return differenceHours;
}