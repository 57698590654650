import { memo, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TEMPLATE_CREATION_GRIP_VERTICAL from "../../../../../assets/UIUX/icons/Checklist/grip-vertical.svg";
import SingleFieldValueComponent from "../AttributeElements/SingleFieldAttribute/SingleFieldValueComponent";
import { MultipleFieldValueComponent } from "../AttributeElements/MultiFieldsAttribute/MultipleFieldValueComponent";
import { useSelector } from "react-redux";
import { handleReturnedFieldValue } from "../../../../../utils/ChecklistAndReportsMethods/ChecklistMethods";
import { useDispatch } from "react-redux";
import { ChecklistAndReportsAction } from "../../../../../Store/Reducers/ClonosChecklistAndReportsReducer"
import { TemplateRowActions } from "./TemplateRowActions";
import Styles from "./TemplateAttributesTable.module.css"
import { doGetApiCall } from "../../../../../utils/ClonosApi";
import AppUrl from "../../../../../utils/AppUrl";
import useMultiPagination from "../../../../../CustomHooks/MultiPaginationHook";
import { commonPageState, handleRemoveDuplicatesFromAnArray } from "../../../../../utils/clonosCommon";
import { commonActions } from "../../../../../Store/Reducers/CommonReducer";
import { CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE } from "../../../../../Constants/Constant";



const TemplateAttributesTable = ({
    attributes,
    uniqueKey,
    handleGetValues,
    uploadedAttributesFiles,
    handleGetErrorActivatorInformation,
    handlePrimaryObjectModifier,
    handleDeleteKeyFromPrimaryObject,
    handleIsAnyFieldUpdate,
    handleRemoveAttribute,
    isEdit
}) => {
    const columns = [
        { name: "SI.NO", width: "5%" },
        { name: "Asset", width: "20%" },
        { name: "TASK NAME", width: "20%" },
        { name: "DEFAULT VALUE", width: "20%" },
        { name: "TYPE", width: "10%" },
        { name: "ACTION", width: "5%" },
    ];

    const { checklistTemplateAttributes, useTemplateInformation } = useSelector(
        (store) => store?.checklistTemplateData
    );
    const dispatch = useDispatch();
    const [serverResponse, setServerResponse] = useState({ assets: {} });
    const { assets } = serverResponse;
    console.log('assets:', assets)
    const { handleSetPage, handleSetLimit, paginations } = useMultiPagination({
        asset: commonPageState,
    });
    const [assetSearchString, setAssetSearchString] = useState("");
    const [assetSearchData, setAssetSearchData] = useState([]);

    const handleDragEnd = (result) => {

        const { source, destination } = result;

        // Check if the item was dropped outside any droppable area
        if (!destination) return;

        // Reorder the items in the state
        const reorderedAttributes = Array.from(checklistTemplateAttributes);
        const [removed] = reorderedAttributes.splice(source.index, 1);
        reorderedAttributes.splice(destination.index, 0, removed);

        // Update the state using your Redux action
        dispatch(
            ChecklistAndReportsAction.setSelectedAttributesToolkitState(
                reorderedAttributes
            )
        );
        handleGetValues && handleGetValues({ uniqueKey, componentName: "templateAttributesTable", updatedValue: reorderedAttributes })
    };

    const handleAttributes = ({ id, dispatch, attributes, value }) => {
        handleIsAnyFieldUpdate && handleIsAnyFieldUpdate()
        if (Object.keys(value)?.length) {
            handleReturnedFieldValue({ id, ...value, dispatch, attributes })
        }
    }

    const lcHandleGetValues = (props) => {
        handleGetValues && handleGetValues({ uniqueKey: props?.uniqueKey, componentName: props?.componentName, updatedValue: props.updatedValue })
    }


    const handleGetAssets = (props = {}) => {
        const { limit = 15, name, page = 1 } = props;
        return doGetApiCall({ url: AppUrl.getAssets, params: { limit, name, page } }).then((res) => {
            console.log('res:', res)
            return { ...res?.data?.result, data: (res?.data?.result?.data || []).map(item => ({ id: item?.id, name: item?.generalDetails?.name })) };
        });
    };


    const handleDynamicSearch = (props) => {
        setAssetSearchString(props?.input)
    };




    const handleLoadMoreData = ({ uniqueKey }) => {
        console.log('uniqueKey:', uniqueKey)
        let pagination = paginations[uniqueKey];
        console.log('pagination:', pagination)
        switch (uniqueKey) {
            case "asset": {
                if (assets.totalPageCount === pagination?.page) return;
                let updatedPage = null;
                if (pagination?.limit * pagination?.page === assets.data?.length) {
                    updatedPage = pagination?.page + 1
                } else {
                    updatedPage = pagination?.page
                }
                console.log('updatedPage:', updatedPage)
                handleGetAssets({ page: updatedPage }).then(assetDataResponse => {
                    console.log('assetDataResponse:', assetDataResponse)
                    const updatedAssets = handleRemoveDuplicatesFromAnArray({ data: [...assetDataResponse?.data, ...assets.data] })
                    console.log('updatedAssets:', updatedAssets)
                    setServerResponse(prev => (
                        {
                            ...prev,
                            ["assets"]: { ...assetDataResponse, data: updatedAssets }
                        }));
                });
                handleSetPage({ page: updatedPage, key: uniqueKey });
            }
        }
    };


    useEffect(() => {
        if (assetSearchString === "") {
            handleGetAssets().then((assetData) => {
                setServerResponse({ ...serverResponse, ["assets"]: assetData })
            });
        }
        else {
            handleGetAssets({ name: assetSearchString }).then((assetData) => {
                setAssetSearchData(assetData?.data);
            });
            setAssetSearchData([]);
        };
    }, [assetSearchString]);

    useEffect(() => {
        dispatch(commonActions.showApiLoader(true))
        const timer = setTimeout(() => {
            dispatch(commonActions.showApiLoader(false))
        }, attributes?.length > 100 ? 1000 : attributes?.length > 50 ? 500 : 200);

        return () => clearTimeout(timer);
    }, [attributes?.length]);

    return (
        <div
            area-aria-label="template main container"
            className={Styles.template_table_container}
        >
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className={Styles.custom_table_container}>
                    <div className={Styles.custom_table_head}>
                        {columns?.map((col) => {
                            return (
                                <div style={{ width: col?.width }} className={Styles.column_container}><p key={col?.name}>
                                    {col?.name}
                                </p>
                                </div>
                            );
                        })}
                    </div>
                    <Droppable droppableId="droppable-1">
                        {(provider) => (
                            <div
                                ref={provider.innerRef}
                                {...provider.droppableProps}
                                className={Styles.custom_table_body}
                            >
                                {checklistTemplateAttributes?.length > 0 &&
                                    attributes?.map((attribute, index) => {
                                        return (
                                            <Draggable
                                                key={attribute.id}
                                                draggableId={attribute.id}
                                                index={index}
                                            >
                                                {(provider) => (
                                                    <div
                                                        {...provider.draggableProps}
                                                        ref={provider.innerRef}
                                                        className={Styles.custom_table_row}
                                                    >
                                                        <div className={Styles.template_table_col_index}>
                                                            <div
                                                                {...provider.dragHandleProps}
                                                                className={Styles.template_table_col_handle}
                                                            >
                                                                <img src={TEMPLATE_CREATION_GRIP_VERTICAL} />
                                                            </div>
                                                            <p>{index + 1}</p>
                                                        </div>
                                                        {attribute?.type === "text" && (
                                                            <SingleFieldValueComponent
                                                                attribute={attribute}
                                                                getFieldDetails={(value) => {
                                                                    console.log('valueee:', value)
                                                                    handlePrimaryObjectModifier({ response: value?.fieldName, uniqueKey: value?.uniqueKey })
                                                                    handleAttributes({
                                                                        id: attribute?.id,
                                                                        value,
                                                                        dispatch,
                                                                        attributes: checklistTemplateAttributes,
                                                                    })
                                                                    setAssetSearchString("");
                                                                    handleSetPage({ page: 1, key: "asset" });
                                                                }
                                                                }
                                                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                                                assets={assetSearchString?.length ? assetSearchData : serverResponse?.assets?.data}
                                                                handleMoreData={assets.totalPageCount === paginations["asset"]?.page ? null : handleLoadMoreData}
                                                                isEdit={isEdit}
                                                                handleDynamicSearch={handleDynamicSearch}

                                                            />
                                                        )}
                                                        {attribute?.type === "number" && (
                                                            <SingleFieldValueComponent
                                                                attribute={attribute}
                                                                getFieldDetails={(value) => {
                                                                    console.log('valueee:', value)
                                                                    handlePrimaryObjectModifier({ response: value?.fieldName, uniqueKey: value?.uniqueKey })
                                                                    handleAttributes({
                                                                        id: attribute?.id,
                                                                        value,
                                                                        dispatch,
                                                                        attributes: checklistTemplateAttributes,
                                                                    });
                                                                    setAssetSearchString("");
                                                                    handleSetPage({ page: 1, key: "asset" });

                                                                }
                                                                }
                                                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                                                assets={assetSearchString?.length ? assetSearchData : serverResponse?.assets?.data}
                                                                handleMoreData={assets.totalPageCount === paginations["asset"]?.page ? null : handleLoadMoreData}
                                                                isEdit={isEdit}
                                                                handleDynamicSearch={handleDynamicSearch}
                                                            />
                                                        )}
                                                        {attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE && (
                                                            <MultipleFieldValueComponent
                                                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                                                handlePrimaryObjectModifier={handlePrimaryObjectModifier}
                                                                handleDeleteKeyFromPrimaryObject={handleDeleteKeyFromPrimaryObject}
                                                                attribute={attribute}
                                                                getFieldDetails={(value) => {
                                                                    console.log('valueee:', value)
                                                                    handleAttributes({
                                                                        id: attribute?.id,
                                                                        value,
                                                                        dispatch,
                                                                        attributes: checklistTemplateAttributes,
                                                                    });
                                                                    setAssetSearchString("");
                                                                    handleSetPage({ page: 1, key: "asset" });

                                                                }
                                                                }
                                                                handleMoreData={assets.totalPageCount === paginations["asset"]?.page ? null : handleLoadMoreData}
                                                                isEdit={isEdit}
                                                                assets={assetSearchString?.length ? assetSearchData : serverResponse?.assets?.data}
                                                                handleDynamicSearch={handleDynamicSearch}
                                                            />
                                                        )}
                                                        {attribute?.type === "dropdown" && (
                                                            <MultipleFieldValueComponent
                                                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                                                handlePrimaryObjectModifier={handlePrimaryObjectModifier}
                                                                handleDeleteKeyFromPrimaryObject={handleDeleteKeyFromPrimaryObject}
                                                                attribute={attribute}
                                                                getFieldDetails={(value) => {
                                                                    // handlePrimaryObjectModifier({ response: value?.fieldName, uniqueKey: value?.uniqueKey })
                                                                    handleAttributes({
                                                                        id: attribute?.id,
                                                                        value,
                                                                        dispatch,
                                                                        attributes: checklistTemplateAttributes,
                                                                    });
                                                                    setAssetSearchString("");
                                                                    handleSetPage({ page: 1, key: "asset" });
                                                                }
                                                                }
                                                                assets={assetSearchString?.length ? assetSearchData : serverResponse?.assets?.data}
                                                                handleMoreData={assets.totalPageCount === paginations["asset"]?.page ? null : handleLoadMoreData}
                                                                isEdit={isEdit}
                                                                handleDynamicSearch={handleDynamicSearch}
                                                            />
                                                        )}
                                                        {attribute?.type === "date" && (
                                                            <SingleFieldValueComponent
                                                                attribute={attribute}
                                                                getFieldDetails={(value) => {
                                                                    console.log('valueee:', value)
                                                                    handlePrimaryObjectModifier({ response: value?.fieldName, uniqueKey: value?.uniqueKey })
                                                                    handleAttributes({
                                                                        id: attribute?.id,
                                                                        value,
                                                                        dispatch,
                                                                        attributes: checklistTemplateAttributes,
                                                                    });
                                                                    setAssetSearchString("");
                                                                    handleSetPage({ page: 1, key: "asset" });
                                                                }
                                                                }
                                                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                                                assets={assetSearchString?.length ? assetSearchData : serverResponse?.assets?.data}
                                                                isEdit={isEdit}
                                                                handleMoreData={assets.totalPageCount === paginations["asset"]?.page ? null : handleLoadMoreData}
                                                                handleDynamicSearch={handleDynamicSearch}
                                                            />
                                                        )}
                                                        {attribute?.type === "checkboxes" && (
                                                            <MultipleFieldValueComponent
                                                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                                                handlePrimaryObjectModifier={handlePrimaryObjectModifier}
                                                                handleDeleteKeyFromPrimaryObject={handleDeleteKeyFromPrimaryObject}
                                                                attribute={attribute}
                                                                getFieldDetails={(value) => {
                                                                    // handlePrimaryObjectModifier({ response: value?.fieldName, uniqueKey: value?.uniqueKey })
                                                                    handleAttributes({
                                                                        id: attribute?.id,
                                                                        value,
                                                                        dispatch,
                                                                        attributes: checklistTemplateAttributes,
                                                                    });
                                                                    setAssetSearchString("");
                                                                    handleSetPage({ page: 1, key: "asset" });
                                                                }
                                                                }
                                                                assets={assetSearchString?.length ? assetSearchData : serverResponse?.assets?.data}
                                                                isEdit={isEdit}
                                                                handleMoreData={assets.totalPageCount === paginations["asset"]?.page ? null : handleLoadMoreData}
                                                                handleDynamicSearch={handleDynamicSearch}
                                                            />
                                                        )}
                                                        <div
                                                            className={Styles.template_table_col_type_container}
                                                        >
                                                            <p className={Styles.template_table_col_type}>
                                                                {attribute?.attributeName}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={Styles.template_table_col_action_container}
                                                        >
                                                            <TemplateRowActions
                                                                isImage={false}
                                                                isNote={false}
                                                                attribute={attribute}
                                                                handleGetValues={lcHandleGetValues}
                                                                uniqueKey="templateRowActions"
                                                                attributes={checklistTemplateAttributes}
                                                                uploadedAttributesFiles={uploadedAttributesFiles}
                                                                handleDeleteKeyFromPrimaryObject={handleDeleteKeyFromPrimaryObject}
                                                                handleRemoveAttribute={handleRemoveAttribute}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                {provider.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        </div>
    );
};

export default memo(TemplateAttributesTable);
