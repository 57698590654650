import React, { useEffect, useState } from 'react'
import Styles from "./Scale.module.css"

/**
 * Scale component dynamically renders a segmented scale with color-coded sections
 * representing ranges between the provided minLimit, maxLimit, safeOperating limits, and bestBatch limits.
 * 
 * @param {Object} props - The props for the Scale component.
 * @param {number} props.minLimit - The minimum limit value for the scale.
 * @param {number} props.maxLimit - The maximum limit value for the scale.
 * @param {number} props.safeOperatingLow - The lower boundary of the safe operating range.
 * @param {number} props.safeOperatingHigh - The upper boundary of the safe operating range.
 * @param {number} props.bestBatchLow - The lower boundary of the best batch range.
 * @param {number} props.bestBatchHigh - The upper boundary of the best batch range.
 * 
 * The component computes the percentage width for each range and adjusts the styles accordingly.
 * It also adds markers at specific points on the scale to indicate boundaries.
 */
const Scale = ({
    minLimit,
    maxLimit,
    safeOperatingLow,
    safeOperatingHigh,
    bestBatchLow,
    bestBatchHigh
}) => {

    // State to hold the percentage values for each segment in the scale.
    const [scaleSegments, setScaleSegment] = useState({
        minToSafeLowPercent: 0,           // Percentage from minLimit to safeOperatingLow
        safeLowToBestLowPercent: 0,       // Percentage from safeOperatingLow to bestBatchLow
        bestLowToBestHighPercent: 0,      // Percentage from bestBatchLow to bestBatchHigh
        bestHighToSafeHighPercent: 0,     // Percentage from bestBatchHigh to safeOperatingHigh
        safeHighToMaxPercent: 0,          // Percentage from safeOperatingHigh to maxLimit
    });

    // Destructuring the segments from state for ease of use in JSX.
    const {
        minToSafeLowPercent,
        safeLowToBestLowPercent,
        bestLowToBestHighPercent,
        bestHighToSafeHighPercent,
        safeHighToMaxPercent
    } = scaleSegments;

    /**
     * Generates the scale points based on the provided min and max limits.
     * Dynamically calculates the points dividing the range into equal steps.
     */
    const scaleSteps = 6; // Number of divisions on the scale
    let scale = [];
    scale = Array.from({ length: scaleSteps + 1 }, (_, i) =>
        Math.round(minLimit + (i * (maxLimit - minLimit)) / scaleSteps)
    );

    useEffect(() => {
        /**
         * Computes the percentage width for each segment in the scale based on the provided limits.
         * If a value isn't provided, it falls back to the min or max limits.
         */
        const lcSafeOperatingLow = safeOperatingLow ? safeOperatingLow : minLimit;
        const lcBestBatchLow = bestBatchLow ? bestBatchLow : lcSafeOperatingLow;
        const lcSafeOperatingHigh = safeOperatingHigh ? safeOperatingHigh : maxLimit;
        const lcBestBatchHigh = bestBatchHigh ? bestBatchHigh : lcSafeOperatingHigh;

        setScaleSegment(prev => {
            return {
                minToSafeLowPercent: ((lcSafeOperatingLow - minLimit) / (maxLimit - minLimit)) * 100,
                safeLowToBestLowPercent: ((lcBestBatchLow - lcSafeOperatingLow) / (maxLimit - minLimit)) * 100,
                bestLowToBestHighPercent: ((lcBestBatchHigh - lcBestBatchLow) / (maxLimit - minLimit)) * 100,
                bestHighToSafeHighPercent: ((lcSafeOperatingHigh - lcBestBatchHigh) / (maxLimit - minLimit)) * 100,
                safeHighToMaxPercent: ((maxLimit - lcSafeOperatingHigh) / (maxLimit - minLimit)) * 100,
            }
        });
    }, [minLimit, maxLimit, safeOperatingLow, safeOperatingHigh, bestBatchLow, bestBatchHigh]);

    return (
        <div className={Styles.scale}>
            {/* Render each segment with corresponding color */}
            <div className={`${Styles.scale_segment} ${Styles.red}`} style={{ width: `${minToSafeLowPercent}%` }}></div>
            <div className={`${Styles.scale_segment} ${(safeOperatingLow) ? Styles.blue : Styles.red}`} style={{ width: `${safeLowToBestLowPercent}%` }}></div>
            <div className={`${Styles.scale_segment} ${(bestBatchLow || bestBatchHigh) ? Styles.green : (safeOperatingLow || safeOperatingHigh) ? Styles.blue : Styles.red}`} style={{ width: `${bestLowToBestHighPercent}%` }}></div>
            <div className={`${Styles.scale_segment} ${(safeOperatingHigh) ? Styles.blue : Styles.red}`} style={{ width: `${bestHighToSafeHighPercent}%` }}></div>
            <div className={`${Styles.scale_segment} ${Styles.red}`} style={{ width: `${safeHighToMaxPercent}%` }}></div>

            {/* Render scale points */}
            {
                scale.map((step, index) => (
                    <div
                        key={index}
                        className={Styles.scale_point}
                        style={{ left: `${(index / scaleSteps) * 100}%` }}
                    >
                        {step}
                    </div>
                ))
            }

            {/* Markers indicating various limit points */}
            <div
                className={`${Styles.marker} ${Styles.low}`}
                style={{
                    left: `${((safeOperatingLow - minLimit - 1) / (maxLimit - minLimit)) * 100}%`,
                }}
            />
            <div
                className={`${Styles.marker} ${Styles.high}`}
                style={{
                    left: `${((safeOperatingHigh - minLimit - 1) / (maxLimit - minLimit)) * 100}%`,
                }}
            />
            <div
                className={`${Styles.marker} ${Styles.low}`}
                style={{
                    left: `${((bestBatchLow - minLimit - 1) / (maxLimit - minLimit)) * 100}%`,
                }}
            />
            <div
                className={`${Styles.marker} ${Styles.high}`}
                style={{
                    left: `${((bestBatchHigh - minLimit - 1) / (maxLimit - minLimit)) * 100}%`,
                }}
            />
        </div>
    )
}

export default Scale;
