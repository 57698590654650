import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getToken, handleMakePromiseFullfilled, handleSegregateURL, hasPermission } from './clonosCommon';
import React, { useEffect, useState } from 'react';
import ClonosLayout from '../components/Layout/ClonosLayout';
import { UnAuthorizedModal } from '../components/CommonComponents/UnAuthorizedPage/UnAuthorizedModal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { parentRoutesStaticData } from '../Constants/StaticData';
import { globalEntitiesActions } from '../Store/Reducers/ClonosGlobalEntities';



const ClonosPrivateRoute = () => {
    // Global States 
    const { globalNavigationBarPathsToolkitState } = useSelector(
        (store) => store.globalEntitiesStateManagement
    );
    const windowLocation = window.location;
    console.log('windowLocation:', windowLocation)
    const LOCATION = useLocation()
    console.log('LOCATION:', LOCATION)

    // Local States 
    const [isUnity, setIsUnity] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const URL = handleSegregateURL();

    useEffect(() => {
        if (!getToken() && (URL.unity || URL.AR || URL.VR)) {
            setIsUnity(true);
            handleMakePromiseFullfilled({ dispatch });
        } else if (!getToken()) {
            navigate("/");
        };
    }, []);


    const handleActiveComponentNameGenerator = (pathName) => {
        return (pathName?.substring(1) || "").split("-")?.map(element => (element[0] || "").toUpperCase() + (element || "").substring(1))?.join(" ");
    }

    useEffect(() => {
        // Extract the pathname from the window location
        let pathName = windowLocation?.pathname?.substring(1);
        // Split the pathname into an array and replace "%20" with spaces
        pathName = (pathName || "").split("/")?.map(element => (element || "").split("%20").join(" "));
        // Determine the base route
        const route = `/${pathName[0]}`;
        // Flag to track if the route is already present in the global navigation bar paths
        let isAlreadyPresent = false;

        // Check if the current route is already present in the global navigation bar paths
        (globalNavigationBarPathsToolkitState || []).forEach((item, index) => {
            if (item?.route === windowLocation.pathname) {
                // If so, update the array up to the current index and dispatch the updated state
                const updatedArray = globalNavigationBarPathsToolkitState.slice(0, index + 1);
                dispatch(globalEntitiesActions.setGlobalNavigationBarPaths(updatedArray));
                // Set the flag to true
                isAlreadyPresent = true;
                return;
            }
        });

        // If the route is already present, return to prevent further execution
        if (isAlreadyPresent) {
            return;
        }

        // If there are multiple components in the path
        if (pathName?.length > 1) {
            const activeViewPage = pathName[1];
            // Check if the base route is static
            if (parentRoutesStaticData.has(route)) {
                // If so, set the global navigation bar paths with the base route and active component
                dispatch(globalEntitiesActions.setGlobalNavigationBarPaths([{ route: route, activeComponentName: activeViewPage, state: LOCATION?.state }]));
                return;
            } else if (pathName?.length == 2) {
                // If there are only two components in the path, set the global navigation bar paths with the current route and active component
                dispatch(globalEntitiesActions.setGlobalNavigationBarPaths([...globalNavigationBarPathsToolkitState, { route: windowLocation?.pathname, activeComponentName: activeViewPage, state: LOCATION?.state }]));
                return;
            } else {
                // Otherwise, set the global navigation bar paths with the base route and active component
                dispatch(globalEntitiesActions.setGlobalNavigationBarPaths([...globalNavigationBarPathsToolkitState, { route: route, activeComponentName: activeViewPage, state: LOCATION?.state }]));
                return;
            }
        } else {
            // If there's only one component in the path
            // Check if the base route is static
            if (parentRoutesStaticData.has(route)) {
                // If so, set the global navigation bar paths with the base route and a generated active component name
                dispatch(globalEntitiesActions.setGlobalNavigationBarPaths([{ route: route, activeComponentName: handleActiveComponentNameGenerator(route), state: LOCATION?.state }]));
                return;
            } else {
                // Otherwise, set the global navigation bar paths with the base route and a generated active component name
                dispatch(globalEntitiesActions.setGlobalNavigationBarPaths([...globalNavigationBarPathsToolkitState, { route: route, activeComponentName: handleActiveComponentNameGenerator(route), state: LOCATION?.state }]));
                return;
            }
        }

    }, [windowLocation.pathname]);


    const routingPart = () => hasPermission(windowLocation.pathname) ? <Outlet /> : <UnAuthorizedModal type={'NOT_AUTHORIZED'} />

    if (isUnity) {
        return routingPart();
    } else {
        return <ClonosLayout>{routingPart()}</ClonosLayout>;
    }
}

export default ClonosPrivateRoute;