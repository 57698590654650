import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { checkUserHasPermission, getToken, handleSegregateURL, handleSidebarModuleListPriorityList, hasPermission } from "../../utils/clonosCommon";
import { Outlet, useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo.png";
import Tooltip from "@material-ui/core/Tooltip";
import { Collapse } from "@material-ui/core";
import { Assets, ActiveAssets } from "../../assets/svg/Assets";
import AppBar from "./AppBar";
import { ActiveTask, Task } from "../../assets/svg/Task";
import { ActiveCheckList, CheckList } from "../../assets/svg/CheckList";
import { ActiveHome, Home } from "../../assets/svg/Home";
import { ActiveMaintenance, Maintenance } from "../../assets/svg/Maintenance";
import { ActiveDocument, Document } from "../../assets/svg/Document";
import { ActiveWorkorder, Workorder } from "../../assets/svg/Workorder";
import { ActiveLog, Log } from "../../assets/svg/Log";
import { ActiveMaintenancePlan, MaintenancePlan } from "../../assets/svg/MaintenancePlan";
import { ActiveRight, Right } from "../../assets/svg/RightIcon";
import { ClonosButton } from "../CommonComponents/Button/Button";
import { CLONOS_GLOBAL_APP_DEV_VERSION, CLONOS_GLOBAL_APP_ENVIRONMENT, CLONOS_GLOBAL_APP_UI_VERSION, CLONOS_GLOBAL_APP_VERSION, CLONOS_GLOBAL_MODULE_ASSETS, CLONOS_GLOBAL_MODULE_CHECKLISTS, CLONOS_GLOBAL_MODULE_DOCUMENTS, CLONOS_GLOBAL_MODULE_LOGS, CLONOS_GLOBAL_MODULE_MAINTENANCE_PLANS, CLONOS_GLOBAL_MODULE_PLANT_3D, CLONOS_GLOBAL_MODULE_REPORTS, CLONOS_GLOBAL_MODULE_SPARES, CLONOS_GLOBAL_MODULE_TASK_LIBRARY, CLONOS_GLOBAL_MODULE_USERS, CLONOS_GLOBAL_MODULE_WORK_ORDERS } from "../../Constants/Constant";
import { AllUsersCountSVG, PermissionsActiveTabSVG, PermissionsInActiveTabSVG, UsersActiveTabSVG, UsersInActiveTabSVG, UsersListActiveTabSVG, UsersListInActiveTabSVG } from "../../assets/UsersSVGComponents/AllUsersSVG";
import { SparesAndInventory } from "../../assets/svg/SparesAndInventory";
import { ReportsActiveIcon, ReportsInActiveIcon } from "../../assets/Reports/SVGComponents";

const drawerWidth = 240;
let previousSelected = null;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		height: "100%"
	},
	toolbarTwo: {
		display: "flex",
		justifyContent: "space-between",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(5) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(7.5) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-around",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		height: "64px"
	},
	contentWrapper: {
		flexGrow: 1,
		padding: 0,
		backgroundColor: "#ebe8e8",
		display: "flex",
		flexDirection: "column"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		flex: 1
	},
	removePadding: {
		padding: 0
	},
	version_contianer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "0.5rem",
		fontFamily: "roboto",
		fontWeight: "500",
		cursor: "default"
	},
	sidebar_bottom_container: {
		width: "100%"
	},
	logo: {
		width: "25px",
		height: "25px",
		cursor: "pointer"
	},
	appName: {
		cursor: "pointer",
	}
}));

const drawerMenu = [
	{
		name: "Home",
		icon: <Home />,
		activeIcon: <ActiveHome />,
		route: "/dashboard",
		moduleName: "home"
	},
	{
		name: "Assets",
		icon: <Assets />,
		activeIcon: <ActiveAssets />,
		route: handleSidebarModuleListPriorityList({ nodeName: CLONOS_GLOBAL_MODULE_ASSETS }),
		moduleName: CLONOS_GLOBAL_MODULE_ASSETS,
		children: [
		// 	{
		// 		name: "Documents",
		// 		icon: <Document />,
		// 		activeIcon: <ActiveDocument />,
		// 		route: "/documents",
		// 		moduleName: CLONOS_GLOBAL_MODULE_DOCUMENTS,
		// 		subRoute: ["/create-document", "/edit-document"]
		// 	},
			// {
			// 	name: "Spares & Inventory",
			// 	icon: <SparesAndInventory color={"#575757"} />,
			// 	activeIcon: <SparesAndInventory color={"#06337E"} />,
			// 	route: "/spares",
			// 	moduleName: CLONOS_GLOBAL_MODULE_SPARES,
			// 	subRoute: [""]
			// }
		]
	},
	{
		name: "Users",
		icon: <UsersInActiveTabSVG />,
		activeIcon: <UsersActiveTabSVG />,
		route: handleSidebarModuleListPriorityList({ nodeName: CLONOS_GLOBAL_MODULE_USERS }),
		moduleName: CLONOS_GLOBAL_MODULE_USERS,
		children: [
			{
				name: "Users List",
				route: "/users",
				icon: <UsersListInActiveTabSVG />,
				activeIcon: <UsersListActiveTabSVG />,
				moduleName: CLONOS_GLOBAL_MODULE_USERS,
			},
			{
				name: "Permissions",
				icon: <PermissionsInActiveTabSVG />,
				activeIcon: <PermissionsActiveTabSVG />,
				route: "/permissions",
				moduleName: CLONOS_GLOBAL_MODULE_USERS,
			},
		]
	},
	{
		name: "Maintenance",
		icon: <Maintenance />,
		activeIcon: <ActiveMaintenance />,
		route: handleSidebarModuleListPriorityList({ nodeName: "maintenance" }),
		moduleName: "maintenance",
		children: [
			// {
			// 	name: "Work Order",
			// 	icon: <Workorder />,
			// 	activeIcon: <ActiveWorkorder />,
			// 	route: "/work-orders",
			// 	moduleName: CLONOS_GLOBAL_MODULE_WORK_ORDERS,
			// 	subRoute: ["/create-work-order", "/view-work-order", "/edit-work-order"]
			// },
			{
				name: "Logs",
				icon: <Log />,
				activeIcon: <ActiveLog />,
				route: "/logs",
				moduleName: CLONOS_GLOBAL_MODULE_LOGS,
				subRoute: ["/view-log", "/create-log-generaldetails", "/edit-log-generaldetails", "/log-entries"]
			},
			{
				name: "Checklist",
				icon: <CheckList />,
				activeIcon: <ActiveCheckList />,
				route: "/checklists",
				moduleName: CLONOS_GLOBAL_MODULE_CHECKLISTS,
				subRoute: ["/view-checklist", "/create-checklist-generaldetails", "/edit-checklist-generaldetails", "/checklist-entries"]
			},
			// {
			// 	name: "Task Library",
			// 	icon: <Task />,
			// 	activeIcon: <ActiveTask />,
			// 	route: "/tasks-library",
			// 	moduleName: CLONOS_GLOBAL_MODULE_TASK_LIBRARY,
			// 	subRoute: ["/create-task", "/edit-task", "/view-task"]
			// },
			// {
			// 	name: "Maintenance Plan",
			// 	icon: <MaintenancePlan />,
			// 	activeIcon: <ActiveMaintenancePlan />,
			// 	route: "/maintenance-plans",
			// 	moduleName: CLONOS_GLOBAL_MODULE_MAINTENANCE_PLANS,
			// 	subRoute: ["/create-scheduled-maintenance", "/edit-scheduled-maintenance", "/view-maintenance-plan", "/maintenance-plans"]
			// }
		]
	},
	{
		name: "Reports",
		icon: <ReportsInActiveIcon/>,
		activeIcon:<ReportsActiveIcon/>,
		route: "/reports",
		moduleName: CLONOS_GLOBAL_MODULE_REPORTS,
	},
	// {
	// 	name: "Plant 3D",
	// 	icon: <Task />,
	// 	activeIcon: <ActiveTask />,
	// 	route: "/plant3D-modules",
	// 	moduleName: CLONOS_GLOBAL_MODULE_PLANT_3D,
	// 	children: [
	// 		{
	// 			name: "3D Library",
	// 			icon: <Task />,
	// 			activeIcon: <ActiveTask />,
	// 			route: "/3D-library",
	// 			moduleName: CLONOS_GLOBAL_MODULE_PLANT_3D,
	// 			// subRoute: ["/create-task", "/edit-task", "/view-task"]
	// 		},
	// 	]
	// }
];

const ClonosLayout = ({ children }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const navigate = useNavigate();
	const theme = useTheme();
	const [selectedParent, setSelectedParent] = useState(null);
	const URL = handleSegregateURL();

	const handleDrawerClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		let pathname = window.location.pathname.substring(1).split("/");
		pathname = `/${pathname[0]}`;
		for (let i = 0; i < drawerMenu.length; i++) {
			let selected = null;
			if ((selected = drawerMenu[i]["route"] === pathname) || (selected = drawerMenu[i]["subRoute"]?.includes(pathname))) {
				setSelectedParent(drawerMenu[i].name);
				break;
			}
			if (drawerMenu[i]?.children?.length > 0 && (selected = drawerMenu[i].children.find(a => ((a.route === pathname) || a.subRoute?.includes(pathname))))) {
				setSelectedParent(drawerMenu[i].name);
				break;
			}
		}
	}, [navigate])

	useEffect(() => {
		localStorage.setItem("isDrawerOpen", open)
	}, [open])

	const onMenuClick = menu => {
		setSelectedParent(prev => {
			if (prev !== menu.name) {
				previousSelected = null;
				return menu.name;

			} else {
				previousSelected = prev;
				return null;
			}
		})
		if (menu.route)
			setTimeout(() => {
				navigate(menu.route);
			})
	}

	return (
		<>
			{(localStorage.getItem("unity") ||
				localStorage.getItem("AR") ||
				localStorage.getItem("VR") ||
				URL?.unity ||
				URL?.AR ||
				URL?.VR) ? (
				<div className={classes.content} style={{ height: "100%" }}>
					{children}
				</div>
			) : (
				<div className={classes.root}>
					<AppBar open={open} setOpen={setOpen} />
					<Drawer
						variant="permanent"
						className={clsx(classes.drawer, {
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						})}
						classes={{
							paper: clsx({
								[classes.drawerOpen]: open,
								[classes.drawerClose]: !open,
							}),
						}}
					>
						<div>
							<div className={classes.toolbar}>
								<img
									className={classes.logo}
									style={{ width: "25px", height: "25px" }}
									src={LOGO}
									alt="logo"
									onClick={() => navigate("/dashboard")}
								/>
								<h5 className={classes.appName} onClick={() => navigate("/dashboard")}>CLONOS</h5>
								<IconButton onClick={handleDrawerClose}>
									{theme.direction === "rtl" ? (
										<ChevronRightIcon />
									) : (
										<ChevronLeftIcon />
									)}
								</IconButton>
							</div>
							{<List className={classes.removePadding}>
								{drawerMenu.map((content, index) => {
									const isActive = [selectedParent, previousSelected].includes(content.name);
									let parentStyle = {
										backgroundColor: isActive ? '#06337E' : '#fff',
										borderRadius: '0.5rem',
									}
									if (open)
										parentStyle = {
											...parentStyle,
											margin: '0rem 0.5rem',
											width: 'calc(100% - 1rem)',

										}
									return checkUserHasPermission({ moduleName: content?.moduleName, isSideBarPermissionsNeeded: true }) ? (
										<>
											<List key={+ Math.random(4)} className={classes.removePadding}>
												<ListItem button onClick={() => onMenuClick(content)} style={parentStyle}>
													<Tooltip title={content?.name}>
														<ListItemIcon style={open ? { minWidth: '40px' } : {}}>{isActive ? content.activeIcon : content.icon}</ListItemIcon>
													</Tooltip>
													<ListItemText style={{ color: isActive ? "#fff" : "#000" }} primary={content.name} />
													{content?.children?.length > 0 ? <IconButton style={previousSelected === content.name ? { rotate: '90deg' } : {}}>
														{isActive ? <ActiveRight /> : <Right />}
													</IconButton> : <></>}
												</ListItem>
												{content?.children?.map(childContent => {
													let pathname = window.location.pathname.substring(1).split("/");
													pathname = `/${pathname[0]}`;
													const isActive = (pathname === childContent.route) || (childContent.subRoute?.includes(pathname));
													let childStyle = {
														backgroundColor: isActive ? '#E6EBF2' : '#fff',
														// paddingLeft: open ? '2rem' : '1rem',
														paddingLeft: "1.5rem",
													}
													if (isActive)
														childStyle = {
															...childStyle,
															borderRadius: '0.5rem',
															borderRight: '0.3rem solid #06337E',
														}
													if (open)
														childStyle = {
															...childStyle,
															width: 'calc(100% - 1rem)',
															margin: '0rem 0.5rem',
														}
													return checkUserHasPermission({ moduleName: childContent?.moduleName, isSideBarPermissionsNeeded: true }) ? <Collapse key={childContent.name} in={selectedParent === content.name}>
														<List>
															<ListItem button onClick={() => onMenuClick(childContent)} style={childStyle}>
																<Tooltip title={childContent?.name}>
																	<ListItemIcon style={open ? { minWidth: '40px' } : {}}>{isActive ? childContent.activeIcon : childContent.icon}</ListItemIcon>
																</Tooltip>
																<ListItemText style={{ color: isActive ? "#06337E" : "#000", backgroundColor: isActive ? '#E6EBF2' : '#fff' }} primary={childContent.name} />
															</ListItem>
														</List>
													</Collapse> : <></>
												})}
											</List>
										</>
									) : <></>
								})}
								{<Divider />}
							</List>}
						</div>
						{(open && (CLONOS_GLOBAL_APP_ENVIRONMENT === "development" || CLONOS_GLOBAL_APP_ENVIRONMENT === "test")) && <div className={classes.sidebar_bottom_container}>
							<div className={classes.version_contianer}>
								<span>{CLONOS_GLOBAL_APP_ENVIRONMENT === "development" ? "DEV" : "TEST"} {CLONOS_GLOBAL_APP_VERSION}</span>
								<span>|</span>
								<span>UI {CLONOS_GLOBAL_APP_UI_VERSION}</span>
							</div>
						</div>}
					</Drawer>
					<main className={classes.contentWrapper} style={{ width: 0 }}>
						<div className="main_toolbar" style={{ height: "7%" }} />
						<div className={classes.content} style={{ height: "92%" }}>
							<Outlet />
						</div>
					</main>
				</div>
			)}
		</>
	);
}

export default ClonosLayout;
