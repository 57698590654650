import {
    CLONOS_GLOBAL_MODULE_ASSETS,
    CLONOS_GLOBAL_MODULE_AUDIT_AND_INSPECTION,
    CLONOS_GLOBAL_MODULE_CHECKLISTS,
    CLONOS_GLOBAL_MODULE_LOGS,
    CLONOS_GLOBAL_MODULE_PLANT_3D,
    CLONOS_GLOBAL_MODULE_REPORTS,
    CLONOS_GLOBAL_MODULE_SPARES,
    CLONOS_GLOBAL_MODULE_USERS,
    CLONOS_GLOBAL_MODULE_WORK_ORDERS,
    CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_SCHEDULED_ENUM,
    CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS
} from "../../Constants/Constant";
import { notificationActions } from "../../Store/Reducers/ClonosNotificationsReducer";
import AppUrl from "../../utils/AppUrl";
import { doGetApiCall, doPatchApiCall } from "../../utils/ClonosApi";


export const handleUpdateStatusOfIsRead = ({ notifications }) => {
    doPatchApiCall({ url: AppUrl?.updateStatusOfIsRead, payload: { notifications } });
}

export const handleViewModuleEntity = ({dispatch, moduleName, entityId, navigate, entityName, handleCloseDrawer, notificationId, activity }) => {
    console.log('entityId:', entityId)
    console.log('moduleName:', moduleName, CLONOS_GLOBAL_MODULE_USERS + "s")
    notificationId && handleUpdateStatusOfIsRead({ notifications: [notificationId] });
    switch (moduleName) {
        case CLONOS_GLOBAL_MODULE_ASSETS: {
            navigate(`/view-asset/${entityName}`, { state: { id: entityId } });
        }
            break;

        case CLONOS_GLOBAL_MODULE_USERS: {
            navigate(`/profile/${entityName}`, { state: { userId: entityId } });
        }
            break;

        case CLONOS_GLOBAL_MODULE_CHECKLISTS: {
            if (activity === CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_SCHEDULED_ENUM) {
                navigate(`/checklist-entries`, { state: { _id: entityId, name: entityName } })
            } else {
                navigate(`/view-checklist/${entityName}`, { state: { entryId: entityId } })
            };
        }
            break;

        case CLONOS_GLOBAL_MODULE_LOGS: {
            if (activity === CLONOS_GLOBAL_NOTIFICATION_ACTIVITY_SCHEDULED_ENUM) {
                navigate(`/log-entries`, { state: { _id: entityId, name: entityName } })
            } else {
                navigate(`/view-log/${entityName}`, { state: { entryId: entityId } })
            };
        }
            break;

        case CLONOS_GLOBAL_MODULE_WORK_ORDERS: {

        }
            break;

        case CLONOS_GLOBAL_MODULE_LOGS: {

        }
            break;

        case CLONOS_GLOBAL_MODULE_REPORTS: {

        }
            break;

        case CLONOS_GLOBAL_MODULE_PLANT_3D: {

        }
            break;

        case CLONOS_GLOBAL_MODULE_SPARES: {

        }
            break;

        case CLONOS_GLOBAL_MODULE_AUDIT_AND_INSPECTION: {

        }
            break;

    }
    handleCloseDrawer && handleCloseDrawer();
    handleGetUnreadNotificationCount({dispatch})
}


export const handleGetUnreadNotificationCount = ({dispatch}) => {
    doGetApiCall({url:AppUrl.getUnreadNotificationCount})
    .then((res) => {
        if(res?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS){
            let count = res?.data?.result?.unReadNotificationsCount
            dispatch(notificationActions.setUnreadNotificationsCountToolkitState(count));
        }
    })
}