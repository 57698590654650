import Styles from "./MultipleFieldValueComponent.module.css";
import TEMPLATE_CREATION_REMOVEROW from "../../../../../../assets/UIUX/icons/Checklist/circle-minus.svg";
import ClonosInput from "../../../../../../components/CommonComponents/ClonosInput/ClonosInput";
import { v4 as uuidv4 } from 'uuid';
import { CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_OPTIONS_LIMIT } from "../../../../../../Constants/Constant";
import { handleShowErrorAndWarning } from "../../../../../../utils/clonosCommon";
import { useDispatch } from "react-redux";
import DynamicDropdown from "../../../../../../components/CommonComponents/DynamicDropdown/DynamicDropdown";
import PlusStaticIcon from "../../../../../../resources/icons/static/PlusStaticIcon";

/**
 * Component for handling multiple field values in a form.
 * @param {Object} getFieldDetails - Function to update field details.
 * @param {Object} attribute - Object containing field details.
 * @param {Function} handleGetErrorActivatorInformation - Function to handle error information.
 * @param {Function} handlePrimaryObjectModifier - Function to modify the primary object.
 * @returns {JSX.Element} - JSX Element for rendering multiple field values.
 */
export const MultipleFieldValueComponent = ({
    getFieldDetails,
    attribute,
    handleGetErrorActivatorInformation,
    handlePrimaryObjectModifier,
    handleDeleteKeyFromPrimaryObject,
    assets,
    handleMoreData,
    isEdit = false,
    handleDynamicSearch
}) => {
    console.log('attribute:', attribute)
    const dispatch = useDispatch();

    /**
     * Handles the addition of new options to the field value.
     */
    const handleAddOptions = () => {
        getFieldDetails({ ...attribute, fieldValue: [...attribute?.fieldValue, { optionId: uuidv4(), optionValue: "", isActive: false }] })
    }

    /**
     * Handles the removal of a specific option from the field value.
     * @param {string} id - Unique identifier of the option to be removed.
     */
    const handleRemoveOption = (id) => {
        if (attribute?.fieldValue?.length > 1) {
            const newOptions = attribute?.fieldValue?.filter((option) => {
                if (option?.optionId !== id) {
                    return option
                } else {
                    handleDeleteKeyFromPrimaryObject(option?.optionId)
                }
            })
            getFieldDetails({ ...attribute, fieldValue: newOptions })
        }
    }

    /**
     * Handles changes in the field name or field value.
     * @param {Object} props - Updated values and metadata.
     * @param {string} optionId - Unique identifier of the option being modified.
     */
    const handleChange = (props, optionId) => {
        if (props?.name === "fieldName") {
            handlePrimaryObjectModifier({ response: props?.updatedValue, uniqueKey: props?.uniqueKey })
            getFieldDetails({ ...attribute, fieldName: props?.updatedValue, uniqueKey: props?.uniqueKey })
        } else if (props?.name === "fieldValue") {
            let changedElement = null;
            let updatedOptions = attribute?.fieldValue?.map((option) => {
                if (option?.optionId === props?.uniqueKey) {
                    changedElement = { ...option, optionValue: props?.updatedValue };
                    return { ...option, optionValue: props?.updatedValue }
                } else {
                    return option
                }
            })
            handlePrimaryObjectModifier({ response: changedElement?.optionValue, uniqueKey: changedElement?.optionId })
            getFieldDetails({ ...attribute, fieldValue: updatedOptions, uniqueKey: props?.uniqueKey })
        }
    }

    return <>
        <div className={Styles.asset_container}>

            <DynamicDropdown
                label="Asset"
                isLabel={false}
                uniqueKey={"asset"}
                isActivator={true}
                data={assets}
                heading={"Select from Asset Library"}
                placeholder={"Search"}
                isSearchable={true}
                isCheckbox={false}
                isDynamicLoad={handleMoreData ? true : false}
                isMandatory={false}
                defaultValue={(attribute?.asset && isEdit) && [{ "name": attribute?.asset }]}
                handleMoreData={handleMoreData}
                handleGetValues={(props) => {
                    getFieldDetails({ ...attribute, uniqueKey: props.uniqueKey, asset: props?.updatedValue[0].id })
                }}
                handleDynamicSearch={handleDynamicSearch}
            />
        </div>
        <div className={Styles.fieldNameTaker}>
            {/* Input for capturing the field name */}
            <ClonosInput
                value={attribute?.fieldName || ""}
                name="fieldName"
                placeholder="Add Name"
                uniqueKey={attribute?.id || ""}
                isMandatory={true}
                handleGetValues={handleChange}
                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                ariaLabel="Field Name Input"
                isDebounce={true}
            />
        </div>
        <div className={Styles.fieldValueTaker}>
            <div className={Styles.multiple_fieldvalue_taker_container}>
                {
                    // Mapping through each field value option
                    attribute?.fieldValue?.map((option, index) => (
                        <div className={Styles.option_box} key={option?.optionId}>
                            {/* Input for capturing the field value */}
                            <ClonosInput
                                value={option?.optionValue || ""}
                                name="fieldValue"
                                placeholder={`Add ${attribute?.attributeName}`}
                                uniqueKey={option?.optionId || ""}
                                handleGetValues={handleChange}
                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                isMandatory={true}
                                ariaLabel={`Field Value Input ${index + 1}`}
                                isDebounce={true}
                            />
                            {
                                // Displaying remove option icon if there is more than one option
                                attribute?.fieldValue?.length > 1 && <div className={Styles.remove_option} onClick={() => handleRemoveOption(option?.optionId)}>
                                    <img src={TEMPLATE_CREATION_REMOVEROW} alt="Remove Option" />
                                </div>
                            }
                        </div>
                    ))
                }
                {/* Container for adding a new option */}
                {<div className={Styles.add_option_container}>
                    <div
                        style={attribute?.fieldValue?.length === CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_OPTIONS_LIMIT ? { cursor: "not-allowed", userSelect: "none" } : { cursor: "pointer" }}
                        onClick={() => {
                            if (attribute?.fieldValue?.length === CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_OPTIONS_LIMIT) {
                                handleShowErrorAndWarning({ dispatch, type: "warning", message: `You can only add up to ${CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_OPTIONS_LIMIT} options in one attribute.`, showTime: 5000 });
                                return;
                            };
                            handleAddOptions();
                        }}>
                        {/* <div><img src={TEMPLATE_CREATION_CIRCLEPLUS} alt="Add Option" /></div> */}
                        <PlusStaticIcon fill={attribute?.fieldValue?.length === CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_OPTIONS_LIMIT ? "#d3d3d3" : null} />
                        <p
                            style={attribute?.fieldValue?.length === CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_OPTIONS_LIMIT ? { color: "#d3d3d3" } : {}}
                        >Add Option</p>
                    </div>
                </div>}
            </div>
        </div >
    </>
}
