const Task = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M9 12H9.01M13 12H15M9 16H9.01M13 16H15" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M9 12H9.01M13 12H15M9 16H9.01M13 16H15" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const ActiveTask = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M9 12H9.01M13 12H15M9 16H9.01M13 16H15" stroke="#06337E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const TotalTasks = () => <svg width="85" height="84" viewBox="0 0 85 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="42.1016" cy="42" r="42" fill="#E7EFFF"/>
<path d="M37.0007 30.3333H33.6673C32.7833 30.3333 31.9354 30.6845 31.3103 31.3096C30.6852 31.9348 30.334 32.7826 30.334 33.6667V53.6667C30.334 54.5507 30.6852 55.3986 31.3103 56.0237C31.9354 56.6488 32.7833 57 33.6673 57H50.334C51.218 57 52.0659 56.6488 52.691 56.0237C53.3161 55.3986 53.6673 54.5507 53.6673 53.6667V33.6667C53.6673 32.7826 53.3161 31.9348 52.691 31.3096C52.0659 30.6845 51.218 30.3333 50.334 30.3333H47.0007M37.0007 30.3333C37.0007 29.4493 37.3518 28.6014 37.977 27.9763C38.6021 27.3512 39.4499 27 40.334 27H43.6673C44.5514 27 45.3992 27.3512 46.0243 27.9763C46.6495 28.6014 47.0007 29.4493 47.0007 30.3333M37.0007 30.3333C37.0007 31.2174 37.3518 32.0652 37.977 32.6904C38.6021 33.3155 39.4499 33.6667 40.334 33.6667H43.6673C44.5514 33.6667 45.3992 33.3155 46.0243 32.6904C46.6495 32.0652 47.0007 31.2174 47.0007 30.3333M37.0007 42H37.0173M43.6673 42H47.0007M37.0007 48.6667H37.0173M43.6673 48.6667H47.0007" stroke="#0A5FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export {
    Task, ActiveTask,TotalTasks
}