import React, { useEffect, useState } from 'react'
import Styles from "./notifications-drawer.module.css"
import { CLONOS_GLOBAL_APP_ENVIRONMENT, CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS } from '../../../Constants/Constant';
import useToggler from '../../../CustomHooks/TogglerHook';
import { commonPageState, getDayMonthYear, globalOnScrollEnd, handleClientDateInConfiguredTimeZone, modifyString } from '../../../utils/clonosCommon';
import DatePicker from '../../../assets/svg/DatePicker';
import ClonosSpinner from '../../../components/CommonComponents/ClonosSpinner/ClonosSpinner';
import { handleGetNotifications, handleUpdatePagination } from './notifications-drawer-manager';
import BellIcon from '../../../resources/icons/interactive/BellInteractiveIcon';
import UserInteractiveIcon from '../../../resources/icons/interactive/UserInteractiveIcon';
import BellStaticIcon from '../../../resources/icons/static/BellStaticIcon';
import { useSelector } from 'react-redux';
import { handleViewModuleEntity } from '../managers';
import { useNavigate } from 'react-router-dom';
import { notificationActions } from '../../../Store/Reducers/ClonosNotificationsReducer';
import { useDispatch } from 'react-redux';



const NotificationsDrawer = () => {
    const toastNotifications = useSelector(store => store.notificationsData.notifications);
    const unreadNotificationsCount = useSelector(store => store.notificationsData.unreadNotificationsCount)
    const navigate = useNavigate();
    console.log('toastNotifications:', toastNotifications)
    const [toggler, setToggler] = useToggler();
    const [pagination, setPagination] = useState(commonPageState);
    console.log('pagination:', pagination)
    const [lcValues, setLcValues] = useState({
        getNotificationsLoading: false
    });
    const [serverResponse, setServerResponse] = useState({
        notifications: {},
        totalNotifications: 0
    });
    const { totalNotifications, notifications } = serverResponse;
    const { getNotificationsLoading } = lcValues;
    const [refreshAPI, setRefreshAPI] = useToggler();
    const dispatch = useDispatch();

    console.log('notifications:', notifications)

    const handleUpdatetoggle = () => {
        setToggler();
        setRefreshAPI();
        setPagination(commonPageState);
        setServerResponse({ ...serverResponse, notifications: {} });
        dispatch(notificationActions.setNotificationsToolkitState([]));
    }

    useEffect(() => {
        if (notifications?.totalPageCount !== notifications?.currentPage || pagination?.page === 1) {
            setLcValues((prev) => {
                return { ...prev, ["getNotificationsLoading"]: true }
            });
            handleGetNotifications({
                responseSetterMethod: setServerResponse,
                lcStateSetterMethod: setLcValues,
                pagination
            });
        }
    }, [pagination, refreshAPI]);

    useEffect(() => {
        globalOnScrollEnd(() => {
            handleUpdatePagination({ setServerResponse, setPagination });
        }, "notifications_container");
    }, []);

    console.log(unreadNotificationsCount,"unreadNotificationsCount")
    return (
        <div className={Styles.main_container}>
            <div
                onClick={handleUpdatetoggle}
                className={Styles.bell_container}
            >
                <BellIcon
                    tooltipTitle="Notifications"
                    style={{ backgroundColor: CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT, padding: "0.3rem", zIndex: "1" }}
                />
                {/* {
                    toastNotifications?.length > 0 && <span className={Styles.number_of_notification}>{toastNotifications?.length < 10 ? `${toastNotifications?.length}` : `9+`}</span>
                } */}
                {
                    unreadNotificationsCount > 0 && <span className={Styles.number_of_notification}>{unreadNotificationsCount < 10 ? `${unreadNotificationsCount}` : `9+`}</span>
                }
            </div>
            <div className={`${Styles.transform} ${toggler ? Styles.active_grow_container : Styles.grow_container}`}>
                <header className={Styles.header}>
                    <span style={{ color: "#000" }}>Notifications</span>
                </header>
                <div id='notifications_container' className={Styles.notifications_container}>
                    {
                        notifications?.data?.length ? notifications?.data?.map((notification, index) => {
                            const time = handleClientDateInConfiguredTimeZone({ dateString: notification?.createdAt, parseType: "object" })
                            return <div
                                key={notification?.id}
                                className={Styles.notification_container}
                                onClick={() => handleViewModuleEntity(
                                    {
                                        moduleName: notification?.moduleName,
                                        entityId: notification?.moduleDetails?.id,
                                        entityName: notification?.moduleDetails?.name,
                                        navigate,
                                        dispatch,
                                        handleCloseDrawer: setToggler,
                                        notificationId: notification?.id,
                                        activity: notification?.activity
                                    }
                                )}
                            >
                                <UserInteractiveIcon style={{ backgroundColor: CLONOS_GLOBAL_DISABLED_COLOUR_BACKGROUND_LIGHT, padding: "0.3rem", zIndex: "1" }} />
                                <div
                                    aria-label='notification information'
                                    className={Styles.notification_information_container}
                                >
                                    {
                                        notification?.moduleDetails?.name ?
                                            <p
                                                className={Styles.message}
                                                style={notification?.isRead === "false" ? { fontWeight: 700, color: "#000" } : {}}
                                            >
                                                <span>{`"${modifyString(notification?.moduleDetails.name, "capitalize")}" `}</span>{notification?.message}
                                            </p>
                                            : <p><span>"-"</span>{(notification?.message || "").split(",")?.[0]}</p>
                                    }
                                    <div className={Styles.time}>
                                        <DatePicker />
                                        <span>{`${time?.monthString} ${time?.day} at ${time?.hour}:${time?.minute}`}</span>
                                    </div>
                                </div>
                            </div>
                        }) : <div className={Styles.no_notifications}>
                            <div className={Styles.shake_container}>
                                <BellStaticIcon />
                            </div>
                            <span>No Notification Yet</span>
                        </div>
                    }
                    {getNotificationsLoading && <div className={Styles.loading_spinner_container}> <ClonosSpinner /> </div>}
                </div>
            </div>
            {toggler && <div onClick={handleUpdatetoggle} className={Styles.overlay}></div>}
        </div >
    );
};

export default NotificationsDrawer;