export const AppUrl = {


    /* User Management */
    createUser: '/auth/signup',
    saveUserAsDraft: '/auth/signup?draft=true',
    deleteUser: '/users/disable',
    deleteTeam: '/teams',
    getDesignations: '/designations',
    getAllUsers: '/users',
    getTeams: "/teams",
    getShifts:"/shifts",


    /* Permissions */
    getAllActivePermissionsModules: '/permissionGroups',
    assignToMultipleUsers: '/userPermissions/updateMultiple',
    assignToMultipleDesignations: '/designations/updateMultiple',

    /* Task Library */
    getTaskList: '/tasksLibrary',
    deleteTask: '/tasksLibrary/delete',
    getSingleTask: 'tasksLibrary/singleTask',
    createTask: '/tasksLibrary/create',
    editTask: '/tasksLibrary/tasks/edit',

    /* Asset Management */
    getAssets: '/assets',
    createAsset: '/assets',
    editAsset: '/assets',
    deleteAssets: '/assets',
    getAssetCounts: '/assets/count',
    getTeamForDropdown: '/teams/getTeams',
    getAssetCategories: '/assetCategories',
    createAssetDocument: "/assetDocuments",
    getAssetForDropdown: '/assets/dropdown',
    getUserForDropdown: '/admin/userDropdown',
    getDocumentFunctionArea: '/functionArea/dropdown',
    getIncompleteModulesDetailsOfAsset: '/assets/count',
    getDocumentType: '/documents/docType/getDocumentType',
    getDocumentApprovalStatus: '/approvals/approvalStatus',
    getAssetsCounts: "/assets/activeAssets/underMaintenance",



    // SCHEDULE MAINTENANCE:
    getAllScheduledMaintenance: '/scheduleMaintenance',
    getSingleMaintenanceDetails: '/scheduleMaintenance',
    editMaintenanceTask: '/scheduleMaintenance/edit',
    deleteMaintenanceTask: '/scheduleMaintenance/delete',

    // Work order
    deleteMultiple: '/workorders/deleteMutiple',

    // Checklist 
    getChecklistStatuses: 'checklists/checklistStatus/count',
    getChecklistStutuses: '/checklists/status/statusCount',
    getSaveAsTemplates: "/checklists/general/templates",
    deleteTemplates: "/checklists/templates/delete",
    postChecklistGeneralDetails: "/checklists/general-details",
    createAndSaveAsTemplate: "checklists/create-template",
    getTemplateDetails: "/checklists/templates/",
    getChecklists: "checklists",
    getSingleChecklistDetails: "checklists",
    getRecurredChecklistEntries: "checklists",
    getVersionTemplateDataSets: "/checklists/versions",
    getEntryDetails: "/checklists/entry",
    getChecklistStructure: "/checklists/versions",
    updatedTemplate: "/checklists/checklistStructure",
    fieldUniqueness: "/checklists/field/check-uniqueness",
    updateGeneralTemplate: "/checklists/templates",
    fillEntry: "/checklists/fill-entries",
    updateFilledEntry: "/checklists/entries",
    approveFilledEntry: "/checklists/entry-status",
    getTimePeriods: "/checklists/checklist/timePeriod",

    getWorkOrderStatuses: "/workorders/status/statusCount",

    // Log Endpoints 
    getLogStatuses: '/logs/logStatus/count',
    getLogSaveAsTemplates: "/logs/general/templates",
    deleteTemplates: "/checklists/templates/delete",
    postLogGeneralDetails: "/logs/general-details",
    createAndSaveAsLogTemplate: "logs/create-template",
    getLogTemplateDetails: "/logs/templates/",
    getLogs: "/logs",
    getSingleLogDetails: "/logs",
    getRecurredLogEntries: "/logs",
    getLogVersionTemplateDataSets: "/logs/versions",
    getLogEntryDetails: "/logs/entry",
    getLogStructure: "/logs/versions",
    updatedLogTemplate: "/logs/logStructure",
    logFieldUniqueness: "/logs/field/check-uniqueness",
    updateLogGeneralTemplate: "/logs/templates",
    fillLogEntry: "/logs/fill-entries",
    updateLogFilledEntry: "/logs/entries",
    approveLogFilledEntry: "/logs/entry-status",
    getLogTimePeriods: "/logs/log/timePeriod",

    // Documents Endpoints 
    getDocuments: '/assetDocuments',
    createDocument: '/assetDocuments',
    editDocument: '/assetDocuments/edit',
    deleteDocuments: '/assetDocuments',
    deleteMultipleDocuments: "/assetDocuments/deleteMultiple",


    // Dashboard Endpoints 
    dashboard: "/dashboard",

    // Plant 3D
    createPlant3D: '/plant3DModels',
    getAllPlant3DModels: '/plant3DModels',
    deletePlant3DModel: '/plant3DModels/delete',

    // 3D Object
    getUserUploaded3DObjects: '/userUploaded3DModels',
    deleteUserUploaded3DObject: '/userUploaded3DModels/delete',
    getStandard3DObjects: '/standard3DModels',

    //Common 
    getDepartments: "/departments",


    // SPARES & INVENTORY
    getSparesCount: "/spares/statusCount",
    getAllSpares: "/spares",
    createSpare: "/spares",
    editSpare: "/spares",
    getSingleSpare: "/spares",
    deleteSpares: "/spares/delete",

    // Notifications
    getNotifications: "/notifications",
    updateStatusOfIsRead: "/notifications/changeStatus",
    getUnreadNotificationCount:"/notifications/unreadNotificationsCount", 
    // FILE SYSTEM
    uploadFiles: "/files?returnMetaData=true",
    getMultipleFiles: '/files',
    uploadMultipleFiles: "/files/bulkUpload?returnMetaData=true",
    getFiles: "/files",
    
    // REPORTS 
    getReportsList: '/reports/listing',
    getReportsModules: '/reports/report-module',
    getReportsFormats: '/reports/report-format',
    generateReport: '/reports/create-report',
    getReportStatusCounts: '/reports/count',


}

export default AppUrl;