import { useState, useEffect } from 'react';

const useImagesLoaded = (imageUrls) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        let isCancelled = false;

        const loadImage = (src) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        };

        Promise.all(imageUrls.map((url) => loadImage(url)))
            .then(() => {
                if (!isCancelled) {
                    setLoaded(true);
                }
            })
            .catch(() => {
                if (!isCancelled) {
                    setLoaded(false);
                }
            });

        return () => {
            isCancelled = true;
        };
    }, [imageUrls]);

    return loaded;
};

export default useImagesLoaded;
