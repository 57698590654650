import React from 'react'
import useToggler from '../../CustomHooks/TogglerHook';
import { Tooltip } from '@material-ui/core';
import { CLONOS_GLOBAL_COLOR_ICON_BLACK, CLONOS_GLOBAL_COLOUR_BLUE, CLONOS_GLOBAL_COLOUR_BLUE_HOVER } from '../../Constants/Constant';

const User = ({ height, width, size, color, onClick, tooltipTitle = 'User', strokeWidth, strokeColor, style }) => {
    let [isHover, setIsHover] = useToggler();
    return (
        <Tooltip title={tooltipTitle}>
            <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => onClick()}
                style={{ padding: "0px", margin: "0px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content", height: "fit-content", cursor: "pointer", ...style }}
            >
                <svg width={size ? size : width ? width : "24"} height={size ? size : height ? height : "25"} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 28V25.3333C8 23.9188 8.5619 22.5623 9.5621 21.5621C10.5623 20.5619 11.9188 20 13.3333 20H18.6667C20.0812 20 21.4377 20.5619 22.4379 21.5621C23.4381 22.5623 24 23.9188 24 25.3333V28M10.6667 9.33333C10.6667 10.7478 11.2286 12.1044 12.2288 13.1046C13.229 14.1048 14.5855 14.6667 16 14.6667C17.4145 14.6667 18.771 14.1048 19.7712 13.1046C20.7714 12.1044 21.3333 10.7478 21.3333 9.33333C21.3333 7.91885 20.7714 6.56229 19.7712 5.5621C18.771 4.5619 17.4145 4 16 4C14.5855 4 13.229 4.5619 12.2288 5.5621C11.2286 6.56229 10.6667 7.91885 10.6667 9.33333Z" stroke={isHover ? CLONOS_GLOBAL_COLOUR_BLUE_HOVER : strokeColor ? strokeColor : CLONOS_GLOBAL_COLOR_ICON_BLACK} stroke-width={strokeWidth ? strokeWidth : "2"} stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </Tooltip>
    )
}

export default User