import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersWithPermissions: [],
  changedPermissions: {},
  designationsWithPermissions: []
};

const permissionsSlice = createSlice({
  name: "permissionsData",
  initialState,
  reducers: {
    getAllUsersWithPermissions(state, action) {
      state.usersWithPermissions = action.payload
    },
    getChangedPermissions(state,action){
        state.changedPermissions = action.payload
    },
    getDesignationsWithPermissions(state,action) {
      state.designationsWithPermissions = action.payload
    }
  },
});
export const permissionsActions = permissionsSlice.actions;
export default permissionsSlice.reducer;
