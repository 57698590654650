const TotalReportsIcon = () => {
    return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="clipboard-check">
    <path id="Vector" d="M15.0026 8.33333H11.6693C10.7852 8.33333 9.93737 8.68452 9.31225 9.30964C8.68713 9.93477 8.33594 10.7826 8.33594 11.6667V31.6667C8.33594 32.5507 8.68713 33.3986 9.31225 34.0237C9.93737 34.6488 10.7852 35 11.6693 35H28.3359C29.22 35 30.0678 34.6488 30.693 34.0237C31.3181 33.3986 31.6693 32.5507 31.6693 31.6667V11.6667C31.6693 10.7826 31.3181 9.93477 30.693 9.30964C30.0678 8.68452 29.22 8.33333 28.3359 8.33333H25.0026M15.0026 8.33333C15.0026 7.44928 15.3538 6.60143 15.9789 5.97631C16.604 5.35119 17.4519 5 18.3359 5H21.6693C22.5533 5 23.4012 5.35119 24.0263 5.97631C24.6514 6.60143 25.0026 7.44928 25.0026 8.33333M15.0026 8.33333C15.0026 9.21739 15.3538 10.0652 15.9789 10.6904C16.604 11.3155 17.4519 11.6667 18.3359 11.6667H21.6693C22.5533 11.6667 23.4012 11.3155 24.0263 10.6904C24.6514 10.0652 25.0026 9.21739 25.0026 8.33333M15.0026 23.3333L18.3359 26.6667L25.0026 20" stroke="#0A5FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>    
}

const ApprovedReportsIcon = () => {
    return <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="checks">
    <path id="Vector" d="M11.7708 20.0013L20.1042 28.3346L36.7708 11.668M3.4375 20.0013L11.7708 28.3346M20.1042 20.0013L28.4375 11.668" stroke="#60D468" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
    
}

const PendingForApprovalReportsIcon = () => {
    return <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="hourglass-empty">
    <path id="Vector" d="M20.9844 20C18.3322 20 15.7887 21.0536 13.9133 22.9289C12.0379 24.8043 10.9844 27.3478 10.9844 30V33.3333C10.9844 33.7754 11.16 34.1993 11.4725 34.5118C11.7851 34.8244 12.209 35 12.651 35H29.3177C29.7597 35 30.1837 34.8244 30.4962 34.5118C30.8088 34.1993 30.9844 33.7754 30.9844 33.3333V30C30.9844 27.3478 29.9308 24.8043 28.0554 22.9289C26.1801 21.0536 23.6365 20 20.9844 20ZM20.9844 20C18.3322 20 15.7887 18.9464 13.9133 17.0711C12.0379 15.1957 10.9844 12.6522 10.9844 10V6.66667C10.9844 6.22464 11.16 5.80072 11.4725 5.48816C11.7851 5.17559 12.209 5 12.651 5H29.3177C29.7597 5 30.1837 5.17559 30.4962 5.48816C30.8088 5.80072 30.9844 6.22464 30.9844 6.66667V10C30.9844 12.6522 29.9308 15.1957 28.0554 17.0711C26.1801 18.9464 23.6365 20 20.9844 20Z" stroke="#E5B82F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
    
}

const ReportsActiveIcon = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="report-analytics">
    <path id="Vector" d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M9 17V12M12 17V16M15 17V14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
    
}

const ReportsInActiveIcon = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="report-analytics">
    <g id="Vector">
    <path d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M9 17V12M12 17V16M15 17V14" stroke="#575757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M9 17V12M12 17V16M15 17V14" stroke="black" stroke-opacity="0.2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </g>
    </svg>
    
}

export {TotalReportsIcon,ApprovedReportsIcon, PendingForApprovalReportsIcon,ReportsActiveIcon,ReportsInActiveIcon}