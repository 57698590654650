import React, { useEffect, useState } from 'react'
import Styles from "./Threshold.module.css"
import ClonosInput from '../../../../../components/CommonComponents/ClonosInput/ClonosInput';
import useFieldsChecker from '../../../../../CustomHooks/FieldsValidatorHook';
import ClonosRadioAndCheckbox from '../../../../../components/CommonComponents/ClonosRadio&Checkbox/ClonosRadio&Checkbox';
import Scale from '../Scale/Scale';
import { ClonosButton } from '../../../../../components/CommonComponents/Button/Button';


/**
 * Threshold component for managing threshold limits and their associated values.
 * This component allows users to set and modify the following threshold parameters:
 * - Minimum Limit
 * - Maximum Limit
 * - Safe Operating Low Limit
 * - Safe Operating High Limit
 * - Best Batch Low Limit
 * - Best Batch High Limit
 * 
 * It utilizes local state to manage the values of these parameters, and integrates
 * with the `useFieldsChecker` hook for error handling and primary object modifications.
 * The component also provides a mechanism to retrieve updated values via the
 * `handleGetValues` function passed as a prop.
 * 
 * @param {Object} param0 - Component props
 * @param {string} param0.uniqueKey - Unique identifier for the threshold instance
 * @param {Function} param0.handleClose - Function to close the threshold component
 * @param {Function} param0.handleGetValues - Function to retrieve the current threshold values
 * @returns {JSX.Element} Rendered Threshold component
 */

const Threshold = ({ uniqueKey, handleClose, handleGetValues }) => {
    const { handleGetErrorActivatorInformation, handlePrimaryObjectModifier, handleGetUpdatedPrimaryObject } = useFieldsChecker();
    const [minLimit, setMinLimit] = useState(1);
    const [maxLimit, setMaxLimit] = useState(100);
    const [safeOperatingLow, setSafeOperatingLow] = useState(null);
    const [safeOperatingHigh, setSafeOperatingHigh] = useState(null);
    const [bestBatchLow, setBestBatchLow] = useState(null);
    const [bestBatchHigh, setBestBatchHigh] = useState(null);
    const [lcValues, setLcValues] = useState({});

    const handleValidateLimits = ({
        minLimit,
        safeOperatingLow,
        bestBatchLow,
        bestBatchHigh,
        safeOperatingHigh,
        maxLimit,
        uniqueKey
    }) => {

        // Helper function to check if a value is valid (not null, undefined, or empty)
        const isValidInput = (value) => value !== null && value !== undefined && value !== '' && value !== NaN;

        // Minimum Limit validations
        if (uniqueKey === "minLimit") {
            if (isValidInput(minLimit) && isValidInput(safeOperatingLow) && minLimit >= safeOperatingLow) {
                return "Minimum Limit must be less than Safe Operating Limit - Low.";
            }
            if (isValidInput(minLimit) && isValidInput(bestBatchLow) && minLimit >= bestBatchLow) {
                return "Minimum Limit must be less than Best Batch Limit - Low.";
            }
            if (isValidInput(minLimit) && isValidInput(bestBatchHigh) && minLimit >= bestBatchHigh) {
                return "Minimum Limit must be less than Best Batch Limit - High.";
            }
            if (isValidInput(minLimit) && isValidInput(safeOperatingHigh) && minLimit >= safeOperatingHigh) {
                return "Minimum Limit must be less than Safe Operating Limit - High.";
            }
            if (isValidInput(minLimit) && isValidInput(maxLimit) && minLimit >= maxLimit) {
                return "Minimum Limit must be less than Maximum Limit.";
            }
        }

        // Safe Operating Limit - Low validations
        if (uniqueKey === "safeOperatingLow") {
            if (isValidInput(safeOperatingLow) && isValidInput(minLimit) && safeOperatingLow <= minLimit) {
                return "Safe Operating Limit - Low must be greater than Minimum Limit.";
            }
            if (isValidInput(safeOperatingLow) && isValidInput(bestBatchLow) && safeOperatingLow >= bestBatchLow) {
                return "Safe Operating Limit - Low must be less than Best Batch Limit - Low.";
            }
            if (isValidInput(safeOperatingLow) && isValidInput(bestBatchHigh) && safeOperatingLow >= bestBatchHigh) {
                return "Safe Operating Limit - Low must be less than Best Batch Limit - High.";
            }
            if (isValidInput(safeOperatingLow) && isValidInput(safeOperatingHigh) && safeOperatingLow >= safeOperatingHigh) {
                return "Safe Operating Limit - Low must be less than Safe Operating Limit - High.";
            }
            if (isValidInput(safeOperatingLow) && isValidInput(maxLimit) && safeOperatingLow >= maxLimit) {
                return "Safe Operating Limit - Low must be less than Maximum Limit.";
            }
        }

        // Best Batch Limit - Low validations
        if (uniqueKey === "bestBatchLow") {
            if (isValidInput(bestBatchLow) && isValidInput(minLimit) && bestBatchLow <= minLimit) {
                return "Best Batch Limit - Low must be greater than Minimum Limit.";
            }
            if (isValidInput(bestBatchLow) && isValidInput(safeOperatingLow) && bestBatchLow <= safeOperatingLow) {
                return "Best Batch Limit - Low must be greater than Safe Operating Limit - Low.";
            }
            if (isValidInput(bestBatchLow) && isValidInput(bestBatchHigh) && bestBatchLow >= bestBatchHigh) {
                return "Best Batch Limit - Low must be less than Best Batch Limit - High.";
            }
            if (isValidInput(bestBatchLow) && isValidInput(safeOperatingHigh) && bestBatchLow >= safeOperatingHigh) {
                return "Best Batch Limit - Low must be less than Safe Operating Limit - High.";
            }
            if (isValidInput(bestBatchLow) && isValidInput(maxLimit) && bestBatchLow >= maxLimit) {
                return "Best Batch Limit - Low must be less than Maximum Limit.";
            }
        }

        // Best Batch Limit - High validations
        if (uniqueKey === "bestBatchHigh") {
            if (isValidInput(bestBatchHigh) && isValidInput(minLimit) && bestBatchHigh <= minLimit) {
                return "Best Batch Limit - High must be greater than Minimum Limit.";
            }
            if (isValidInput(bestBatchHigh) && isValidInput(safeOperatingLow) && bestBatchHigh <= safeOperatingLow) {
                return "Best Batch Limit - High must be greater than Safe Operating Limit - Low.";
            }
            if (isValidInput(bestBatchHigh) && isValidInput(bestBatchLow) && bestBatchHigh <= bestBatchLow) {
                return "Best Batch Limit - High must be greater than Best Batch Limit - Low.";
            }
            if (isValidInput(bestBatchHigh) && isValidInput(safeOperatingHigh) && bestBatchHigh >= safeOperatingHigh) {
                return "Best Batch Limit - High must be less than Safe Operating Limit - High.";
            }
            if (isValidInput(bestBatchHigh) && isValidInput(maxLimit) && bestBatchHigh >= maxLimit) {
                return "Best Batch Limit - High must be less than Maximum Limit.";
            }
        }

        // Safe Operating Limit - High validations
        if (uniqueKey === "safeOperatingHigh") {
            if (isValidInput(safeOperatingHigh) && isValidInput(minLimit) && safeOperatingHigh <= minLimit) {
                return "Safe Operating Limit - High must be greater than Minimum Limit.";
            }
            if (isValidInput(safeOperatingHigh) && isValidInput(safeOperatingLow) && safeOperatingHigh <= safeOperatingLow) {
                return "Safe Operating Limit - High must be greater than Safe Operating Limit - Low.";
            }
            if (isValidInput(safeOperatingHigh) && isValidInput(bestBatchLow) && safeOperatingHigh <= bestBatchLow) {
                return "Safe Operating Limit - High must be greater than Best Batch Limit - Low.";
            }
            // if (isValidInput(safeOperatingHigh) && isValidInput(bestBatchHigh) && safeOperatingHigh >= bestBatchHigh) {
            //     return "Safe Operating Limit - High must be less than Best Batch Limit - High.";
            // }
            if (isValidInput(safeOperatingHigh) && isValidInput(maxLimit) && safeOperatingHigh >= maxLimit) {
                return "Safe Operating Limit - High must be less than Maximum Limit.";
            }
        }

        // Maximum Limit validations
        if (uniqueKey === "maxLimit") {
            if (isValidInput(maxLimit) && isValidInput(minLimit) && maxLimit <= minLimit) {
                return "Maximum Limit must be greater than Minimum Limit.";
            }
            if (isValidInput(maxLimit) && isValidInput(safeOperatingLow) && maxLimit <= safeOperatingLow) {
                return "Maximum Limit must be greater than Safe Operating Limit - Low.";
            }
            if (isValidInput(maxLimit) && isValidInput(bestBatchLow) && maxLimit <= bestBatchLow) {
                return "Maximum Limit must be greater than Best Batch Limit - Low.";
            }
            if (isValidInput(maxLimit) && isValidInput(bestBatchHigh) && maxLimit <= bestBatchHigh) {
                return "Maximum Limit must be greater than Best Batch Limit - High.";
            }
            if (isValidInput(maxLimit) && isValidInput(safeOperatingHigh) && maxLimit <= safeOperatingHigh) {
                return "Maximum Limit must be greater than Safe Operating Limit - High.";
            }
        }
        // If all validations pass, return true
        return true;
    };

    // Handle input changes
    const handleMinChange = (props) => {
        const value = parseFloat(props.updatedValue); // Use parseFloat to handle decimals and negative values
        const primaryObjectElement = handleGetUpdatedPrimaryObject()?.[props.uniqueKey];  // Get the element instence properties.
        const message = handleValidateLimits({ minLimit: props.updatedValue, maxLimit, safeOperatingLow, safeOperatingHigh, bestBatchLow, bestBatchHigh, uniqueKey: props.uniqueKey })
        console.log('message:', message)
        if (message?.length) {
            primaryObjectElement?.errorSetterMethod(message);
            primaryObjectElement?.errorActivatorMethod(true);
        } else {
            setMinLimit(value);
        }
    };

    const handleMaxChange = (props) => {
        const value = parseFloat(props.updatedValue); // Use parseFloat to handle decimals and negative values
        const primaryObjectElement = handleGetUpdatedPrimaryObject()?.[props.uniqueKey];  // Get the element instence properties.
        const message = handleValidateLimits({ minLimit, maxLimit: props.updatedValue, safeOperatingLow, safeOperatingHigh, bestBatchLow, bestBatchHigh, uniqueKey: props.uniqueKey })
        if (message?.length) {
            primaryObjectElement?.errorSetterMethod(message);
            primaryObjectElement?.errorActivatorMethod(true);
        } else {
            setMaxLimit(value);
        }
    };

    const handleSafeOperatingLowChange = (props) => {
        const value = parseFloat(props.updatedValue); // Use parseFloat to handle decimals and negative values
        const primaryObjectElement = handleGetUpdatedPrimaryObject()?.[props.uniqueKey];  // Get the element instence properties.
        const message = handleValidateLimits({ minLimit, maxLimit, safeOperatingLow: props.updatedValue, safeOperatingHigh, bestBatchLow, bestBatchHigh, uniqueKey: props.uniqueKey })
        if (message?.length) {
            primaryObjectElement?.errorSetterMethod(message);
            primaryObjectElement?.errorActivatorMethod(true);
        } else {
            setSafeOperatingLow(value);
        }
    };

    const handleSafeOperatingHighChange = (props) => {
        const value = parseFloat(props.updatedValue); // Use parseFloat to handle decimals and negative values
        const primaryObjectElement = handleGetUpdatedPrimaryObject()?.[props.uniqueKey];  // Get the element instence properties.
        const message = handleValidateLimits({ minLimit, maxLimit, safeOperatingLow, safeOperatingHigh: props.updatedValue, bestBatchLow, bestBatchHigh, uniqueKey: props.uniqueKey })
        if (message?.length) {
            primaryObjectElement?.errorSetterMethod(message);
            primaryObjectElement?.errorActivatorMethod(true);
        } else {
            setSafeOperatingHigh(value);
        };
    }



    // best batch
    const handleBestBatchLowChange = (props) => {
        const value = parseFloat(props.updatedValue); // Use parseFloat to handle decimals and negative values
        const primaryObjectElement = handleGetUpdatedPrimaryObject()?.[props.uniqueKey];  // Get the element instence properties.
        const message = handleValidateLimits({ minLimit, maxLimit, safeOperatingLow, safeOperatingHigh, bestBatchLow: props.updatedValue, bestBatchHigh, uniqueKey: props.uniqueKey })
        if (message?.length) {
            primaryObjectElement?.errorSetterMethod(message);
            primaryObjectElement?.errorActivatorMethod(true);
        } else {
            setBestBatchLow(value)
        }
    };

    const handleBestBatchHighChange = (props) => {
        const value = parseFloat(props.updatedValue); // Use parseFloat to handle decimals and negative values
        const primaryObjectElement = handleGetUpdatedPrimaryObject()?.[props.uniqueKey];  // Get the element instence properties.
        const message = handleValidateLimits({ minLimit, maxLimit, safeOperatingLow, safeOperatingHigh, bestBatchLow, bestBatchHigh: props.updatedValue, uniqueKey: props.uniqueKey })
        if (message?.length) {
            primaryObjectElement?.errorSetterMethod(message);
            primaryObjectElement?.errorActivatorMethod(true);
        } else {
            setBestBatchHigh(value)
        }
    };


    // Function to handle changes in the threshold values
const handleChange = (props) => {
    // Update local state with the new value based on the unique key
    setLcValues(prev => ({ ...prev, [props.uniqueKey]: props.updatedValue }));
};

// Function to retrieve the current values of the threshold fields
const lcHandleGetValues = () => {
    // Call the provided handleGetValues function, if it exists, with the current threshold values
    handleGetValues && handleGetValues({
        uniqueKey,
        minLimit,
        maxLimit,
        safeOperatingLow,
        safeOperatingHigh,
        bestBatchLow,
        bestBatchHigh
    });
};

// Array of threshold fields configurations
const thresholdFields = [
    {
        uniqueKey: "minLimit", // Unique key for identifying the field
        handleGetValuesCheckbox: handleChange, // Function to handle checkbox changes
        label: "Minimum Limit", // Label for the input field
        value: minLimit, // Current value of the field
        disabled: true, // Whether the field is disabled
        handleGetValuesInput: handleMinChange, // Function to handle input changes
        isMandatory: true, // Whether the field is mandatory
        errorMessage: "Please enter some value.", // Error message for validation
        isChecked: true // Initial checked state for the checkbox
    },
    {
        uniqueKey: "safeOperatingLow",
        handleGetValuesCheckbox: handleChange,
        label: "Safe Operating Limit - Low",
        value: safeOperatingLow,
        disabled: lcValues?.safeOperatingLow, // Disable based on local values state
        handleGetValuesInput: handleSafeOperatingLowChange,
        isMandatory: lcValues?.safeOperatingLow ? true : false, // Determine if mandatory based on local state
        errorMessage: "Please enter some value.",
        isChecked: false
    },
    {
        uniqueKey: "bestBatchLow",
        handleGetValuesCheckbox: handleChange,
        label: "Best Batch Limit - Low",
        value: bestBatchLow,
        disabled: lcValues?.bestBatchLow,
        handleGetValuesInput: handleBestBatchLowChange,
        isMandatory: lcValues?.bestBatchLow ? true : false,
        errorMessage: "Please enter some value.",
        isChecked: false
    },
    {
        uniqueKey: "bestBatchHigh",
        handleGetValuesCheckbox: handleChange,
        label: "Best Batch Limit - High",
        value: bestBatchHigh,
        disabled: lcValues?.bestBatchHigh,
        handleGetValuesInput: handleBestBatchHighChange,
        isMandatory: lcValues?.bestBatchHigh ? true : false,
        errorMessage: "Please enter some value.",
        isChecked: false
    },
    {
        uniqueKey: "safeOperatingHigh",
        handleGetValuesCheckbox: handleChange,
        label: "Safe Operating Limit - High",
        value: safeOperatingHigh,
        disabled: lcValues?.safeOperatingHigh,
        handleGetValuesInput: handleSafeOperatingHighChange,
        isMandatory: lcValues?.safeOperatingHigh ? true : false,
        errorMessage: "Please enter some value.",
        isChecked: false
    },
    {
        uniqueKey: "maxLimit",
        handleGetValuesCheckbox: handleChange,
        label: "Maximum Limit",
        value: maxLimit,
        disabled: true, // This field is disabled
        handleGetValuesInput: handleMaxChange,
        isMandatory: true,
        errorMessage: "Please enter some value.",
        isChecked: true
    },
];

// Effect to reset the threshold values when the component is unmounted
useEffect(() => {
    return () => {
        // Reset all state values to their initial state
        setMinLimit(1);
        setMaxLimit(100);
        setSafeOperatingLow(null);
        setSafeOperatingHigh(null);
        setBestBatchLow(null);
        setBestBatchHigh(null);
        setLcValues({}); // Clear local values
    };
}, []);


    return (
        <div className={Styles.threshold_container}>

            <Scale
                minLimit={minLimit}
                maxLimit={maxLimit}
                safeOperatingLow={safeOperatingLow}
                safeOperatingHigh={safeOperatingHigh}
                bestBatchLow={bestBatchLow}
                bestBatchHigh={bestBatchHigh}
            />

            {/* Inputs */}
            < div className={Styles.inputs_container} >
                {
                    thresholdFields?.map(item => {
                        return <div key={item?.uniqueKey} className={Styles.input_container}>
                            <div className={Styles.info_and_controller_container}>
                                <ClonosRadioAndCheckbox
                                    isChecked={item?.isChecked ? true : null}
                                    uniqueKey={item?.uniqueKey}
                                    type="checkbox"
                                    labelPosition="right"
                                    handleGetValues={item?.handleGetValuesCheckbox}
                                />
                                <span className={`${Styles.label} ${item?.disabled ? Styles.active : Styles.disabled}`}>{item?.label}:</span>
                            </div>

                            <ClonosInput
                                containerStyle={{ width: "300px" }}
                                disabled={!item?.disabled}
                                value={item?.value}
                                uniqueKey={item?.uniqueKey}
                                type="number"
                                handleGetValues={props => {
                                    handlePrimaryObjectModifier({ uniqueKey: props?.uniqueKey, response: props?.updatedValue });
                                    item?.handleGetValuesInput(props)
                                }}
                                debounceDelay={2000}
                                isDebounce={true}
                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                isMandatory={item?.isMandatory}
                                errorMessage={item?.errorMessage}
                            />
                        </div>
                    })
                }
            </div >
            <footer className={Styles.footer}>
                <ClonosButton
                    style={{ color: "red", borderColor: "red" }}
                    onClick={handleClose}
                >Cancel</ClonosButton>

                <ClonosButton
                    variant="filled"
                    onClick={lcHandleGetValues}
                >Save</ClonosButton>

            </footer>
        </div >
    );
};

export default Threshold