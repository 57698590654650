import axios from "axios";
import { getToken } from "../utils/clonosCommon";
import configs from "../config";


export const axiosInstance = (contentType = "application/json") => {
  const token = getToken();
  if(contentType === "application/json") {
    const axiosClient = axios.create({
      baseURL: `${configs?.url}/api/v1`,
      headers: {
        Accept: "application/json",
        contentType:contentType,
        Authorization: "Bearer " + token,
      },
    })
    return axiosClient
  } else {
    const axiosClient = axios.create({
      baseURL: `${configs?.url}/api/v1`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return axiosClient
  }
 };

export const loginAxiosClient = axios.create({
  baseURL: `${configs.url}/api/v1`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
