import ClonosSpinner from "../ClonosSpinner/ClonosSpinner";
import "./Button.css"
import React, { useEffect, useRef, useState } from "react"
/**
 * ClonosButton component for rendering a custom button.
 * @param {Object} props - Component props.
 * @property {string} props.variant - Button variant ("hollow" or "solid").
 * @property {function} props.onClick - Callback function for button click event.
 * @property {string} props.border - Border style for the button.
 * @property {string} props.fontSize - Font size for the button text.
 * @property {number} tabIndex - tabIndex for input tag.
 * @property {Object} props.style - Additional styles for the button.
 * @property {boolean} props.loading - This is state will help to show the loader.
 * @property {boolean} props.isDisabled - This is state will help to disable the button.
 * @property {React.ReactNode} props.children - Content to be displayed inside the button.
 * @returns {React.Component} - ClonosButton component.
 */

export const ClonosButton = React.memo(({ variant = "outlined", onClick, isHollow, className, loading, isDisabled, tabIndex, children, style, ...props }) => {
    let buttonVariant = {
        outlined: { border: "1px solid #000", color: (loading || isDisabled) ? "#a0a0a0" : "#06337E", background: (loading || isDisabled) ? "#d3d3d3" : "white", border: (loading || isDisabled) && "none" },
        filled: { backgroundColor: (loading || isDisabled) ? "#d3d3d3" : "#06337E", color: (loading || isDisabled) ? "#a0a0a0" : "white", border: (loading || isDisabled) && "none" }
    };
    const buttonRef = useRef()

    const handleMouseDown = (event) => {
        const button = buttonRef.current;
        button?.classList.add('hold-mouse');

        const { clientX, clientY } = event; // Get click coordinates relative to viewport
        const buttonRect = button.getBoundingClientRect(); // Get button's position and size

        const relativeX = clientX - buttonRect.left; // Calculate click position within button
        const relativeY = clientY - buttonRect.top;

        const circle = document.createElement('div');
        circle.classList.add('circle', 'grow');
        circle.style.left = `${relativeX}px`;
        circle.style.top = `${relativeY}px`;

        button.parentNode.appendChild(circle);
    };


    const handleMouseUp = () => {
        const button = buttonRef.current;
        button.classList.remove('hold-mouse');

        setTimeout(() => {
            const circles = button.parentNode.querySelectorAll('.circle');
            circles.forEach(element => element.remove());
        }, 300);
    };


    return (
        <div className="main_container">
            <button
                ref={buttonRef}
                tabIndex={tabIndex}
                className={`${variant}_btn ${className || ''}`}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                    border: (loading || isDisabled) && "none",
                    ...variant == "filled" ? buttonVariant.filled : buttonVariant.outlined,
                    ...style,
                }}
                id="clonos_common_button"
                disabled={loading || isDisabled ? true : false}
                onClick={() => {
                    onClick();
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                {...props}
            >
                {children} {loading && <ClonosSpinner width={'20px'} color={variant === "filled" ? "#fff" : "#06337E"} spinnerThickness={"2px"} spinnerShadowColor={variant === "filled" ? "#fff" : "#06337E"} />}
            </button>
        </div>
    );
});
