import { modulesStaticData } from '../Constants/StaticData';

/**
 * Custom React hook to provide the module name based on the current URL location.
 * This hook helps to dynamically determine the module name based on the provided moduleName or the first part of the current URL path.
 * It checks if the provided moduleName is valid and if the current URL path matches any operations defined in the modulesStaticData data.
 * If no moduleName is provided, it attempts to find a matching module for the first part of the current URL path.
 * If no match is found, it logs a warning message to the console.
 *
 * @param {string} moduleName - The optional name of the module to search for.
 * @returns {string | undefined} - The module name if found, otherwise undefined.
 */
const useModuleNameProvider = (moduleName) => {
    /**
     * Function to handle finding the module name based on the provided moduleName or URL path.
     * @param {string} moduleName - The name of the module to search for.
     * @returns {string | undefined} - The module name if found, otherwise undefined.
     */
    const handleFindModuleName = (moduleName) => {
        // Get the current URL path and split it into parts
        let location = window.location.pathname.substring(1).split("/");

        // Check if a moduleName is provided
        if (moduleName) {
            // Check if the provided moduleName exists in the modulesStaticData data
            if (modulesStaticData?.[moduleName]) {
                const moduleDetails = modulesStaticData[moduleName];
                // Check if the current URL path matches any operations defined for the module
                if (moduleDetails?.oprations.includes(`/${location[0]}`)) {
                    return moduleDetails?.moduleName;
                } else {
                    // Log a warning message if the current URL path doesn't match any operations for the module
                    console.warn(`Provided children ${location[0]} is not part of ${moduleName} module`)
                }
            } else {
                // Log a warning message if the provided moduleName is invalid
                console.warn(`Please provide a valid module name. Available modules: '${Object.keys(modulesStaticData).join(", ")}'`);
            }
        } else {
            let found = false;
            // Iterate through each module in the modulesStaticData data
            for (const key in modulesStaticData) {
                const moduleDetails = modulesStaticData[key];
                // Check if the current URL path matches any operations defined for the module
                if (moduleDetails?.oprations.includes(`/${location[0]}`) && !found) {
                    found = true;
                    return moduleDetails?.moduleName;
                }
            }
            // Log a warning message if no match is found for the current URL path
            if (!found) {
                console.warn(`Provided children ${location[0]} is not part of any module.`);
            }
        }
    }

    // Call the function to find the module name based on the provided moduleName or URL path
    return handleFindModuleName(moduleName)
}

export default useModuleNameProvider;
