const Assets = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M20 7.5L12 3L4 7.5M20 7.5V16.5L12 21M20 7.5L12 12M12 21L4 16.5V7.5M12 21V12M4 7.5L12 12" stroke="#575757" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 7.5L12 3L4 7.5M20 7.5V16.5L12 21M20 7.5L12 12M12 21L4 16.5V7.5M12 21V12M4 7.5L12 12" stroke="black" strokeOpacity="0.2" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const ActiveAssets = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M20 7.5L12 3L4 7.5M20 7.5V16.5L12 21M20 7.5L12 12M12 21L4 16.5V7.5M12 21V12M4 7.5L12 12" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export {
    Assets,
    ActiveAssets
};