import { useDispatch, useSelector } from "react-redux";
import { Backdrop, Snackbar, makeStyles } from "@material-ui/core";
import { removeUserSession } from "./utils/clonosCommon";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import MuiAlert from "@material-ui/lab/Alert";
import Cookies from "js-cookie";
import { commonActions } from "./Store/Reducers/CommonReducer";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: 2000,
		color: "#fff",
	},
	snackbarStyle: {
		backgroundColor: "#eeee",
		fontSize: "18px",
		color: "#242424",
	},
	snackbarPos: {
		marginTop: "50px",
		zIndex: "3000"
	},
	crossError: {
		fontSize: '100px',
		color: "red"
	},
	errorBox: {
		width: "70%",
		height: "70vh",
		margin: "auto",
		background: "#FFF",
		color: "red",
		borderRadius: "12px",
		textAlign: "center",
		padding: "1em",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	erroText: {
		fontSize: "20px",
		color: "#3f51b5",
		marginTop: "30px",
		cursor: "default"
	}
}));

const Addons = () => {

	const navigate = useNavigate();
	const classes = useStyles();
	const showExpiryAlert = useSelector((state) => state?.commonData?.showExpiryAlert)
	const showLoader = useSelector((state) => state?.commonData?.showLoader)
	const tokenExpires = Cookies?.get("tokenExpires") ? new Date(JSON.parse(Cookies?.get("tokenExpires"))) : null
	const currentTime = new Date();
	const dispatch = useDispatch();
	const snackbarSelector = useSelector(
		(state) => state?.commonData?.showSnackbar
	);

	const handleCloseSnackbar = () => {
		dispatch(
			commonActions.handleSnackbar({
				show: false,
				message: "",
				type: "",
				verticalPosition: "right",
				toastType: "notification",
			})
		);
	};

	return <React.Fragment>
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			open={snackbarSelector?.show}
			className={classes.snackbarPos}
			autoHideDuration={snackbarSelector?.closeIn || 3000}
			onClose={handleCloseSnackbar}
		>
			<Alert
				onClose={handleCloseSnackbar}
				severity={snackbarSelector?.type === "success" ? "success" : snackbarSelector?.type === "warning" ? "warning" : "error"}
				className={snackbarSelector?.toastType && classes.snackbarStyle}
			>
				{snackbarSelector?.message}
			</Alert>
		</Snackbar>
		{
			showExpiryAlert ? <Backdrop className={classes.backdrop} open={showExpiryAlert}>
				<div className={classes.errorBox}>
					<div>
						<div style={{ textAlign: "center" }}>
							<CancelOutlinedIcon className={classes.crossError} />
						</div>
						<h1>{tokenExpires?.getTime() < currentTime.getTime() ? "Your session expired !" : "Found login from another device"}</h1>
						<div>
							<p className={classes.erroText}>Please <span style={{ color: "red", cursor: "pointer" }} onClick={() => {
								removeUserSession();
								localStorage.removeItem("loginUser")
								dispatch(commonActions.handleExpiryAlert(false));
								navigate("/")
							}}>Login</span> again to create new Session</p>
						</div>
					</div>
				</div>
			</Backdrop> : <></>
		}
		<Backdrop className={classes.backdrop} open={showLoader}>
			<CircularProgress color="inherit" />
		</Backdrop>
	</React.Fragment >
}

export default Addons;