import Styles from "./TemplatePreview.module.css";
import TEMPLATE_PREVIEW_ADDIMAGE from "../../../../../assets/UIUX/icons/Checklist/add-image.svg";
import TEMPLATE_PREVIEW_VIEWIMAGE from "../../../../../assets/UIUX/icons/Checklist/view-image.svg";
import TEMPLATE_PREVIEW_ADDNOTE from "../../../../../assets/UIUX/icons/Checklist/add-note.svg";
import TEMPLATE_PREVIEW_VIEWNOTE from "../../../../../assets/UIUX/icons/Checklist/view-note.svg";
import { useEffect, useState } from "react";
import ClonosSelect from "../../../../../components/CommonComponents/ClonosSelect/ClonosSelect";
import ClonosInput from "../../../../../components/CommonComponents/ClonosInput/ClonosInput";
import { CLONOS_GLOBAL_ACTION_FILL_ENTRY, CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_CHECKBOXES, CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE, CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DROPDOWN, CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE, CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_NUMBER, CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_TEXT } from "../../../../../Constants/Constant";
import { ClonosButton } from "../../../../../components/CommonComponents/Button/Button";
import { checkUserHasPermission } from "../../../../../utils/clonosCommon";
import useModuleNameProvider from "../../../../../CustomHooks/ModuleNameProviderHook";
import useFieldsChecker from "../../../../../CustomHooks/FieldsValidatorHook";



export const TemplatePreview = ({ attributes = [], isEdit, handleUseTemplate, handleGetUpdatedDataSets, loading }) => {
    console.log('attributesssss:', attributes)
    const [lcAttributes, setLcAttributes] = useState({});
    const [lcUpdatedAttributes, setLcUpdatedAttributes] = useState([]);
    const moduleName = useModuleNameProvider();
    const {
        handleGetErrorActivatorInformation,
        handleGetUpdatedPrimaryObject
    } = useFieldsChecker();


    console.log('lcAttributes:', lcAttributes)

    // dispatch(ChecklistAndReportsAction.setSelectedAttributesToolkitState(data));


    useEffect(() => {
        const newAttributesStructure = {};
        // const primaryObjectElements = handleGetUpdatedPrimaryObject()
        attributes?.forEach(attribute => {
            // console.log("htmlTags", primaryObjectElements[attribute?.id]?.getElementReferenceMethod()?.current);
            newAttributesStructure[attribute?.id] = attribute;
        });
        setLcAttributes(newAttributesStructure);
        setLcUpdatedAttributes(attributes)
    }, [attributes]);



    const waitForElementReference = (getElementReferenceMethod, retries = 10, delay = 100) => {
        return new Promise((resolve, reject) => {
            const attemptFetch = async (attempt) => {
                const elementReference = getElementReferenceMethod()?.current?.value;
                if (elementReference !== undefined || attempt >= retries) {
                    resolve(elementReference);
                } else {
                    setTimeout(() => attemptFetch(attempt + 1), delay);
                }
            };
            attemptFetch(0);
        });
    };

    const handleManipulateDataSetsStructure = async () => {
        const primaryObjectElements = handleGetUpdatedPrimaryObject();
        const attributesLocalData = [];
        for (const key in lcAttributes) {
            const attribute = lcAttributes[key];
            if (
                attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_TEXT ||
                attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE ||
                attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_NUMBER
            ) {
                if (!attribute?.fieldValue?.length) {
                    const previousFilledValues = await waitForElementReference(
                        primaryObjectElements[key]?.getElementReferenceMethod
                    );
                    attributesLocalData.push({ ...attribute, ["fieldValue"]: previousFilledValues });
                } else {
                    attributesLocalData.push(attribute);
                }
            } else {
                attributesLocalData.push(attribute);
            }
        }
        handleGetUpdatedDataSets(attributesLocalData);
    };


    const handleChange = ({ type, attribute, props, index }) => {
        console.log('props:', props)
        console.log('typeeeee:', type, attribute, props)
        const { updatedValue } = props;
        console.log('updatedValue:', updatedValue)
        const { optionValue } = attribute;
        console.log('optionValue:', optionValue)
        let targetedAttribute = { ...lcAttributes[attribute?.id] };
        console.log('targetedAttribute:', targetedAttribute)

        if (
            type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_TEXT ||
            type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_NUMBER ||
            type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE
        ) {
            targetedAttribute.fieldValue = updatedValue;
        }
        else if (type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE) {
            targetedAttribute.fieldValue = targetedAttribute?.fieldValue?.map(attribute => {
                if (attribute?.optionId === props?.target?.value) {
                    return { ...attribute, isActive: true };
                }
                else {
                    return { ...attribute, isActive: false };
                }
            });
        }
        else if (type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DROPDOWN) {
            targetedAttribute.fieldValue = targetedAttribute?.fieldValue?.map(attribute => {
                if (attribute?.optionId === updatedValue?.value) {
                    return { ...attribute, isActive: true }
                }
                else {
                    return { ...attribute, isActive: false }
                }
            });

        }
        else if (type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_CHECKBOXES) {
            console.log("isChecked", props?.target?.checked);
            targetedAttribute.fieldValue = targetedAttribute?.fieldValue?.map(attribute => {
                if (attribute?.optionId === props?.target?.value && props?.target?.checked) {
                    return { ...attribute, isActive: true };
                }
                else if (attribute?.optionId === props?.target?.value && props?.target?.checked === false) {
                    return { ...attribute, isActive: false };
                }
                return attribute;
            });

        }
        console.log('targetedAttribute:', targetedAttribute)
        setLcAttributes(prev => {
            const updatedState = { ...prev, [attribute?.id]: targetedAttribute };
            setLcUpdatedAttributes(Object.values(updatedState));
            return updatedState;
        });

    }

    return (
        <>
            <div className={Styles.ct_preview_container}>
                {lcUpdatedAttributes?.length || false ? (
                    lcUpdatedAttributes?.map((attribute, index) => {
                        return (
                            <div className={Styles.ct_preview_attribute_container}>
                                <div className={Styles.ct_preview_field_label_container}>
                                    <h4 className={Styles.ct_preview_field_label}>
                                        {attribute?.fieldName || "Untitled Field"}
                                    </h4>
                                </div>
                                <div className={Styles.ct_preview_attribute_value_contanier}>
                                    {
                                        (attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_TEXT || attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_NUMBER || attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE) &&
                                        <div className={Styles.ct_preview_attribute_value_left}>
                                            <ClonosInput
                                                limit={attribute?.type == CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_NUMBER ? 20 : 100}
                                                label={attribute?.fieldName || "Untitled Field"}
                                                uniqueKey={attribute?.id}
                                                // isLabel={true}
                                                disabled={!isEdit}
                                                // defaultValue={attribute?.fieldValue}
                                                value={attribute?.fieldValue ? attribute?.fieldValue : ""}
                                                handleGetValues={(props) => handleChange({ props, attribute, index, type: props?.type })}
                                                type={attribute?.type}
                                                handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                                            // isMandatory={isEdit ? true : false}
                                            />
                                        </div>
                                    }
                                    {
                                        attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DROPDOWN && <div className={Styles.ct_preview_attribute_value_left}>
                                            <ClonosSelect
                                                type="select"
                                                // isLabel={true}
                                                label={attribute?.fieldName || "Untitled Field"}
                                                uniqueKey="priority"
                                                placeholder="Select"
                                                defaultValue={attribute?.fieldValue?.filter(item => item?.isActive)?.[0]?.optionValue}
                                                disabled={isEdit ? false : true}
                                                options={attribute?.fieldValue?.map((type) => ({
                                                    value: type?.optionId,
                                                    label: type?.optionValue,
                                                    isNeeded: true,
                                                    disabled: isEdit ? false : true
                                                }))}
                                                handleGetValues={(props) => handleChange({ props, attribute, index, type: attribute?.type })}
                                            // isMandatory={isEdit ? true : false}
                                            />
                                        </div>
                                    }
                                    {
                                        attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE && <div className={Styles.ct_preview_attribute_value_left}>
                                            {
                                                attribute?.fieldValue?.map((opt) => {
                                                    return <div className={Styles.ct_preview_multichoice_options_container}>
                                                        {opt?.optionValue &&
                                                            <div className={Styles.ct_preview_attribute_option}>
                                                                <input
                                                                    value={opt?.optionId}
                                                                    onChange={(e) => handleChange({ props: e, attribute, index, type: attribute?.type })}
                                                                    disabled={!isEdit}
                                                                    defaultChecked={attribute?.choosedValue?.includes(opt?.optionValue)}
                                                                    name={`multichoice${index}`}
                                                                    type="radio"
                                                                    checked={opt?.isActive ? true : false}
                                                                />
                                                                <label>{opt?.optionValue}</label>
                                                            </div>
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        attribute?.type === CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_CHECKBOXES && <div className={Styles.ct_preview_attribute_value_left}>
                                            {
                                                attribute?.fieldValue?.map((opt) => {
                                                    return <div className={Styles.ct_preview_checkboxes_option}>
                                                        {opt?.optionValue && <div className={Styles.ct_preview_attribute_option}>
                                                            <input
                                                                value={opt?.optionId}
                                                                onChange={(e) => handleChange({ props: e, attribute, index, type: attribute?.type })}
                                                                disabled={!isEdit}
                                                                defaultChecked={attribute?.choosedValue?.includes(opt?.optionId)}
                                                                name="multichoice"
                                                                type="checkbox"
                                                                checked={opt?.isActive ? true : false}
                                                            />
                                                            <label>{opt?.optionValue}</label>
                                                        </div>}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div></div>
                )}
            </div>
            <footer className={Styles.ct_footer}>
                {
                    handleUseTemplate && <ClonosButton loading={loading} onClick={() => handleUseTemplate()} variant="filled">Use Template</ClonosButton>
                }
                {
                    handleGetUpdatedDataSets && <ClonosButton
                        loading={loading}
                        variant="filled"
                        onClick={() => handleManipulateDataSetsStructure()}
                    >{checkUserHasPermission({ moduleName, action: CLONOS_GLOBAL_ACTION_FILL_ENTRY }) ? "Submit" : "Update"}</ClonosButton>
                }
            </footer>
        </>
    );
};
