const UsersInActiveTabSVG = () => {
  return (
    <svg
      width="14"
      height="21"
      viewBox="0 0 14 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 19.3252V17.3252C1 16.2643 1.42143 15.2469 2.17157 14.4968C2.92172 13.7466 3.93913 13.3252 5 13.3252H9C10.0609 13.3252 11.0783 13.7466 11.8284 14.4968C12.5786 15.2469 13 16.2643 13 17.3252V19.3252M3 5.3252C3 6.38606 3.42143 7.40348 4.17157 8.15362C4.92172 8.90377 5.93913 9.3252 7 9.3252C8.06087 9.3252 9.07828 8.90377 9.82843 8.15362C10.5786 7.40348 11 6.38606 11 5.3252C11 4.26433 10.5786 3.24691 9.82843 2.49677C9.07828 1.74662 8.06087 1.3252 7 1.3252C5.93913 1.3252 4.92172 1.74662 4.17157 2.49677C3.42143 3.24691 3 4.26433 3 5.3252Z"
        stroke="#575757"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 19.3252V17.3252C1 16.2643 1.42143 15.2469 2.17157 14.4968C2.92172 13.7466 3.93913 13.3252 5 13.3252H9C10.0609 13.3252 11.0783 13.7466 11.8284 14.4968C12.5786 15.2469 13 16.2643 13 17.3252V19.3252M3 5.3252C3 6.38606 3.42143 7.40348 4.17157 8.15362C4.92172 8.90377 5.93913 9.3252 7 9.3252C8.06087 9.3252 9.07828 8.90377 9.82843 8.15362C10.5786 7.40348 11 6.38606 11 5.3252C11 4.26433 10.5786 3.24691 9.82843 2.49677C9.07828 1.74662 8.06087 1.3252 7 1.3252C5.93913 1.3252 4.92172 1.74662 4.17157 2.49677C3.42143 3.24691 3 4.26433 3 5.3252Z"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const UsersActiveTabSVG = () => {
  return (
    <svg
      width="14"
      height="21"
      viewBox="0 0 14 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 19.3252V17.3252C1 16.2643 1.42143 15.2469 2.17157 14.4968C2.92172 13.7466 3.93913 13.3252 5 13.3252H9C10.0609 13.3252 11.0783 13.7466 11.8284 14.4968C12.5786 15.2469 13 16.2643 13 17.3252V19.3252M3 5.3252C3 6.38606 3.42143 7.40348 4.17157 8.15362C4.92172 8.90377 5.93913 9.3252 7 9.3252C8.06087 9.3252 9.07828 8.90377 9.82843 8.15362C10.5786 7.40348 11 6.38606 11 5.3252C11 4.26433 10.5786 3.24691 9.82843 2.49677C9.07828 1.74662 8.06087 1.3252 7 1.3252C5.93913 1.3252 4.92172 1.74662 4.17157 2.49677C3.42143 3.24691 3 4.26433 3 5.3252Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const UsersListInActiveTabSVG = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 19V17C1.5 15.9391 1.92143 14.9217 2.67157 14.1716C3.42172 13.4214 4.43913 13 5.5 13H9.5C10.5609 13 11.5783 13.4214 12.3284 14.1716C13.0786 14.9217 13.5 15.9391 13.5 17V19M14.5 1.13C15.3604 1.35031 16.123 1.85071 16.6676 2.55232C17.2122 3.25392 17.5078 4.11683 17.5078 5.005C17.5078 5.89318 17.2122 6.75608 16.6676 7.45769C16.123 8.1593 15.3604 8.6597 14.5 8.88M19.5 19V17C19.4949 16.1172 19.1979 15.2608 18.6553 14.5644C18.1126 13.868 17.3548 13.3707 16.5 13.15M3.5 5C3.5 6.06087 3.92143 7.07828 4.67157 7.82843C5.42172 8.57857 6.43913 9 7.5 9C8.56087 9 9.57828 8.57857 10.3284 7.82843C11.0786 7.07828 11.5 6.06087 11.5 5C11.5 3.93913 11.0786 2.92172 10.3284 2.17157C9.57828 1.42143 8.56087 1 7.5 1C6.43913 1 5.42172 1.42143 4.67157 2.17157C3.92143 2.92172 3.5 3.93913 3.5 5Z"
        stroke="#1C1C1C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const UsersListActiveTabSVG = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 19V17C1.5 15.9391 1.92143 14.9217 2.67157 14.1716C3.42172 13.4214 4.43913 13 5.5 13H9.5C10.5609 13 11.5783 13.4214 12.3284 14.1716C13.0786 14.9217 13.5 15.9391 13.5 17V19M14.5 1.13C15.3604 1.35031 16.123 1.85071 16.6676 2.55232C17.2122 3.25392 17.5078 4.11683 17.5078 5.005C17.5078 5.89318 17.2122 6.75608 16.6676 7.45769C16.123 8.1593 15.3604 8.6597 14.5 8.88M19.5 19V17C19.4949 16.1172 19.1979 15.2608 18.6553 14.5644C18.1126 13.868 17.3548 13.3707 16.5 13.15M3.5 5C3.5 6.06087 3.92143 7.07828 4.67157 7.82843C5.42172 8.57857 6.43913 9 7.5 9C8.56087 9 9.57828 8.57857 10.3284 7.82843C11.0786 7.07828 11.5 6.06087 11.5 5C11.5 3.93913 11.0786 2.92172 10.3284 2.17157C9.57828 1.42143 8.56087 1 7.5 1C6.43913 1 5.42172 1.42143 4.67157 2.17157C3.92143 2.92172 3.5 3.93913 3.5 5Z"
        stroke="#06337E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PermissionsInActiveTabSVG = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 19.3242V17.3242C1 16.2634 1.42143 15.2459 2.17157 14.4958C2.92172 13.7456 3.93913 13.3242 5 13.3242H7M17 14.3242C17 18.3242 14.5 20.3242 13.5 20.3242C12.5 20.3242 10 18.3242 10 14.3242C11 14.3242 12.5 13.8242 13.5 12.8242C14.5 13.8242 16 14.3242 17 14.3242ZM3 5.32422C3 6.38508 3.42143 7.4025 4.17157 8.15265C4.92172 8.90279 5.93913 9.32422 7 9.32422C8.06087 9.32422 9.07828 8.90279 9.82843 8.15265C10.5786 7.4025 11 6.38508 11 5.32422C11 4.26335 10.5786 3.24594 9.82843 2.49579C9.07828 1.74565 8.06087 1.32422 7 1.32422C5.93913 1.32422 4.92172 1.74565 4.17157 2.49579C3.42143 3.24594 3 4.26335 3 5.32422Z"
        stroke="#575757"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 19.3242V17.3242C1 16.2634 1.42143 15.2459 2.17157 14.4958C2.92172 13.7456 3.93913 13.3242 5 13.3242H7M17 14.3242C17 18.3242 14.5 20.3242 13.5 20.3242C12.5 20.3242 10 18.3242 10 14.3242C11 14.3242 12.5 13.8242 13.5 12.8242C14.5 13.8242 16 14.3242 17 14.3242ZM3 5.32422C3 6.38508 3.42143 7.4025 4.17157 8.15265C4.92172 8.90279 5.93913 9.32422 7 9.32422C8.06087 9.32422 9.07828 8.90279 9.82843 8.15265C10.5786 7.4025 11 6.38508 11 5.32422C11 4.26335 10.5786 3.24594 9.82843 2.49579C9.07828 1.74565 8.06087 1.32422 7 1.32422C5.93913 1.32422 4.92172 1.74565 4.17157 2.49579C3.42143 3.24594 3 4.26335 3 5.32422Z"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PermissionsActiveTabSVG = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 19.3242V17.3242C1 16.2634 1.42143 15.2459 2.17157 14.4958C2.92172 13.7456 3.93913 13.3242 5 13.3242H7M17 14.3242C17 18.3242 14.5 20.3242 13.5 20.3242C12.5 20.3242 10 18.3242 10 14.3242C11 14.3242 12.5 13.8242 13.5 12.8242C14.5 13.8242 16 14.3242 17 14.3242ZM3 5.32422C3 6.38508 3.42143 7.4025 4.17157 8.15265C4.92172 8.90279 5.93913 9.32422 7 9.32422C8.06087 9.32422 9.07828 8.90279 9.82843 8.15265C10.5786 7.4025 11 6.38508 11 5.32422C11 4.26335 10.5786 3.24594 9.82843 2.49579C9.07828 1.74565 8.06087 1.32422 7 1.32422C5.93913 1.32422 4.92172 1.74565 4.17157 2.49579C3.42143 3.24594 3 4.26335 3 5.32422Z"
        stroke="#06337E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const AllUsersCountSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M33.3307 12.5L19.9974 5L6.66406 12.5M33.3307 12.5V27.5L19.9974 35M33.3307 12.5L19.9974 20M19.9974 35L6.66406 27.5V12.5M19.9974 35V20M6.66406 12.5L19.9974 20"
        stroke="#0A5FFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ActiveUsersCountSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M11.7708 19.9993L20.1042 28.3327L36.7708 11.666M3.4375 19.9993L11.7708 28.3327M20.1042 19.9993L28.4375 11.666"
        stroke="#60D468"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export {
  UsersListInActiveTabSVG,
  UsersListActiveTabSVG,
  AllUsersCountSVG,
  ActiveUsersCountSVG,
  UsersActiveTabSVG,
  UsersInActiveTabSVG,
  PermissionsActiveTabSVG,
  PermissionsInActiveTabSVG,
};
