import React, { useState } from 'react'
import { Card } from '@mui/material'
import defaultImportImage from "../assets/UIUX/images/import.png"
import ThreeDViewer from '../components/CommonComponents/ThreeDViewer/ThreeDViewer'
import Test from './Test'
import useModuleNameProvider from '../CustomHooks/ModuleNameProviderHook'
import TemplateCreation from '../Clonos_Modules/ChecklistAndReports/ClonosTemplate/TemplateCreation/TemplateCreation'
import Button from "./Test"
import Threshold from '../Clonos_Modules/LogAndReports/ClonosTemplate/Components/Threshold/Threshold'

const Index = () => {
    // const moduleName = useModuleNameProvider("rohan")
    const [file, setFile] = useState(null);

    const handleCheckUrl = (e) => {
        console.log('e:', e)
        setFile(e)
    }

    const handleGetEditedData = (props) => {
        console.log('props:', props)
    }
    return (
        <Card variant="outlined" style={{ flex: 1, margin: "1rem", borderRadius: "1rem", overflowY: "auto" }}>
            <Threshold />
        </Card>

    )
}

export default Index