import React from 'react'
import useToggler from '../../CustomHooks/TogglerHook';
import { Tooltip } from '@material-ui/core';

const PowerOff = ({ height, width, size, color, onClick, tooltipTitle = 'Power Off' }) => {
    let [isHover, setIsHover] = useToggler();

    return (
        <Tooltip title={tooltipTitle}>
            <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => onClick()}
                style={{ padding: "0px", margin: "0px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content", height: "fit-content" }}
            >
                <svg style={{ cursor: "pointer" }} onClick={onClick} width={size ? size : width ? width : "25"} height={size ? size : height ? height : "25"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 2V12.5H12.5V2H11Z" fill="black" />
                    <path d="M4.50054 12.8735C4.50018 11.534 4.85866 10.2188 5.53871 9.06467C6.21877 7.91058 7.19556 6.95974 8.36754 6.311L7.64004 5C5.90083 5.9631 4.52945 7.47496 3.74 9.29957C2.95055 11.1242 2.78746 13.1588 3.27619 15.0859C3.76491 17.0129 4.87795 18.724 6.44154 19.9518C8.00513 21.1796 9.93127 21.8552 11.9193 21.8731C13.9072 21.8909 15.8452 21.25 17.4306 20.0504C19.0159 18.8508 20.1595 17.16 20.6827 15.2421C21.2059 13.3241 21.0794 11.2868 20.3228 9.44836C19.5662 7.60988 18.2222 6.07365 16.5005 5.0795L15.749 6.377C16.8894 7.03503 17.8364 7.98164 18.4949 9.1217C19.1535 10.2617 19.5003 11.5551 19.5005 12.8716C19.5008 14.1882 19.1545 15.4817 18.4964 16.622C17.8383 17.7623 16.8916 18.7092 15.7515 19.3677C14.6114 20.0262 13.318 20.3729 12.0015 20.3731C10.6849 20.3732 9.39146 20.0268 8.2512 19.3686C7.11095 18.7105 6.16403 17.7637 5.50565 16.6236C4.84727 15.4834 4.50062 14.1901 4.50054 12.8735Z" fill={color ? color : "#1C1C1C"} />
                </svg>

            </div>
        </Tooltip>
    )
}

export default PowerOff