export const localConfigs = {


    url: "https://test.clonos.in:3333"
    // url: "https://test.clonos.in:3333"
    // dev alpha/
    // url: "http://20.204.85.50:3333"
    // url: "http://192.168.68.109:3333"

    // url: "http://20.204.179.207:3334"
    // url: "https://as-clonosv1-kaleesuwari-prod.azurewebsites.net"
};
