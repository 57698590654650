export const prodConfigs = {

    url: "https://test.clonos.in:3333"
    // url: "https://test.clonos.in:3333"
    // url: "http://20.207.204.144:3333"
    // url:"http://20.198.122.177"
    // url: "http://20.204.85.50:3333"
    // url: "http://192.168.1.103:3333"
    // url: "http://192.168.68.109:3333"

    // url: "http://192.168.19.126:3333"
    // url:"http://localhost:3333"

    // url: "https://bpcl-kci.clonos.in:3333"

    // ekta local 
    // url: "http://192.168.68.111:3333"
    // url: "http://192.168.68.115:3333"
    // mine local
    // url: "http://192.168.1.100:3334"

    // Dummy Testing Con.
    // url : "https://as-clonosv1-kaleesuwari-prod.azurewebsites.net"
    // url : "https://as-clonosv1-kaleesuwari-prod.azurewebsites.net"

}   
