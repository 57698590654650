import TEMPLTE_CREATION_TEXTICON from "../../../../../assets/UIUX/icons/Checklist/Attributes/alphabet-latin.svg"
import TEMPLTE_CREATION_NUMBERICON from "../../../../../assets/UIUX/icons/Checklist/Attributes/123.svg"
// import TEMPLTE_CREATION_DOTCIRCLE from "../../../../../assets/UIUX/icons/Checklist/Attributes/circle-dot (1).svg"
import TEMPLTE_CREATION_DOTCIRCLE from "../../../../../assets/UIUX/icons/Checklist/Attributes/123.svg"
import TEMPLTE_CREATION_SQUARECHECK from "../../../../../assets/UIUX/icons/Checklist/Attributes/square-check.svg";
import TEMPLTE_CREATION_CIRCLEDOWN from "../../../../../assets/UIUX/icons/Checklist/Attributes/circle-chevron-down.svg";
import TEMPLTE_CREATION_CALENDARICON from "../../../../../assets/UIUX/icons/Checklist/Attributes/calendar-event.svg";
import PLUSICON from "../../../../../assets/UIUX/icons/Checklist/white-plus.svg";
import EXPANDDOWN from "../../../../../assets/UIUX/icons/Checklist/caret-down.svg";
import Styles from "./TemplateAttributesImporter.module.css"
import useImagesLoaded from "../../../../../CustomHooks/ImageLoaderHook";
import ClonosSkeletonLoading from "../../../../../components/CommonComponents/ClonosSkeletonLoading/ClonosSkeletonLoading";
import { useState } from "react";
import { handleShowErrorAndWarning } from "../../../../../utils/clonosCommon";
import { CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_LIMIT, CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE } from "../../../../../Constants/Constant";
import { useDispatch } from "react-redux";


export const TemplateAttributesImporter = ({ getSelectedValue, ariaLabel, disabled }) => {
    const [showAttributes, setShowAttributes] = useState(false);
    const dispatch = useDispatch();

    const isAllImagesLoaded = useImagesLoaded([
        TEMPLTE_CREATION_TEXTICON,
        TEMPLTE_CREATION_DOTCIRCLE,
        TEMPLTE_CREATION_SQUARECHECK,
        TEMPLTE_CREATION_CIRCLEDOWN,
        TEMPLTE_CREATION_NUMBERICON,
        TEMPLTE_CREATION_CALENDARICON,
        PLUSICON,
        EXPANDDOWN
    ])


    console.log('isAllImagesLoaded:', isAllImagesLoaded)
    const attributes = [
        { id: 1, icon: TEMPLTE_CREATION_TEXTICON, title: "Text", type: "text" },
        { id: 2, icon: TEMPLTE_CREATION_DOTCIRCLE, title: "Multiple Choice", type: CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_MULTIPLE_CHOICE },
        { id: 3, icon: TEMPLTE_CREATION_SQUARECHECK, title: "Checkbox", type: "checkboxes" },
        { id: 4, icon: TEMPLTE_CREATION_CIRCLEDOWN, title: "Dropdown", type: "dropdown" },
        { id: 5, icon: TEMPLTE_CREATION_NUMBERICON, title: "Number", type: "number" },
        { id: 6, icon: TEMPLTE_CREATION_CALENDARICON, title: "Date", type: "date" },
    ];

    return (
        <div className={Styles.main_contianer}
        >
            <div aria-label={ariaLabel}
                style={disabled ? { backgroundColor: "#d3d3d3", cursor: "not-allowed", userSelect: "none" } : { backgroundColor: "#000", cursor: "pointer" }}
                onClick={() => {
                    if (disabled) {
                        handleShowErrorAndWarning({ dispatch, type: "warning", message: `You can only add up to ${CLONOS_GLOBAL_CHECKLIST_TEMPLATE_ATTRIBUTES_LIMIT} attributes.`, showTime: 5000 });
                    } else {
                        setShowAttributes(!showAttributes)
                    }
                }}
                className={Styles.add_task_btn_box}
            >
                {
                    isAllImagesLoaded ? <img src={PLUSICON} /> : <ClonosSkeletonLoading bgColor="#ffffff" width="20" height="20px" />
                }
                <p>Add Task</p>
                <span>
                    {
                        isAllImagesLoaded ? <img src={EXPANDDOWN} /> : <ClonosSkeletonLoading bgColor="#ffffff" width="20px" height="20px" />
                    }
                </span>
            </div>

            {
                showAttributes && <div className={Styles.popper_grow_container}>
                    <div
                        className={`${Styles.template_attribute_container} ${showAttributes ? Styles.popper_body_open : Styles.popper_body_close}`}
                        onClick={e => e.stopPropagation()}>
                        {
                            attributes.map((attr) => (
                                <div key={attr.id} className={Styles.attributeItem} onClick={() => {
                                    getSelectedValue({ attributeName: attr?.title, type: attr?.type });
                                    setShowAttributes(false);
                                }}>
                                    {
                                        !isAllImagesLoaded ? <ClonosSkeletonLoading height="25px" width="25px" /> :
                                            <img src={attr.icon} alt={attr.title} loading="lazy" />
                                    }
                                    <p>{attr.title}</p>
                                </div>
                            ))
                        }
                    </div>
                    {
                        showAttributes && <div onClick={() => setShowAttributes(false)} className={Styles.overLay}></div>
                    }
                </div>
            }

        </div>
    );
};