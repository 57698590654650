import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    objectsList:[],
    single3DObjectDetails:{}
}

const objectLibrarySlice = createSlice({
    name:"object3DLibraryData",
    initialState,
    reducers : {
        get3DLibraryToolkitState(state,action) {
            initialState.objectsList = action.payload
        }
    }
})

export const objectLibraryActions = objectLibrarySlice.actions;
export default  objectLibrarySlice.reducer