import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  unreadNotificationsCount:0
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationsToolkitState(state, actions) {
      console.log('actions:', actions)
      state.notifications = actions.payload
    },
    setUnreadNotificationsCountToolkitState(state,action) {
      state.unreadNotificationsCount = action.payload
    }
  },
});
export const notificationActions = notificationSlice.actions;
export default notificationSlice.reducer;
