import configs from "../config";
import { CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS,CLONOS_GLOBAL_APP_ENVIRONMENT, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_NOTFOUND_ERROR, CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS } from "../Constants/Constant";
import AppUrl from "./AppUrl";
import { doGetApiCall, doPostMultipartApiCall } from "./ClonosApi";
import { debounce, getToken, handleShowErrorAndWarning } from "./clonosCommon";



const autoSaveFieldsToLocalStorage = ({key,data ={},delay=500}) => {
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(key)) || {};
    const auxData = {...dataFromLocalStorage,...data};
    console.log(auxData,"auxData")
    localStorage.setItem(key,JSON.stringify(auxData))
}

export const debouncedSaveFildsToLocalStorage = debounce(autoSaveFieldsToLocalStorage, 500);


export const getAssetDropdownData = ({setterMethod,setIsLoading,pagination}) => {
    setIsLoading();
    doGetApiCall({url:AppUrl.getAssets,params:pagination})
    .then((res) => {
        if(res?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS){
            setterMethod(res?.data?.result)
        }
    })
    .catch(err => console.error(err))
    .finally(() => setIsLoading())
}


/**
 * Modifies the behavior of `console.log` to include a timestamp
 * when the environment is set to "test". It only affects logs
 * in the test environment and preserves the original `console.log`
 * functionality.
 *
 * @param {string} CLONOS_GLOBAL_APP_ENVIRONMENT - A global variable 
 * indicating the application's current environment. If it is set to 
 * "test", the `console.log` method will be modified.
 */
export const consoleModifier = () => {

  if(process.env.PRODUCTION === "PRODUCTION"){
      console.log = function(){}
  } else {
    const originalConsole = console.log;
        console.log = function (...args) {
            const timeStamp = new Date().toLocaleString();
            originalConsole.apply(console, [`[${timeStamp}]`, ...args]);
        }
  }
}


export const handleGetMultipleFiles = ({fileIds,setterMethod,setIsLoading}) => {
    setIsLoading()
    doGetApiCall({url:AppUrl.getMultipleFiles,params:{ids:fileIds?.join(",")}})
    .then((res) => {
        if(res?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            console.log(res?.data?.result,"imagess")
            setterMethod(res?.data?.result)
        }
    })
    .catch(err => console.error(err))
    .finally(() => setIsLoading())
}
// TO CHECH UNIQUENESS OF FIELD

/**
 * Function to check if a field value is unique in the specified module.
 * 
 * @param {Object} options - The options object.
 * @param {string} options.module - The module name where the check needs to be performed.e.g. assets or users ...
 * @param {string} options.fieldName - The field name to check for uniqueness.
 * @param {string} options.fieldValue - The field value to be checked.
 * 
 * @returns {Promise<string>} - A promise that resolves to either "unique" if the field value is unique or "not_unique" if it already exists.
 */
export const handleCheckUniqueFieldsMethod = async ({ module, fieldName, fieldValue }) => {
    try {
      const url = `${configs.url}/api/v1/${module}/${fieldValue}/?fetchByField=${fieldName}`
      const response = await fetch(url, {
        headers: {
          "contentType": "application/json",
          "Authorization": `Bearer ${getToken()}`
        }
      });
      if (response?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_NOTFOUND_ERROR) {
        return "unique"
      }
      if (response?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
        return "not_unique"
      }
    } catch (err) {
      console.error(err)
    }
  }



  export const handleValidateFiles = ({dispatch,limit,fileSizeInMB,acceptableFileTypes,files}) => {
    
    // check max limit of files
    
    if(files?.length > limit) {
      handleShowErrorAndWarning({ dispatch, type: "error", message: `Can not upload more than ${limit} files!`, showTime: 5000 });
      return false
    }


    // check file size

    const checkFileSize = ({files,fileSizeInMB}) => {
      files?.forEach((file) => {
        let sizeInBytes = fileSizeInMB * 1024 * 1024;
        if (file.size > sizeInBytes) {
            // Notify that the file size is greater (consider using a more user-friendly notification)
            handleShowErrorAndWarning({ dispatch, type: "error", message: `File size should be less than ${fileSizeInMB}!`, showTime: 5000 });
            return false; // Return false if a file size exceeds the limit
        }
      });
      return true; // Return true if all file sizes are within the limit
    }

    // check file type

    const checkIfFilesTypeIsValidOrNot = ({ files, acceptableFileTypes }) => {
      let count = 0;
      let fileNames = files.map(file => file.name);
      for (let i = 0; i < fileNames.length; i++) {
          let tempElement = fileNames[i].split(".");
          if (acceptableFileTypes.includes(`.${[tempElement[tempElement.length - 1]]}`)) count++;
      }
      if (count === fileNames.length) return true;
      else {
          handleShowErrorAndWarning({ dispatch, type: "error", message: "Please upload supported file types!", showTime: 5000 });
          return false;
      }
    }

    const filterUniqueFiles = ({files}) => {
      const fileNamesObj= {};
      let uniqueFiles = [];
      files?.forEach(file => {
        if(!fileNamesObj[file?.name]) {
          uniqueFiles.push(file);
          fileNamesObj[file?.name] = true;
        }
      })
      return uniqueFiles
    }

    // checkFileSize({files,fileSizeInMB});
    // checkIfFilesTypeIsValidOrNot({files,acceptableFileTypes});
    if(checkFileSize({files,fileSizeInMB}) && checkIfFilesTypeIsValidOrNot({files,acceptableFileTypes})){
      return filterUniqueFiles({files})
    } else {
      return
    }
  }


  export const handleUploadSingleFileAndGetId = async ({file,setIsLoading}) => {
    try {
      const formData = new FormData();
      formData.append("file",file)
      setIsLoading()
      const response = await doPostMultipartApiCall({url:AppUrl.uploadFiles,payload:formData})
      console.log(response?.data,"res from files")
      if(response?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_CREATE_SUCCESS) {
        return response?.data?.result
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading()
    }

  }