import { Tooltip } from "@mui/material";
import useToggler from "../../../CustomHooks/TogglerHook";
import { CLONOS_GLOBAL_COLOR_ICON_BLACK, CLONOS_GLOBAL_COLOUR_BLUE_HOVER } from "../../../Constants/Constant";

const BellInteractiveIcon = ({ height, width, size, color, onClick, tooltipTitle = 'Bell', strokeWidth, strokeColor, style }) => {
    let [isHover, setIsHover] = useToggler();
    return (
        <Tooltip title={tooltipTitle}>
            <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => onClick()}
                style={{ padding: "0px", margin: "0px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content", height: "fit-content", cursor: "pointer", ...style }}
            >

                <svg width={size ? size : width ? width : "24"} height={size ? size : height ? height : "25"} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="bell-ringing">
                        <path id="Vector" d="M12.0026 22.6667V24C12.0026 25.0609 12.424 26.0783 13.1742 26.8284C13.9243 27.5786 14.9417 28 16.0026 28C17.0635 28 18.0809 27.5786 18.831 26.8284C19.5812 26.0783 20.0026 25.0609 20.0026 24V22.6667M13.3359 6.66667C13.3359 5.95942 13.6169 5.28115 14.117 4.78105C14.6171 4.28095 15.2954 4 16.0026 4C16.7098 4 17.3881 4.28095 17.8882 4.78105C18.3883 5.28115 18.6693 5.95942 18.6693 6.66667C20.2005 7.3907 21.5058 8.51777 22.4453 9.92707C23.3849 11.3364 23.9232 12.9748 24.0026 14.6667V18.6667C24.1029 19.4956 24.3965 20.2894 24.8597 20.9842C25.3229 21.6789 25.9427 22.2552 26.6693 22.6667H5.33594C6.06252 22.2552 6.68235 21.6789 7.14552 20.9842C7.60869 20.2894 7.90226 19.4956 8.0026 18.6667V14.6667C8.08202 12.9748 8.62034 11.3364 9.55987 9.92707C10.4994 8.51777 11.8047 7.3907 13.3359 6.66667Z" stroke={isHover ? CLONOS_GLOBAL_COLOUR_BLUE_HOVER : strokeColor ? strokeColor : CLONOS_GLOBAL_COLOR_ICON_BLACK} stroke-width={strokeWidth ? strokeWidth : "2"} stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                </svg>

            </div>
        </Tooltip>
    )
}

export default BellInteractiveIcon