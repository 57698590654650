import React from 'react'
import useToggler from '../../CustomHooks/TogglerHook';
import { Tooltip } from '@material-ui/core';
import { CLONOS_GLOBAL_COLOR_ICON_BLACK, CLONOS_GLOBAL_COLOUR_BLUE, CLONOS_GLOBAL_COLOUR_BLUE_HOVER } from '../../Constants/Constant';

const Burger = ({ height, width, size, color, onClick, tooltipTitle = 'Burger', strokeWidth, strockColor }) => {
    let [isHover, setIsHover] = useToggler();

    return (
        <Tooltip title={tooltipTitle}>
            <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => onClick()}
                style={{ padding: "0px", margin: "0px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content", height: "fit-content" }}
            >
                <svg style={{ cursor: "pointer" }} onClick={onClick} width={size ? size : width ? width : "24"} height={size ? size : height ? height : "25"} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" fill={color ? color : "white"} />
                    <path d="M6 9H30M6 18H30M6 27H30" stroke={isHover ? strockColor ? strockColor : CLONOS_GLOBAL_COLOUR_BLUE_HOVER : CLONOS_GLOBAL_COLOR_ICON_BLACK} stroke-width={strokeWidth ? strokeWidth : "3"} stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </Tooltip>
    )
}

export default Burger