
import React from 'react'
import useToggler from '../../CustomHooks/TogglerHook';
import { Tooltip } from '@material-ui/core';
import { CLONOS_GLOBAL_COLOR_ICON_BLACK, CLONOS_GLOBAL_COLOUR_BLUE, CLONOS_GLOBAL_COLOUR_BLUE_HOVER, CLONOS_GLOBAL_MATE_BLACK } from '../../Constants/Constant';

const DatePicker = ({ height, width, size, color, onClick, isTooltip, tooltipTitle = 'Date Picker', strokeWidth, strokeColor, style }) => {
    let [isHover, setIsHover] = useToggler();
    return (
        isTooltip ? <Tooltip title={tooltipTitle}>
            <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => onClick()}
                style={{ padding: "0px", margin: "0px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content", height: "fit-content", cursor: "pointer", ...style }}
            >
                <svg width={size ? size : width ? width : "24"} height={size ? size : height ? height : "24"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="calendar-due">
                        <path id="Vector" d="M13.3359 2.5V5.83333M6.66927 2.5V5.83333M3.33594 9.16667H16.6693M3.33594 5.83333C3.33594 5.39131 3.51153 4.96738 3.82409 4.65482C4.13665 4.34226 4.56058 4.16667 5.0026 4.16667H15.0026C15.4446 4.16667 15.8686 4.34226 16.1811 4.65482C16.4937 4.96738 16.6693 5.39131 16.6693 5.83333V15.8333C16.6693 16.2754 16.4937 16.6993 16.1811 17.0118C15.8686 17.3244 15.4446 17.5 15.0026 17.5H5.0026C4.56058 17.5 4.13665 17.3244 3.82409 17.0118C3.51153 16.6993 3.33594 16.2754 3.33594 15.8333V5.83333ZM9.16927 13.3333C9.16927 13.5543 9.25707 13.7663 9.41335 13.9226C9.56963 14.0789 9.78159 14.1667 10.0026 14.1667C10.2236 14.1667 10.4356 14.0789 10.5919 13.9226C10.7481 13.7663 10.8359 13.5543 10.8359 13.3333C10.8359 13.1123 10.7481 12.9004 10.5919 12.7441C10.4356 12.5878 10.2236 12.5 10.0026 12.5C9.78159 12.5 9.56963 12.5878 9.41335 12.7441C9.25707 12.9004 9.16927 13.1123 9.16927 13.3333Z" stroke={isHover ? CLONOS_GLOBAL_COLOUR_BLUE_HOVER : strokeColor ? strokeColor : CLONOS_GLOBAL_MATE_BLACK} stroke-width={strokeWidth ? strokeWidth : "1.5"} stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                </svg>
            </div>
        </Tooltip> : <div
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => onClick()}
            style={{ padding: "0px", margin: "0px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content", height: "fit-content", cursor: "pointer", ...style }}
        >
            <svg width={size ? size : width ? width : "24"} height={size ? size : height ? height : "24"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="calendar-due">
                    <path id="Vector" d="M13.3359 2.5V5.83333M6.66927 2.5V5.83333M3.33594 9.16667H16.6693M3.33594 5.83333C3.33594 5.39131 3.51153 4.96738 3.82409 4.65482C4.13665 4.34226 4.56058 4.16667 5.0026 4.16667H15.0026C15.4446 4.16667 15.8686 4.34226 16.1811 4.65482C16.4937 4.96738 16.6693 5.39131 16.6693 5.83333V15.8333C16.6693 16.2754 16.4937 16.6993 16.1811 17.0118C15.8686 17.3244 15.4446 17.5 15.0026 17.5H5.0026C4.56058 17.5 4.13665 17.3244 3.82409 17.0118C3.51153 16.6993 3.33594 16.2754 3.33594 15.8333V5.83333ZM9.16927 13.3333C9.16927 13.5543 9.25707 13.7663 9.41335 13.9226C9.56963 14.0789 9.78159 14.1667 10.0026 14.1667C10.2236 14.1667 10.4356 14.0789 10.5919 13.9226C10.7481 13.7663 10.8359 13.5543 10.8359 13.3333C10.8359 13.1123 10.7481 12.9004 10.5919 12.7441C10.4356 12.5878 10.2236 12.5 10.0026 12.5C9.78159 12.5 9.56963 12.5878 9.41335 12.7441C9.25707 12.9004 9.16927 13.1123 9.16927 13.3333Z" stroke={isHover ? CLONOS_GLOBAL_COLOUR_BLUE_HOVER : strokeColor ? strokeColor : CLONOS_GLOBAL_MATE_BLACK} stroke-width={strokeWidth ? strokeWidth : "1.5"} stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </svg>
        </div>
    )
}

export default DatePicker