import React, { useState, useEffect, Suspense } from 'react'
import Styles from "./toast-notification.module.css"
import NotificationItem from '../toast-notification-item/ToastNotificationItem';
import { useSelector } from 'react-redux';

const ToastNotification = () => {
    const notifications = useSelector(store => store.notificationsData.notifications);
    console.log('notifications:', notifications)

    return (
        <div
            className={Styles.main_container}
        >
            <section className={Styles.notifications_container}>
                <div>
                    {
                        notifications?.map((notification) => {
                            return <NotificationItem notification={notification} />
                        })
                    }
                </div>
            </section>
        </div>
    );
};

export default ToastNotification;



