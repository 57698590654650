import React, { useEffect, useState } from 'react'
import Styles from "./ClonosRadio&Checkbox.module.css"



/**
 * ClonosRadioAndCheckbox component for rendering a custom input field.
 * @param {Object} props - Component props.
 * @property {string} type - Type of the input field.
 * @property {string} name - Name attribute for the input field.
 * @property {string} placeholder - Placeholder text for the input field.
 * @property {string} label - Label for the input field.
 * @property {boolean} isLabel - Flag to show/hide the label.
 * @property {Object} inputStyle - Additional styles for the input field.
 * @property {Object} labelStyle - Additional styles for the label.
 * @property {string} uniqueKey - Unique key for the component.
 * @property {string} defaultValue - Default value for the input field.
 * @property {boolean} isMandatory - Flag indicating whether the field is mandatory.
 * @property {function} handleGetValues - Callback function to handle value changes.
 * @property {function} labelPosition - labelPosition will take a string, that should be ("top" || "bottom" || "left" || "right") and based on that label will show in that side.
 * @property {string} areaLabel - Description of the area.
 * @property {function} handleGetErrorActivatorInformation - This function will handle all kind of error handling, that returns one object.
 * @property {function} handleGetErrorActivatorInformation.errorActivatorMethod  - This function will trigger or allow to show the error, it takes boolean value (true/false).
 * @property {function} handleGetErrorActivatorInformation.errorSetterMethod  - This function can set the error massage, it takes string.
 * @property {function} handleGetErrorActivatorInformation.type  - "type" represent that which kind of components is it like, "input, select, textarea"
 * @property {function} handleGetErrorActivatorInformation.uniqueKey  - "uniqueKey" will be be unique string that represent specific element.
 * @property {function} handleGetErrorActivatorInformation.value  - "value" Initially will be false that you need to change once that specific element has come value. and make it as true.
 * @returns {React.Component} - ClonosTextarea component.
 */
const ClonosRadioAndCheckbox = React.memo(({ type = "checkbox", uniqueKey, labelPosition = "right", isChecked, value, name, placeholder, disabled, label, errorMessage, isLabel, inputStyle, labelStyle, defaultValue, isMandatory = false, handleGetValues, areaLabel, onChange, limit, handleGetErrorActivatorInformation, ...props }) => {
    console.log('isChecked:', isChecked)
    const [lcValue, setLcValue] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [lcErrorMessage, setLcErrorMessage] = useState(errorMessage);
    const [positionStyles, setPositionStyles] = useState({});
    console.log('positionStyles:', positionStyles)


    const handleChange = (e) => {
        handleGetValues && handleGetValues({ type, uniqueKey, updatedValue: isChecked ? isChecked : e.target.checked, name });
    }

    useEffect(() => {
        isChecked && setLcValue(true);
        value && setLcValue(value);
        setLcErrorMessage(errorMessage || `Please fill ${label ? label?.toLowerCase() : "this"} field!`);
        handleGetErrorActivatorInformation && isMandatory && handleGetErrorActivatorInformation({ uniqueKey, value: false, type, errorActivatorMethod: setShowErrorMessage, errorSetterMethod: setLcErrorMessage });
    }, [isChecked]);

    useEffect(() => {
        switch (labelPosition) {
            case "top": {
                setPositionStyles({ flexDirection: "column" })
            }
                break;
            case "bottom": {
                setPositionStyles({ flexDirection: "column-reverse" })
            }
                break;
            case "left": {
                setPositionStyles({ flexDirection: "row", justifiyContent: "flex-end" })
            }
                break;
            case "right": {
                setPositionStyles({ flexDirection: "row-reverse", justifiyContent: "flex-start" })
            }
        }
    }, [labelPosition]);



    return (
        <div className={Styles.clonos_radio_and_checklist_container} aria-label={areaLabel} style={positionStyles}>
            {isLabel && <label style={{ ...labelStyle }}>{label} {isMandatory && <sup className={Styles.clonos_radio_and_checklist_isMandatory}>*</sup>}</label>}
            <div className={Styles.clonos_radio_and_checklist} >
                <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value ? value : lcValue}
                    defaultValue={defaultValue}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    style={{ ...inputStyle, }}
                    {...props}
                    checked={isChecked && isChecked}
                />
            </div>
            {showErrorMessage && !value && <span className={Styles.clonos_radio_and_checklist_error}>{lcErrorMessage ? lcErrorMessage : `Please Fill ${label} Field!`}</span>}
        </div>
    )
})

export default ClonosRadioAndCheckbox