import { useEffect, useRef } from "react";

const useFunctionDebounce = (callback, delay) => {
    const debounceRef = useRef(null);

    const debouncedFunction = (...args) => {
        // Clear the previous timeout if it's still active
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        // Set a new timeout to call the function after the delay
        debounceRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };

    // Cleanup timeout on component unmount
    useEffect(() => {
        return () => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
        };
    }, []);

    return debouncedFunction;
};

export default useFunctionDebounce;
