import Modal from "../../../../../components/CommonComponents/Modal/Modal"
import Styles from "./SaveAsTemplate.module.css"
import CloseIcon from "../../../../../assets/Clonos Common Indicators/Icon_Components/CloseIcon"
import { ClonosButton } from "../../../../../components/CommonComponents/Button/Button"
import { useSelector } from "react-redux"
import { handleSaveChecklistDataSetsMethod } from "../../../../../utils/ChecklistAndReportsMethods/ChecklistMethods"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import ClonosInput from "../../../../../components/CommonComponents/ClonosInput/ClonosInput"
import { globalHandleIsAllRequiredFieldAvailable, handleShowErrorAndWarning } from "../../../../../utils/clonosCommon"
import useFieldsChecker from "../../../../../CustomHooks/FieldsValidatorHook"


export const SaveAsTemplate = ({ open, closeMethod, responseSetterMethod, loading, refreshApi, primaryObject }) => {
    const { checklistTemplateAttributes, checklistGeneralDetailsCreationDetails, templateInfo } = useSelector(
        (store) => store.checklistTemplateData
    );
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const [lcValues, setLcValues] = useState({ "templateName": checklistGeneralDetailsCreationDetails?.name, isDuplicateTemplate: false, templateNameErrorHandling: {} })
    const {
        handleSubmit,
    } = useFieldsChecker();

    const handleSaveChecklistAsTemplate = () => {
        if (lcValues?.templateNameErrorHandling?.value === false) {
            lcValues?.templateNameErrorHandling?.errorActivatorMethod(true);
            return;
        };
        if (!handleSubmit()) {
            handleShowErrorAndWarning({ dispatch, type: "error", message: "Please fill all the fields of attributes" });
            closeMethod(false);
            return;
        };
        handleSaveChecklistDataSetsMethod({
            checklistId: checklistGeneralDetailsCreationDetails?.id,
            payload: {
                images: [],
                note: templateInfo?.noteDetails ? templateInfo?.noteDetails : "",
                dataSets: checklistTemplateAttributes.map((item, index) => ({ ...item, index: index + 1, asset: item?.asset?.id || item?.asset?.assetId })),
                name: lcValues?.templateName
            },
            dispatch,
            Navigate,
            isGeneralTemplate: true,
            responseSetterMethod,
            refreshApi,
            isDuplicateTemplateSetterMethod: { setLcValues, closeMethod }
        });
    }

    return (
        <Modal isOpen={open} closeModalMethod={closeMethod}>
            <div className={Styles.sct_subcontainer}>
                <div className={Styles.sct_header}>
                    <h4>Save As Template</h4>
                    <div><CloseIcon onClick={() => closeMethod(false)} /></div>
                </div>
                <div className={Styles.sct_content_container}>
                    <div className={Styles.sct_field_container}>

                        <ClonosInput
                            label="Template Name"
                            isLabel={true}
                            uniqueKey="templateName"
                            handleGetValues={(props) => {
                                setLcValues(prev => {
                                    let data = null
                                    if (props?.updatedValue === "" || props?.updatedValue === null) {
                                        data = { ...prev, [props?.uniqueKey]: props?.updatedValue, ["templateNameErrorHandling"]: { ...prev["templateNameErrorHandling"], ["value"]: false } }
                                    } else {
                                        data = { ...prev, [props?.uniqueKey]: props?.updatedValue, ["templateNameErrorHandling"]: { ...prev["templateNameErrorHandling"], ["value"]: true } }
                                    };
                                    return data;
                                })
                            }}
                            defaultValue={checklistGeneralDetailsCreationDetails?.name}
                            isMandatory={true}
                            handleGetErrorActivatorInformation={(props) => setLcValues(prev => {
                                return { ...prev, ["templateNameErrorHandling"]: props }
                            })}
                        />

                    </div>
                </div>
                <div className={Styles.cst_btn_controller}>
                    <div>
                        <ClonosButton style={{ color: "#06337E" }} onClick={() => closeMethod(false)}>Cancel</ClonosButton>
                        <ClonosButton
                            loading={loading}
                            isDisabled={!lcValues?.templateName?.length > 0}
                            variant="filled" onClick={() => {
                                handleSaveChecklistAsTemplate()
                            }}>Save</ClonosButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

