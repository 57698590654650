import { axiosInstance } from "../Api/AxioClient";
import { handleLoginExpiry, handleShowErrorAndWarning } from "./clonosCommon";
import { store } from "../Store/Store";

const axiosForJSON = (contentType) => axiosInstance(contentType || "application/json");
const axiosForMultipart = () => axiosInstance("multipart/form-data");
const dispatch = store.dispatch;

const successHandling = res => {
    if (res?.status && res.status >= 200 && res.status <= 299)
        return res;
    else {
        handleShowErrorAndWarning({ dispatch, type: "error", message: `Something went wrong!.`, showTime: 5000 })
        return undefined;
    }
}

const errorHandling = err => {
    if (err?.response?.data?.status === 401)
        handleLoginExpiry(err, dispatch)
    else
        console.log(err,"err from patch")
        handleShowErrorAndWarning({ dispatch, type: "error", message: err?.response?.data?.message ? err?.response?.data?.message : `Something went wrong!.`, showTime: 5000 });
    return err
}

// const doGetApiCall = (url, params = {}) => axiosForJSON().get(url, { params }).then(successHandling).catch(errorHandling)
/**
 * Makes a GET API call with the given URL and query parameters.
 *
 * @param {Object} config - Configuration object for the API call.
 * @param {string} config.url - The endpoint URL to which the GET request is sent.
 * @param {Object} [config.params={}] - The query parameters to be included in the URL. Default is an empty object.
 *
 * @returns {Promise} - A promise that resolves with the response of the successful API call, or rejects with the error if the API call fails.
 *
 * @example
 * const config = {
 *     url: 'https://example.com/api/resource',
 *     params: { param1: 'value1', param2: 'value2' }
 * };
 *
 * doGetApiCall(config)
 *     .then(response => {
 *         console.log('API call successful:', response.data);
 *     })
 *     .catch(error => {
 *         console.error('API call failed:', error);
 *     });
 */
const doGetApiCall = ({ url, params = {} }) =>
    axiosForJSON().get(url, { params })
        .then(successHandling)
        .catch(errorHandling);


// const doPostApiCall = (url, payload = {}, contentType, queryParams) => axiosForJSON(contentType).post(url, payload, { params: queryParams }).then(successHandling).catch(errorHandling)
/**
 * Makes a POST API call with the given URL, payload, content type, and query parameters.
 *
 * @param {Object} config - Configuration object for the API call.
 * @param {string} config.url - The endpoint URL to which the POST request is sent.
 * @param {Object} [config.payload={}] - The data to be sent in the body of the POST request. Default is an empty object.
 * @param {string} config.contentType - The content type of the request, e.g., 'application/json'.
 * @param {Object} [config.queryParams] - The query parameters to be included in the URL. Optional.
 *
 * @returns {Promise} - A promise that resolves with the response of the successful API call, or rejects with the error if the API call fails.
 *
 * @example
 * const config = {
 *     url: 'https://example.com/api/resource',
 *     payload: { key1: 'value1', key2: 'value2' },
 *     contentType: 'application/json',
 *     queryParams: { param1: 'value1' }
 * };
 *
 * doPostApiCall(config)
 *     .then(response => {
 *         console.log('API call successful:', response.data);
 *     })
 *     .catch(error => {
 *         console.error('API call failed:', error);
 *     });
 */
const doPostApiCall = ({url, payload = {}, contentType, queryParams}) =>
    axiosForJSON(contentType).post(url, payload, { params: queryParams })
        .then(successHandling)
        .catch(errorHandling);

// const doPutApiCall = (url, payload = {}) => axiosForJSON().put(url, payload).then(successHandling).catch(errorHandling)
/**
 * Makes a PUT API call with the given URL and payload.
 *
 * @param {Object} config - Configuration object for the API call.
 * @param {string} config.url - The endpoint URL to which the PUT request is sent.
 * @param {Object} [config.payload={}] - The data to be sent in the body of the PUT request. Default is an empty object.
 *
 * @returns {Promise} - A promise that resolves with the response of the successful API call, or rejects with the error if the API call fails.
 *
 * @example
 * const config = {
 *     url: 'https://example.com/api/resource',
 *     payload: { key1: 'value1', key2: 'value2' }
 * };
 *
 * doPutApiCall(config)
 *     .then(response => {
 *         console.log('API call successful:', response.data);
 *     })
 *     .catch(error => {
 *         console.error('API call failed:', error);
 *     });
 */
const doPutApiCall = ({url, payload = {}}) =>
    axiosForJSON().put(url, payload)
        .then(successHandling)
        .catch(errorHandling);


// const doPatchApiCall = (url, payload = {}) => axiosForJSON().patch(url, payload).then(successHandling).catch(errorHandling)
/**
 * Makes a PATCH API call with the given URL and payload.
 *
 * @param {Object} config - Configuration object for the API call.
 * @param {string} config.url - The endpoint URL to which the PATCH request is sent.
 * @param {Object} [config.payload={}] - The data to be sent in the body of the PATCH request. Default is an empty object.
 *
 * @returns {Promise} - A promise that resolves with the response of the successful API call, or rejects with the error if the API call fails.
 *
 * @example
 * const config = {
 *     url: 'https://example.com/api/resource',
 *     payload: { key1: 'value1', key2: 'value2' }
 * };
 *
 * doPatchApiCall(config)
 *     .then(response => {
 *         console.log('API call successful:', response.data);
 *     })
 *     .catch(error => {
 *         console.error('API call failed:', error);
 *     });
 */
const doPatchApiCall = ({url, payload = {}}) =>
    axiosForJSON().patch(url, payload)
        .then(successHandling)
        .catch(errorHandling);


// const doPostMultipartApiCall = (url, params = {}) => axiosForMultipart().post(url, params).then(successHandling).catch(errorHandling)
/**
 * Makes a POST API call with multipart/form-data content type, given the URL and parameters.
 *
 * @param {Object} config - Configuration object for the API call.
 * @param {string} config.url - The endpoint URL to which the POST request is sent.
 * @param {Object} [config.payload={}] - The parameters to be sent in the body of the POST request as multipart/form-data. Default is an empty object.
 *
 * @returns {Promise} - A promise that resolves with the response of the successful API call, or rejects with the error if the API call fails.
 *
 * @example
 * const config = {
 *     url: 'https://example.com/api/upload',
 *     params: { file: myFile, description: 'File upload' }
 * };
 *
 * doPostMultipartApiCall(config)
 *     .then(response => {
 *         console.log('API call successful:', response.data);
 *     })
 *     .catch(error => {
 *         console.error('API call failed:', error);
 *     });
 */
const doPostMultipartApiCall = ({url, payload = {}}) =>
    axiosForMultipart().post(url, payload)
        .then(successHandling)
        .catch(errorHandling);


// const doPutMultipartApiCall = (url, params = {}) => axiosForMultipart().put(url, params).then(successHandling).catch(errorHandling)
/**
 * Makes a PUT API call with multipart/form-data content type, given the URL and parameters.
 *
 * @param {Object} config - Configuration object for the API call.
 * @param {string} config.url - The endpoint URL to which the PUT request is sent.
 * @param {Object} [config.params={}] - The parameters to be sent in the body of the PUT request as multipart/form-data. Default is an empty object.
 *
 * @returns {Promise} - A promise that resolves with the response of the successful API call, or rejects with the error if the API call fails.
 *
 * @example
 * const config = {
 *     url: 'https://example.com/api/upload',
 *     params: { file: myFile, description: 'File upload' }
 * };
 *
 * doPutMultipartApiCall(config)
 *     .then(response => {
 *         console.log('API call successful:', response.data);
 *     })
 *     .catch(error => {
 *         console.error('API call failed:', error);
 *     });
 */
const doPutMultipartApiCall = ({url, params = {}}) =>
    axiosForMultipart().put(url, params)
        .then(successHandling)
        .catch(errorHandling);



const doDeleteApiCall = ({url,payload = {}}) => 
    axiosForJSON().delete(url,{data:payload})
        .then(successHandling)
        .catch(errorHandling);
                
export {
    doGetApiCall,
    doPostApiCall,
    doPutApiCall,
    doPatchApiCall,
    doPostMultipartApiCall,
    doPutMultipartApiCall,
    doDeleteApiCall
}