import React, { useEffect, useState } from 'react'
import { globalHandleIsAllRequiredFieldAvailable } from '../utils/clonosCommon';


// Global object to store field values and their status
let primaryObject = {};
let activeHookInstances = {};

/**
 * Custom React hook to manage field validations and submission state.
 * @param {boolean} isIndexKey - Indicates whether the unique key is indexed, Note: Key name should be end up with "_index".
 * @param {string} hookUniqueKey - It will help to find the instences of all the children hooks.
 * @returns {Object} Object containing state and functions for field validation and submission.
 *          - isClear: Indicates whether all required fields have values.
 *          - primaryObject: Contains all the field states and control functions.
 *          - handleGetErrorActivatorInformation: Returns local error handler states of custom components that support error handling.
 *          - handlePrimaryObjectModifier: Modifies the primary object to reflect whether the local state of the custom component has a value.
 *          - handleSubmit: To be called upon form submission. Checks the 'isClear' property; if true, it means all fields contain values.
 *          - handleRemoveField: Removes specified keys from the primaryObject. Pass an array of keys to remove. Example: ['key1', 'key2'].
 */
const useFieldsChecker = (props) => {
    const { isIndexKey = false, hookUniqueKey, isChildren = false } = props || {}
    const [isClear, setIsClear] = useState(false);




    /**
 * Modifies the primaryObject based on the provided response.
 * @param {Object} props - Object containing response details.
 * @param {string} props.uniqueKey - Unique key for the field.
 * @param {boolean|null} props.response - Response status for the field.
 * @param {string|null} props.indexUniqueKey - Unique key for indexed fields.
 * @returns {boolean} - Indicates whether the field value is set.
 */
    const handlePrimaryObjectModifier = ({ uniqueKey, response, indexUniqueKey }) => {
        const lcUniqueKey = isIndexKey ? indexUniqueKey : uniqueKey
        if (!primaryObject[lcUniqueKey]) return;
        if (response === null || response === "") {
            primaryObject = { ...primaryObject, [lcUniqueKey]: { ...primaryObject[lcUniqueKey], "value": false } }
            return false
        }
        else {
            primaryObject = { ...primaryObject, [lcUniqueKey]: { ...primaryObject[lcUniqueKey], "value": true } }
            return true
        }
    };


    /**
 * Stores error activator information in the primaryObject.
 * @param {Object} props - Object containing error activator details.
 * @param {string} props.uniqueKey - Unique key for the field.
 * @param {string|null} props.indexUniqueKey - Unique key for indexed fields.
 */
    const handleGetErrorActivatorInformation = (props) => {
        const { uniqueKey, indexUniqueKey } = props;
        const lcUniqueKey = isIndexKey ? indexUniqueKey : uniqueKey
        primaryObject = { ...primaryObject, [lcUniqueKey]: props }
    };


    /**
 * Handles form submission and checks if all required fields are available.
 */
    const handleSubmit = () => {
        const responseCheck = globalHandleIsAllRequiredFieldAvailable({ primaryObject })
        setIsClear(responseCheck);
        return responseCheck
    };


    /**
     * Removes specified fields from the primaryObject and reindexes indexed keys if necessary.
     * @param {string[]} keys - Array of keys to be removed.
     */
    const handleRemoveField = (keys) => {
        // Remove specified keys from the primaryObject
        for (const key of keys) {
            delete primaryObject[key];
        }

        // Reindex keys for indexed fields if isIndexKey is true
        if (isIndexKey) {
            // Segregate keys based on common indexes
            let segregatedKeys = {};
            for (let key in primaryObject) {
                const lcKey = key.split("_");
                // Group keys by their common indexes
                segregatedKeys[lcKey[1]] ? segregatedKeys[lcKey[1]].push(key) : segregatedKeys[lcKey[1]] = [key];
            }
            console.log('segregatedKeys:', segregatedKeys)

            // Reindex keys for each group of common indexes
            Object.keys(segregatedKeys).forEach((item, index) => {
                if (item !== index) {
                    const selectedKeyValues = segregatedKeys[item];
                    selectedKeyValues.forEach((lcItem) => {
                        // Generate the new key with reindexed index
                        const key = lcItem.split("_")[0] + "_" + index;
                        // Update primaryObject with reindexed key
                        primaryObject[key] = primaryObject[lcItem];
                    })
                }
            });
        }

        // Check if primaryObject is empty and update isClear state accordingly
        if (Object.keys(primaryObject)?.length === 0) {
            setIsClear(false);
        }
    };

    const handleGetUpdatedPrimaryObject = () => {
        return primaryObject
    }


    useEffect(() => {
        activeHookInstances[hookUniqueKey] = {
            handleSubmit,
        }
        return () => {
            if (!isChildren) {
                primaryObject = {};
            };
        }

    }, []);

    useEffect(() => {
        console.log("primaryObject:", primaryObject)
    }, [primaryObject]);

    return {
        isClear,
        primaryObject,
        handleGetErrorActivatorInformation,
        handlePrimaryObjectModifier,
        handleSubmit,
        handleRemoveField,
        activeHookInstances,
        handleGetUpdatedPrimaryObject
    }
}

export default useFieldsChecker