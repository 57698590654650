import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mainLayoutChildrenPositionsToolkitState: {},
    globalNavigationBarPathsToolkitState: []
};

const globalEntitiesSlice = createSlice({
    name: "globalEntitiesStateManagement",
    initialState,
    reducers: {
        setMainLayoutChildrenPosition(state, action) {
            state.mainLayoutChildrenPositionsToolkitState = action.payload;
        },
        setGlobalNavigationBarPaths(state, action) {
            state.globalNavigationBarPathsToolkitState = action.payload;
        }
    },
});
export const globalEntitiesActions = globalEntitiesSlice.actions;
export default globalEntitiesSlice.reducer;
