import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logTemplateAttributes: [],
    logGeneralDetailsCreationDetails: {},
    useTemplateInformation: { useTemplate: false, templateId: null },
    templateInfo: {},
}

const LogsAndReportsSlice = createSlice({
    name: "ChecklistTemplate",
    initialState,
    reducers: {
        setSelectedAttributesToolkitState(state, action) {
            console.log("actionPayload", action.payload)
            state.logTemplateAttributes = action.payload;
        },
        setLogGeneralDetailsCreationDetails(state, action) {
            state.logGeneralDetailsCreationDetails = action.payload;
        },
        setUseTemplateInformation(state, action) {
            state.useTemplateInformation = action.payload;
        },
        setTemplateInfoToolkitState(state, action) {
            state.templateInfo = action.payload;
        },
    },
});

export const LogsAndReportsAction =
    LogsAndReportsSlice.actions;

export default LogsAndReportsSlice.reducer;