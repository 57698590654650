import { useEffect, useState } from "react";

export const useNetworkStatusHook = () => {
    const [isOffline,setIsOffline] = useState(!navigator.onLine)

    useEffect(() => {
        const handleOnline = () => {
            setIsOffline(false);
            window.location.reload();
        }
        const handleOffline = () => setIsOffline(true);

        window.addEventListener("online",handleOnline)
        window.addEventListener("offline",handleOffline);

        return () => {
            window.removeEventListener("online",handleOnline);
            window.removeEventListener("offline",handleOffline)
        }
    },[navigator.onLine])

    return isOffline
}