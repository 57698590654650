import React, { useState } from 'react'



/**
 * Custom hook for managing multiple pagination states.
 * @param {Object} initialPaginations - The initial pagination states.
 * @returns {Object} - The pagination states and functions to update them.
 */
const useMultiPagination = (initialPaginations = {}) => {
    const [paginations, setPaginations] = useState(initialPaginations);



    /**
  * Set the page and limit for a specific pagination key.
  * @param {string} key - The key identifying the pagination state.
  * @param {number} [page] - The new page number (optional).
  * @param {number} [limit] - The new limit (optional).
  */
    const handleSetPagination = ({ key, page, limit }) => {
        setPaginations((prevPaginations) => ({
            ...prevPaginations,
            [key]: {
                ...prevPaginations[key],
                page: page !== undefined ? page : prevPaginations[key].page,
                limit: limit !== undefined ? limit : prevPaginations[key].limit,
            },
        }));
    };



    /**
 * Set the page number for a specific pagination key.
 * @param {string} key - The key identifying the pagination state.
 * @param {number} page - The new page number.
 */
    const handleSetPage = ({ key, page }) => {
        setPaginations((prevPaginations) => ({
            ...prevPaginations,
            [key]: {
                ...prevPaginations[key],
                page,
            },
        }));
    };



    /**
 * Set the limit for a specific pagination key.
 * @param {string} key - The key identifying the pagination state.
 * @param {number} limit - The new limit.
 */
    const handleSetLimit = ({ key, limit }) => {
        setPaginations((prevPaginations) => ({
            ...prevPaginations,
            [key]: {
                ...prevPaginations[key],
                limit,
            },
        }));
    };

    return {
        paginations,
        handleSetPagination,
        handleSetPage,
        handleSetLimit,
    };
};

export default useMultiPagination