import Modal from "../Modal/Modal";
import Styles from "./ImagePreviewModal.module.css";
import REDTRASH from "../../../assets/UIUX/icons/redTrash.svg";
import XCIRCLE from "../../../assets/UIUX/icons/x-circle-fill.svg";
import CIRCLELEFT from "../../../assets/UIUX/icons/circle-chevron-left.svg";
import CIRCLERIGHT from "../../../assets/UIUX/icons/circle-chevron-right.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ClonosSpinner from "../ClonosSpinner/ClonosSpinner";import ClonosSkeletonLoading from "../ClonosSkeletonLoading/ClonosSkeletonLoading"


export const ImagePreviewModal = ({
  activeImageIndex = 0,
  loading,
  open,
  closeModalMethod,
  images,
  deleteMethod,
  uploadedBy,
}) => {
  console.log("images:", images);
  const [activeImage, setActiveImage] = useState(activeImageIndex);

  const handleMoveLeft = () => {
    if (activeImage > 0) {
      setActiveImage((prev) => prev - 1);
    }
  };
  const handleMoveRight = () => {
    if (activeImage < images?.length - 1) {
      setActiveImage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (open) {
      setActiveImage(activeImageIndex);
    }
  }, [open]);

    return <Modal isOpen={open} closeModalMethod={closeModalMethod}>
        <div className={Styles.container}>
            <div className={Styles.header}>
                <h4 className={Styles.fileNameText}>{loading ? <ClonosSkeletonLoading width="100px" height="20px" /> : images?.length > 0 && images[activeImage]?.name || "Image"}</h4>
                <div className={Styles.headerRightContent}>
                    {
                        deleteMethod && <div onClick={() => deleteMethod(images[activeImage])} className={Styles.deleteContainer}>
                            <img src={REDTRASH} />
                            <h4>Delete</h4>
                        </div>
                    }
                    <div className={Styles.closeIcon} onClick={(() => closeModalMethod(false))}>
                        <img src={XCIRCLE} />
                    </div>
                </div>
            </div>
            <div style={{ height: uploadedBy ? "82%" : "90%" }} className={Styles.slidderContainer}>
                <div onClick={handleMoveLeft} className={`${Styles.CIRCLELEFT} ${activeImage === 0 ? Styles.disabled : ''}`}>
                    {
                        loading ? <ClonosSkeletonLoading variant="circular" height="70px" width='70px' /> :
                            <img src={CIRCLELEFT} />
                    }
                </div>
                <div className={Styles.imageContainer}>
                    {
                        loading ? <ClonosSkeletonLoading height="10%" length={10} /> :
                            <img src={images?.length > 0 && images?.[activeImage]?.url} />
                    }
                    {
                        loading ? <div className={Styles.uploadedBy_main_container}>
                            <ClonosSkeletonLoading length={2} />
                            <ClonosSkeletonLoading length={2} />
                        </div> : images?.[activeImage]?.uploadedBy && <div>
                            <h3>Uploaded By</h3>
                            <span>{images?.[activeImage]?.uploadedBy?.name}</span>
                        </div>
                    }
                </div>
                <div onClick={handleMoveRight} className={`${Styles.CIRCLERIGHT} ${activeImage === images?.length - 1 ? Styles.disabled : ''}`}>
                    {
                        loading ? <ClonosSkeletonLoading variant="circular" height="70px" width='70px' /> :
                            <img src={CIRCLERIGHT} />
                    }
                </div>
            </div>
        </div>
    </Modal >
}