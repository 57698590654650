import { CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS } from "../../../Constants/Constant";
import { notificationActions } from "../../../Store/Reducers/ClonosNotificationsReducer";
import AppUrl from "../../../utils/AppUrl";
import { doGetApiCall } from "../../../utils/ClonosApi";

// To get the all existing notifications.
const handleGetNotifications = ({
    responseSetterMethod,
    lcStateSetterMethod,
    pagination
}) => {
    doGetApiCall({
        url: AppUrl.getNotifications,
        params: { page: pagination.page, limit: pagination?.limit }
    }).then((response) => {
        console.log('response:', response)
        const result = {
            status: response?.status,
            message: response?.data?.message,
            data: response?.data?.result?.data,
            totalDataCount: response?.data?.result?.totalDataCount,
            totalPageCount: response?.data?.result?.totalPageCount,
            currentPage: response?.data?.result?.currentPage,
        }
        if (result?.status === CLONOS_GLOBAL_SERVER_RESPONSE_CODE_GET_SUCCESS) {
            const hasMap = new Map();
            result?.data?.forEach(item => (hasMap.set(item.id, 1)));

            console.log('hasMap:', hasMap)
            responseSetterMethod(prev => {
                const newData = [...(prev?.notifications?.data || []).filter(item => (!hasMap.has(item?.id))), ...result?.data]
                // const newData = [...(prev?.notifications?.data || []), ...result?.data];
                return { ...prev, notifications: { ...result, data: newData } };
            });
        }
    }).finally(() => {
        lcStateSetterMethod(prev => ({ ...prev, ["getNotificationsLoading"]: false }));
    });
};

// To handle the pagination in the infine scroll
const handleUpdatePagination = ({ setServerResponse, setPagination }) => {
    setServerResponse(serverStatePrev => {
        console.log('serverStatePrev:', serverStatePrev)
        setPagination(prev => {
            if (serverStatePrev?.notifications?.totalPageCount > prev?.page) {
                return { ...prev, page: prev?.page + 1 }
            }
            return prev
        });
        return serverStatePrev;
    })
};

// To update the store once we got the notification.
const handleUpdateNotificationsStore = ({ storedNotifications, currentNotification, dispatch }) => {
    console.log('storedNotifications:', storedNotifications)
    const newNotificationsData = [...storedNotifications, currentNotification];
    dispatch(notificationActions.setNotificationsToolkitState(newNotificationsData));
}

export {
    handleUpdateNotificationsStore,
    handleGetNotifications,
    handleUpdatePagination
}
