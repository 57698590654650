import { memo, useEffect, useState } from "react";
import Styles from "./SingleFieldValueComponent.module.css";
import ClonosInput from "../../../../../../components/CommonComponents/ClonosInput/ClonosInput";
import DynamicDropdown from "../../../../../../components/CommonComponents/DynamicDropdown/DynamicDropdown";
import { assetDropdownStaticData } from "../../../../../../Constants/StaticData";
import { doGetApiCall } from "../../../../../../utils/ClonosApi";
import AppUrl from "../../../../../../utils/AppUrl";
import { handleServerDateInConfiguredTimeZone, modifyString } from "../../../../../../utils/clonosCommon";
import { CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE } from "../../../../../../Constants/Constant";

/**
 * Component for handling a single field value in a form.
 * @param {Object} attributes - Additional attributes for the component.
 * @param {Function} getFieldDetails - Function to update field details.
 * @param {Object} attribute - Object containing field details.
 * @param {Function} handleGetErrorActivatorInformation - Function to handle error information.
 * @returns {JSX.Element} - JSX Element for rendering a single field value.
 */
const SingleFieldValueComponent = ({
    getFieldDetails,
    attribute,
    handleGetErrorActivatorInformation,
    assets,
    handleMoreData,
    isEdit = false,
    handleDynamicSearch
}) => {

    /**
     * Handles changes in the field name or field value.
     * @param {Object} e - Event object containing updated values and metadata.
     */
    const handleChange = (e) => {
        let value = "";
        if (e?.type === modifyString(CLONOS_GLOBAL_TEMPLATE_ATTRIBUTE_DATE, "capitalize")) {
            value = handleServerDateInConfiguredTimeZone({ dateString: e?.updatedValue })
        }
        else {
            value = e?.updatedValue;
        };
        getFieldDetails({ ...attribute, [e?.name]: value, ["uniqueKey"]: e?.uniqueKey });
    };

    return (
        <>
            <div className={Styles.asset_container}>
                <DynamicDropdown
                    label="Asset"
                    isLabel={false}
                    uniqueKey={"asset"}
                    isActivator={true}
                    data={assets}
                    heading={"Select from Asset Library"}
                    placeholder={"Search"}
                    isSearchable={true}
                    isCheckbox={false}
                    isDynamicLoad={handleMoreData ? true : false}
                    isMandatory={false}
                    defaultValue={(attribute?.asset && isEdit) && [{ "name": attribute?.asset }]}
                    handleMoreData={handleMoreData}
                    handleGetValues={(props) => {
                        console.log('props:', props)
                        getFieldDetails({ ...attribute, uniqueKey: props.uniqueKey, asset: props?.updatedValue[0].id })
                    }}
                    handleDynamicSearch={handleDynamicSearch}
                />

            </div>
            {/* Input for capturing the field name */}
            <div className={Styles.fieldNameTaker}>
                <ClonosInput
                    value={attribute?.fieldName || ""}
                    name="fieldName"
                    placeholder="Add Name"
                    uniqueKey={attribute?.id || ""}
                    isMandatory={true}
                    handleGetValues={handleChange}
                    handleGetErrorActivatorInformation={handleGetErrorActivatorInformation}
                    ariaLabel="Field Name Input"
                    isDebounce={true}
                />
            </div>

            {/* Input for capturing the field value */}
            <div className={Styles.fieldValueTaker}>
                <ClonosInput
                    value={attribute?.fieldValue || ""}
                    name="fieldValue"
                    type={attribute?.attributeName}
                    placeholder={`Add ${attribute?.attributeName}`}
                    uniqueKey={attribute?.id || ""}
                    handleGetValues={handleChange}
                    ariaLabel="Field Value Input"
                    isDebounce={true}
                />
            </div>
        </>
    );
};

export default memo(SingleFieldValueComponent);
