
const PendingForApproval = () => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="hourglass-empty">
        <path id="Vector" d="M20 20C17.3478 20 14.8043 21.0536 12.9289 22.9289C11.0536 24.8043 10 27.3478 10 30V33.3333C10 33.7754 10.1756 34.1993 10.4882 34.5118C10.8007 34.8244 11.2246 35 11.6667 35H28.3333C28.7754 35 29.1993 34.8244 29.5118 34.5118C29.8244 34.1993 30 33.7754 30 33.3333V30C30 27.3478 28.9464 24.8043 27.0711 22.9289C25.1957 21.0536 22.6522 20 20 20ZM20 20C17.3478 20 14.8043 18.9464 12.9289 17.0711C11.0536 15.1957 10 12.6522 10 10V6.66667C10 6.22464 10.1756 5.80072 10.4882 5.48816C10.8007 5.17559 11.2246 5 11.6667 5H28.3333C28.7754 5 29.1993 5.17559 29.5118 5.48816C29.8244 5.80072 30 6.22464 30 6.66667V10C30 12.6522 28.9464 15.1957 27.0711 17.0711C25.1957 18.9464 22.6522 20 20 20Z" stroke="#E5B82F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
</svg>


const Checks = () => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="checks">
        <path id="Vector" d="M11.6693 19.9974L20.0026 28.3307L36.6693 11.6641M3.33594 19.9974L11.6693 28.3307M20.0026 19.9974L28.3359 11.6641" stroke="#60D468" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
</svg>


const AllChecklists = () => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="clipboard-check">
        <path id="Vector" d="M15.0026 8.33333H11.6693C10.7852 8.33333 9.93737 8.68452 9.31225 9.30964C8.68713 9.93477 8.33594 10.7826 8.33594 11.6667V31.6667C8.33594 32.5507 8.68713 33.3986 9.31225 34.0237C9.93737 34.6488 10.7852 35 11.6693 35H28.3359C29.22 35 30.0678 34.6488 30.693 34.0237C31.3181 33.3986 31.6693 32.5507 31.6693 31.6667V11.6667C31.6693 10.7826 31.3181 9.93477 30.693 9.30964C30.0678 8.68452 29.22 8.33333 28.3359 8.33333H25.0026M15.0026 8.33333C15.0026 7.44928 15.3538 6.60143 15.9789 5.97631C16.604 5.35119 17.4519 5 18.3359 5H21.6693C22.5533 5 23.4012 5.35119 24.0263 5.97631C24.6514 6.60143 25.0026 7.44928 25.0026 8.33333M15.0026 8.33333C15.0026 9.21739 15.3538 10.0652 15.9789 10.6904C16.604 11.3155 17.4519 11.6667 18.3359 11.6667H21.6693C22.5533 11.6667 23.4012 11.3155 24.0263 10.6904C24.6514 10.0652 25.0026 9.21739 25.0026 8.33333M15.0026 23.3333L18.3359 26.6667L25.0026 20" stroke="#0A5FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
</svg>


const SingleCheck = ({ color }) => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M4.16406 10.0026L8.33073 14.1693L16.6641 5.83594" stroke={color ? color : "#60D468"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>

const Search = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M17.5 17.5L12.5 12.5M2.5 8.33333C2.5 9.09938 2.65088 9.85792 2.94404 10.5657C3.23719 11.2734 3.66687 11.9164 4.20854 12.4581C4.75022 12.9998 5.39328 13.4295 6.10101 13.7226C6.80875 14.0158 7.56729 14.1667 8.33333 14.1667C9.09938 14.1667 9.85792 14.0158 10.5657 13.7226C11.2734 13.4295 11.9164 12.9998 12.4581 12.4581C12.9998 11.9164 13.4295 11.2734 13.7226 10.5657C14.0158 9.85792 14.1667 9.09938 14.1667 8.33333C14.1667 7.56729 14.0158 6.80875 13.7226 6.10101C13.4295 5.39328 12.9998 4.75022 12.4581 4.20854C11.9164 3.66687 11.2734 3.23719 10.5657 2.94404C9.85792 2.65088 9.09938 2.5 8.33333 2.5C7.56729 2.5 6.80875 2.65088 6.10101 2.94404C5.39328 3.23719 4.75022 3.66687 4.20854 4.20854C3.66687 4.75022 3.23719 5.39328 2.94404 6.10101C2.65088 6.80875 2.5 7.56729 2.5 8.33333Z" stroke="#8CA1C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>

export {
    PendingForApproval, Checks, AllChecklists, SingleCheck, Search
}