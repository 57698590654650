import React, { useEffect, useRef } from 'react';
import Styles from './Modal.module.css';


/**
 * Modal component for displaying content in a modal dialog.
 * 
 * @param {Object} props - The component's input properties.
 * @param {boolean} props.isOpen - Indicates whether the modal is open or closed.
 * @param {function} props.closeModalMethod - Method to close the modal.
 * @param {boolean} props.stopOverlayCloser - Indicates whether clicking outside the modal should close it.
 * @param {ReactNode} props.children - Content to be displayed within the modal.
 * @returns {JSX.Element} The Modal component.
 */
const Modal = ({ isOpen, closeModalMethod, stopOverlayCloser, children }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !stopOverlayCloser) {
      closeModalMethod(false);
    }
  };

  const handleAddKeyPress = (e) => {
    if (e.keyCode === 27) {
      isOpen && closeModalMethod(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    document.addEventListener('keydown', handleAddKeyPress)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleAddKeyPress)

    };
  }, [isOpen, closeModalMethod, stopOverlayCloser]);

  return isOpen ? (
    <div className={Styles.modal_container} >
      <div ref={modalRef} className={Styles.modal_content}>
        {children}
      </div>
    </div>
  ) : null;
};

export default Modal;

